import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { DeleteDialogComponent } from "../../../../shared/components/dialog-box/delete-dialog/delete-dialog.component";
import { Subject } from 'rxjs';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker } from '@angular/material';
import { MxGraphService } from 'app/support/services/mx-graph.service';
import { PatientService } from 'app/support/services/patient.service';
import { ValidationService } from 'app/shared/services/validation.service';

declare var _;
declare var $;

export const MY_FORMATS = {
    parse: {
        dateInput: 'YYYY',
    },
    display: {
        dateInput: 'YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'YYYY',
        monthYearA11yLabel: 'YYYY',
    },
};


@Component({
    selector: 'app-trigger-rule-panel',
    templateUrl: './trigger-rule-panel.component.html',
    styleUrls: ['./trigger-rule-panel.component.scss'],
})
export class TriggerRulePanelComponent implements OnInit {
    @Output() outputModel: EventEmitter<any> = new EventEmitter<any>();
    @Output() edit: EventEmitter<any> = new EventEmitter<any>();
    @ViewChildren('matSelect_j') matSelect_j;
    public onClose: Subject<any>;
    triggerObjects: any;
    isEdit:boolean = false;
    selectedOption: any;
    select2Config = {};
    deleteModalContent: any = {
        title: '',
        message: ''
    };
    openDeleteConfirmation: boolean = false;
    showMultipleDropdown: boolean = false;
    activeInputs: any = {};
    activeMetaDatas: any;
    dependenciesList: any = {};
    dependencyDropDownList: any = [];
    dynamicFields: any = [];
    isMarketPlaceView: boolean = false;
    deleteConfirmTextInput: string = '';
    @Output() dataEmitter = new EventEmitter<any>();
    activateOperators: boolean = false;
    borderBottom: boolean = false;
    form: FormGroup;
    activeInputsOperator: Array<any> = [];
    activateCondtions: boolean = false;
    isScheduler: any;
    selectYear: any;
    temp_data: any;
    assessmentList: any = {};
    grpNameList: any = {};
    operatorValuesList: any;
    valuesArr: Array<any> = []
    btnActive:boolean = true;
    grp_rule: boolean = false;
    enableRules: boolean = false;
    dayList = [
        {id:0, value:'Days'},
        {id:1, value:'Weeks'},
        {id:2, value:'Months'},
        {id:3, value:'Years'}
    ];
    scoreList = [
        {id:0, value:'Overall'},
        {id:1, value:'Latest'},
    ];
    enableRulesBtn = true;
    
    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private mx_graphService: MxGraphService,
        private patientService: PatientService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<TriggerRulePanelComponent>,
    ) {
        this.triggerObjects = this.data['triggerObjects'];
        this.isEdit = this.data['isEdit'];
        this.onClose = new Subject();
        let formData = {
            mainObject: '',
            dependentId: '',
            rules: [],
        };
        if(this.isEdit) {
            let value = this.data['selectedCell'].getValue();
            formData['mainObject'] = value['mainObject'];
            formData['dependentId'] = value['dependentId'];
            formData['rules'] = value['rules'];
            this.activeMetaDatas = this.triggerObjects[formData['mainObject']];
            this.edit.emit(this.isEdit);
            this.initializeActiveInputs(value);
        }
        this.form = this.fb.group({ 
            mainObject: [formData.mainObject, [Validators.required]],
            dependentId: [formData.dependentId],
            condition: ['And'],
            rules: this.fb.array([]),
        });
        this.form.valueChanges.subscribe({
            next: (res) => {
                this.checkCondition();
            }
        })
        if(this.isEdit) {
            this.enableRules = true;
            if(this.data['selectedCell']['value']['condition'] != undefined){
                this.edit.emit(this.isEdit);
                this.form.get('condition').setValue(this.data['selectedCell']['value']['condition'])
                this.btnEvent(this.data['selectedCell']['value']['condition']);
                this.data['selectedCell']['value']['rules'].forEach((e,i) => {
                    if(e['condition']){
                        this.addGroup();
                        this.form.controls.rules.value[i]['condition'] = e['condition'];
                        this.btnEventGroup(e['condition'], i);
                        e['rules'].forEach((el,j) => {
                            if(el['condition']){
                                this.addGroupSet(i);
                                this.form.controls.rules.value[i]['rules'][j]['condition'] = el['condition'];
                                this.btnEventNest(el['condition'], i, j);
                                el['rules'].forEach((ele,k) => {
                                    this.addNestedRule(i,j,ele);
                                })
                            }else{
                                this.addRuleSet(i,el);
                            }
                        })
                    }else{
                    this.addRule(e);
                    }
                })
            }    
        } 
    }

    ngOnInit() {
    }

    initializeActiveInputs(selectedCellValue:any) {
        this.activeMetaDatas.fields.forEach( (value, index) => {
            // static fields
            this.activeInputs[value.col_name] = {
                input: value.input,
                type: value.type,
                text: value.text,
                unit: value.unit,
                operators: value.operators
            };
            if(value.hasOwnProperty('options') && value.options.length > 0) {
                this.activeInputs[value.col_name]['options'] = value.options;
            }
        });
        // this.activeMetaDatas.tags.forEach((val, ind) => {
        //     this.assessmentList = {
        //         col_name: val.col_name,
        //         type: val.type,
        //         text:val.text,
        //         options:val.options
        //     }
        // });
        this.assessmentList = this.activeMetaDatas.tags[0];
        this.grpNameList = this.activeMetaDatas.tags[1];
    }

    resetMxGraph(event: any){
        let formValues = this.form.value;
        let dialogConfig = new MatDialogConfig();
        dialogConfig.width = '40%';
        dialogConfig.data = { 'yes': null, 'no': null };
        dialogConfig.position = { top: '70px' };
        dialogConfig.disableClose = true,
            dialogConfig.data = {
                title: 'Reset WorkFlow?',
                sub_title: 'Altering the parent trigger will reset the entire workflow. Are you sure you would like to reset the workflow?',
                no_button: 'No, keep it',
                yes_button: 'Yes, Reset the Workflow'
            }
        let dialogRef = this.dialog.open(DeleteDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            value => {
                if (value == 'yes') {
                    event['remove'] = true;
                    event['resetInclude'] = true
                    this.dialogRef.close(event);
                };
            }
        );
    }

    selectedUploadOption(event) {
        this.enableRules = true;
        this.borderBottom = true;
        this.selectedOption = event.value;
        this.removeRules();
        this.r;
        if (this.form.get('mainObject').value != '') {
            this.activeMetaDatas = this.triggerObjects[this.form.get('mainObject').value];
            this.dependencyDropDownList = [];
            this.dynamicFields = [];
            this.activeInputs = {};
            this.activeMetaDatas.fields.forEach((value, index) => {
                this.activeInputs[value.col_name] = {
                    input: value.input,
                    type: value.type,
                    text: value.text,
                    operators: value.operators,
                    unit:value.unit,
                };
                if (value.hasOwnProperty('options') && value.options.length > 0) {
                    this.activeInputs[value.col_name]['options'] = value.options;
                }
            });
            // this.activeMetaDatas.tags.forEach((val, ind) => {
            //     this.assessmentList = {
            //         col_name: val.col_name,
            //         type: val.type,
            //         text:val.text,
            //         options:val.options
            //     }
            // });
            this.assessmentList = this.activeMetaDatas.tags[0];
            this.grpNameList = this.activeMetaDatas.tags[1];
        } else {
            this.dependencyDropDownList = [];
        }
    }

    removeRules() {
        const control = <FormArray>this.form.controls['rules'];
        for (let i = control.length - 1; i >= 0; i--) {
            control.removeAt(i)
        }
    }

    checkToShowAddRule() {
        if (this.isMarketPlaceView) {
            return false;
        }
        if (!this.form.get('mainObject').value) {
            return false;
        }
        return true;
    }

    addRule(ruleValue) {
        this.f;
        let rule = {
            col_name: '',
            input: '',
            operator: '',
            text: '',
            type: '',
            value: '',
            unit:'',
            value2: '',
            all_operators: false
        };
        const formRules = this.form.controls.rules as FormArray;
        if (ruleValue) {
            rule['col_name'] = ruleValue['col_name'];
            rule['input'] = ruleValue['input'];
            rule['operator'] = ruleValue['operator'];
            rule['text'] = ruleValue['text'];
            rule['type'] = ruleValue['type'];
            rule['value'] = ruleValue['value'];
            rule['unit'] = ruleValue['unit'];
            rule['value2'] = ruleValue['value2']
            rule['all_operators'] = ruleValue['all_operators'];
            // if(ruleValue['input'] == 'date'){
            //     this.temp_data = rule['value'];
            //     this.outputModel.emit(this.temp_data);
            // }
        }
        formRules.push(this.fb.group({
            col_name: [rule['col_name'],[Validators.required]],
            operator: [rule.operator,[Validators.required]],
            value: [rule.value,[Validators.required]],
            type: [rule.type],
            input: [rule.input],
            text: [rule.text],
            all_operators: [rule.all_operators],
            unit: [rule.unit],
            value2: [rule.value2]
        }));       
    }

    onBegainDeleteAction(rule: FormGroup, index: number) {
        this.deleteModalContent.title = 'Delete condition';
        this.deleteModalContent.deletableIndex = index;
        this.openDeleteConfirmation = true;
        let dialogConfig = new MatDialogConfig();
        dialogConfig.width = '40%';
        dialogConfig.data = { 'yes': null, 'no': null };
        dialogConfig.position = { top: '70px' };
        dialogConfig.disableClose = true,
            dialogConfig.data = {
                title: 'Delete Condition?',
                sub_title: 'Are you sure to delete this Condition?',
                no_button: 'No, keep it',
                yes_button: 'Yes, Condition'
            }
        let dialogRef = this.dialog.open(DeleteDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            value => {
                if (value == 'yes') {
                    this.removeTriggerCondition();
                }
            }
        );
    }

    selectedCondition_1(value: any, index: number) {
        const controlArray = <FormArray>this.form.controls['rules'];
        controlArray.controls[index].get('operator').setValue(this.activeInputs[value]['operators'][0]);
        controlArray.controls[index].get('type').setValue(this.activeInputs[value]['type']);
        controlArray.controls[index].get('input').setValue(this.activeInputs[value]['input']);
        controlArray.controls[index].get('text').setValue(this.activeInputs[value]['text']);
        controlArray.controls[index].get('unit').setValue('');
        controlArray.controls[index].get('value').setValue('');
        controlArray.controls[index].get('value2').setValue('');
        if(this.activeInputs[value]['operators'][0] === 'exist' || this.activeInputs[value]['operators'][0] === 'not exist' ||
        this.activeInputs[value]['operators'][0] === 'empty' || this.activeInputs[value]['operators'][0] === 'not empty' || 
        this.activeInputs[value]['operators'][0] === 'Yes' || this.activeInputs[value]['operators'][0] === 'No'){
            controlArray.controls[index].get('value').setValue('?');
        }
        if(this.activeInputs[value]['text'] == 'Total Score' || this.activeInputs[value]['input'] == 'unit'){
            controlArray.controls[index].get('unit').setValidators(Validators.required);
        }else{
            controlArray.controls[index].get('unit').clearValidators();
        }
        if(this.activeInputs[value]['input'] !== 'date') {
        }
        if(this.activeInputs[value]['text'] == 'Clinician Email (EMR)' ||
           this.activeInputs[value]['text'] == 'Patient Email' || this.activeInputs[value]['text'] == 'Patient Email (EMR)'){
            controlArray.controls[index].get('value').setValidators([Validators.required,ValidationService.emailValidator]);
        }else{
            controlArray.controls[index].get('value').setValidators([Validators.required]);
        }
        controlArray.controls[index].get('value').updateValueAndValidity();
        controlArray.controls[index].get('value2').updateValueAndValidity();
        controlArray.controls[index].get('unit').updateValueAndValidity();
        // this.patientService.clearYearPicker(false)
        return this.activeInputs[value]
    }

    get f() {
        return this.form.controls;
    }

    get r() {
        return this.form.controls.rules as FormArray;
    }

    save() {
        this.mx_graphService.setGraphLoadingTrue()
        let formValues = this.form.value;
        let value = this.data['selectedCell'].getValue();
        let returnDatas: any = {
            formData: formValues,
            fields: this.activeInputs,
            assessment_lists:this.assessmentList,
            grp_name_list:this.grpNameList,
            triggerData:this.activeMetaDatas
        };
        if(this.isEdit && (value['mainObject'] != this.activeMetaDatas['id'])){
            this.resetMxGraph(returnDatas);
        }else{
            this.dialogRef.close(returnDatas);
        }
    }

    cancel() {
        this.mx_graphService.setGraphLoadingTrue()
        this.dialogRef.close('close');
    }

    removeTriggerCondition() {
        const { deletableIndex } = this.deleteModalContent;
        this.removeRuleByIndex(deletableIndex);
        this.openDeleteConfirmation = false;
        this.deleteModalContent.deletableIndex = -1;
        this.deleteConfirmTextInput = '';
    };

    removeRuleByIndex(index: number) {
        const control = <FormArray>this.form.controls['rules'];
        control.removeAt(index);
    }

    removeRule1(i,j,k){
        const ctrl = <FormArray>this.form.controls['rules'];
        const controlArray = ctrl.controls[i].get('rules') as FormArray;
        this.deleteModalContent.title = 'Delete condition';
        this.openDeleteConfirmation = true;
        let dialogConfig = new MatDialogConfig();
        dialogConfig.width = '40%';
        dialogConfig.data = { 'yes': null, 'no': null };
        dialogConfig.position = { top: '70px' };
        dialogConfig.disableClose = true,
            dialogConfig.data = {
                title: 'Delete Condition?',
                sub_title: 'Are you sure to delete this Condition?',
                no_button: 'No, keep it',
                yes_button: 'Yes, Condition'
            }
        let dialogRef = this.dialog.open(DeleteDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            value => {
                if (value == 'yes') {
                    if(k != undefined){
                        const nestArray = controlArray.controls[j].get('rules') as FormArray;
                        nestArray.removeAt(k);
                    }else{
                        controlArray.removeAt(j);
                    }
                    
                }
            }
        );  
    }

    onSelectOperator(value: any, index: number,j,k) {
        this.activateCondtions = true;
        const controlArray = <FormArray>this.form.controls['rules'];
        if(k != undefined){
            const indVal = controlArray.controls[index].get('rules') as FormArray;
            const jVal = indVal.controls[j].get('rules') as FormArray;
            const kVal = jVal.controls[k].get('operator').value;
            jVal.controls[k].get('value').setValue('');
            jVal.controls[k].get('value2').setValue('');
            jVal.controls[k].get('unit').setValue('');
            if((kVal === 'equal' || kVal === 'not equal') && (jVal.controls[k].get('text').value == 'Clinician Email (EMR)' || jVal.controls[k].get('text').value == 'Patient Email' 
            || jVal.controls[k].get('text').value == 'Patient Email (EMR)')){
                jVal.controls[k].get('value').setValidators([Validators.required, ValidationService.emailValidator]);
            }else{
                jVal.controls[k].get('value').setValidators([Validators.required])
            }
            if(kVal === 'exist' || kVal === 'not exist' || kVal === 'empty' || kVal === 'not empty' || kVal === 'Yes' || kVal === 'No' ){
                jVal.controls[k].get('value').setValue(['?']);
                jVal.controls[k].get('value').setErrors(null);
            }else if(kVal === 'between'){
                jVal.controls[k].get('value2').setValidators(Validators.required);
            }else{
                jVal.controls[k].get('value2').clearValidators();
            }
            if(jVal.controls[k].get('text').value == 'Total Score' || jVal.controls[k].get('input').value == 'unit'){
                jVal.controls[k].get('unit').setValidators(Validators.required);
            }else{
                jVal.controls[k].get('unit').clearValidators();
            }
            jVal.controls[k].get('value').updateValueAndValidity();
            jVal.controls[k].get('value2').updateValueAndValidity();
            jVal.controls[k].get('unit').updateValueAndValidity();
        }else if( j != undefined){
            const indVal = controlArray.controls[index].get('rules') as FormArray;
            const jVal = indVal.controls[j].get('operator').value;
            indVal.controls[j].get('value').setValue('');
            indVal.controls[j].get('value2').setValue('');
            indVal.controls[j].get('unit').setValue('');
            if((jVal === 'equal' || jVal === 'not equal') && (indVal.controls[j].get('text').value == 'Clinician Email (EMR)' || indVal.controls[j].get('text').value == 'Patient Email' 
            || indVal.controls[j].get('text').value == 'Patient Email (EMR)')){
                indVal.controls[j].get('value').setValidators([Validators.required, ValidationService.emailValidator]);
            }else{
                indVal.controls[j].get('value').setValidators([Validators.required])
            }
            if(jVal === 'exist' || jVal === 'not exist' || jVal === 'empty' || jVal === 'not empty' || jVal === 'Yes' || jVal === 'No' ){
                indVal.controls[j].get('value').setValue(['?']);
                indVal.controls[j].get('value').setErrors(null);
            }else if(jVal === 'between'){
                indVal.controls[j].get('value2').setValidators(Validators.required);
            }else{
                indVal.controls[j].get('value2').clearValidators();
            }
            if(indVal.controls[j].get('text').value == 'Total Score' || indVal.controls[j].get('input').value == 'unit'){
                indVal.controls[j].get('unit').setValidators(Validators.required);
            }else{
                indVal.controls[j].get('unit').clearValidators();
            }
            indVal.controls[j].get('value').updateValueAndValidity();
            indVal.controls[j].get('value2').updateValueAndValidity();
            indVal.controls[j].get('unit').updateValueAndValidity();
            indVal.controls[j].get('all_operators').setValue(false);
        }else{
            const indVal = controlArray.controls[index].get('operator').value;
            controlArray.controls[index].get('value').setValue('');
            controlArray.controls[index].get('value2').setValue('');
            controlArray.controls[index].get('unit').setValue('');
            if(indVal === 'exist' || indVal === 'not exist' || indVal === 'empty' || indVal === 'not empty' || indVal === 'Yes' || indVal === 'No'){
                controlArray.controls[index].get('value').setValue(["?"]);
                controlArray.controls[index].get('value').setErrors(null);
            }else if(indVal === 'between'){
                controlArray.controls[index].get('value2').setValidators(Validators.required);
            }else{
                controlArray.controls[index].get('value2').clearValidators();
            }
            if(controlArray.controls[index].get('text').value == 'Total Score' || controlArray.controls[index].get('input').value == 'unit'){
                controlArray.controls[index].get('unit').setValidators(Validators.required);
            }else{
                controlArray.controls[index].get('unit').clearValidators();
            }
            if((indVal === 'equal' || indVal === 'not equal') && (controlArray.controls[index].get('text').value == 'Clinician Email (EMR)' || controlArray.controls[index].get('text').value == 'Patient Email' 
            || controlArray.controls[index].get('text').value == 'Patient Email (EMR)')){
                controlArray.controls[index].get('value').setValidators([Validators.required, ValidationService.emailValidator]);
            }else{
                controlArray.controls[index].get('value').setValidators([Validators.required])
            }
            controlArray.controls[index].get('value').updateValueAndValidity();
            controlArray.controls[index].get('value2').updateValueAndValidity();
            controlArray.controls[index].get('unit').updateValueAndValidity();
            controlArray.controls[index].get('all_operators').setValue(false);
        }
    }

    selectConditions(value: any, index: number) {
        const controlArray = <FormArray>this.form.controls['rules'];
        controlArray.controls[index].get('value').setValue(value['value']);
        controlArray.controls[index].get('value').setErrors(null);
    }

    checkOperators(i,j,k){
        const controlArray = <FormArray>this.form.controls['rules'];
        let count;
        if(j == undefined && k == undefined) {
            count = this.form.controls.rules.value[i].value;
            if(count.length == this.activeInputs[this.form.controls.rules.value[i].col_name].options.length){
                controlArray.controls[i].get('all_operators').setValue(true);
            }else{
                controlArray.controls[i].get('all_operators').setValue(false);
            }
        } else if (k == undefined && j != undefined) {
            count = this.form.controls.rules.value[i].rules[j].value;
            const nestedArray = controlArray.controls[i].get('rules') as FormArray;
            if (count.length == this.activeInputs[this.form.controls.rules.value[i].rules[j].col_name].options.length){
                nestedArray.controls[j].get('all_operators').setValue(true);
            } else {
                nestedArray.controls[j].get('all_operators').setValue(false);
            }

        } else {
            count = this.form.controls.rules.value[i].rules[j].rules[k].value;
            const nestedArray = controlArray.controls[i].get('rules') as FormArray;
            const subNestedArray = nestedArray.controls[j].get('rules') as FormArray;
            if (count.length == this.activeInputs[this.form.controls.rules.value[i].rules[j].rules[k].col_name].options.length){
                subNestedArray.controls[k].get('all_operators').setValue(true);
            } else {
                subNestedArray.controls[k].get('all_operators').setValue(false);
            }
        }
    }

    selectAllOperators(event,param,i,j,k) {
        const controlArray = <FormArray>this.form.controls['rules'];
        try {
            if (event.checked) {
                if( j == undefined && k == undefined) {
                    controlArray.controls[i].get('all_operators').setValue(true);
                    this.operatorValuesList = this.activeInputs[this.form.controls.rules.value[i].col_name].options;
                    this.operatorValuesList.forEach(e => {
                        if (e){
                        this.valuesArr.push(e.id);
                        }
                    })
                    controlArray.controls[i].get('value').setValue(this.valuesArr.concat([]));
                } else if ( j != undefined && k == undefined){
                    const nested = controlArray.controls[i].get('rules') as FormArray;
                    nested.controls[j].get('all_operators').setValue(true)
                    this.operatorValuesList = this.activeInputs[this.form.controls.rules.value[i].rules[j].col_name].options;
                    this.operatorValuesList.forEach(e => {
                        if (e){
                        this.valuesArr.push(e.id);
                        }
                    })
                    nested.controls[j].get('value').setValue(this.valuesArr.concat([]));
                } else {
                    const nested = controlArray.controls[i].get('rules') as FormArray;
                    const subNestedArray = nested.controls[j].get('rules') as FormArray;
                    subNestedArray.controls[k].get('all_operators').setValue(true)
                    this.operatorValuesList = this.activeInputs[this.form.controls.rules.value[i].rules[j].rules[k].col_name].options;
                    this.operatorValuesList.forEach(e => {
                        if (e){
                        this.valuesArr.push(e.id);
                        }
                    })
                    subNestedArray.controls[k].get('value').setValue(this.valuesArr.concat([]));
                }
                this.matSelect_j._results.forEach((x,y) => {
                    if(x._id == param._id)
                    {
                        x.options._results.forEach(el=>{
                            el.select()
                        })
                    }
                });
            } else {
                this.matSelect_j._results.forEach((x,y) => {
                    if(x._id == param._id)
                    {
                        x.options._results.forEach(el=>{
                            el.deselect()
                        })
                    }
                });
                if( j == undefined && k == undefined) {
                    controlArray.controls[i].get('all_operators').setValue(false);
                    controlArray.controls[i].get('value').setValue([]);
                } else if ( j != undefined && k == undefined){
                    const nested = controlArray.controls[i].get('rules') as FormArray;
                    nested.controls[j].get('all_operators').setValue(false)
                    nested.controls[j].get('value').setValue([]);
                } else {
                    const nested = controlArray.controls[i].get('rules') as FormArray;
                    const subNestedArray = nested.controls[j].get('rules') as FormArray;
                    subNestedArray.controls[k].get('all_operators').setValue(false)
                    subNestedArray.controls[k].get('value').setValue([]);
                }
            }
        }
        finally{
            this.valuesArr = [];
        }
    }

    btnEvent(opr){
        this.btnActive = !this.btnActive;
        this.form.get('condition').setValue(opr);
        setTimeout(() => {
            let btns = document.querySelectorAll('.init-');
            let element;
            btns.forEach(el => {
                el.classList.remove('btn-active');
                if(el.textContent == opr) element = el;
            });
            element.classList.add('btn-active');
        }, 100);
    }

    addGroup(){
        this.f;
        this.grp_rule = true;
        const formGroup = this.form.controls.rules as FormArray;
        formGroup.push(this.fb.group({
            condition: ['And'],
            rules: this.fb.array([])
        }))
    }

    addRuleSet(ind,ruleValue){
        const rules = this.form.controls.rules as FormArray;
        let rule = {
            col_name: '',
            input: '',
            operator: '',
            text: '',
            type: '',
            value: '',
            value2: '',
            unit:'',
            all_operators: false
        };
        const sub_rule = rules.controls[ind].get('rules') as FormArray;
        if (ruleValue) {
            rule['col_name'] = ruleValue['col_name'];
            rule['input'] = ruleValue['input'];
            rule['operator'] = ruleValue['operator'];
            rule['text'] = ruleValue['text'];
            rule['type'] = ruleValue['type'];
            rule['value'] = ruleValue['value'];
            rule['unit'] = ruleValue['unit'];
            rule['value2'] = ruleValue['value2'];
            rule['all_operators'] = ruleValue['all_operators'];
            if(ruleValue['value'] == 'English' && !['contains', 'does not contains'].includes(ruleValue['operator'])) rule['value'] = 'EN';
            if(ruleValue['value'] == 'Spanish' && !['contains', 'does not contains'].includes(ruleValue['operator'])) rule['value'] = 'SP';
            // if(ruleValue['input'] == 'date'){
            //     this.temp_data = rule['value'];
            //     this.outputModel.emit(this.temp_data);
            // }
        }
        sub_rule.push(this.fb.group({
            col_name: [rule['col_name'],[Validators.required]],
            operator: [rule.operator,[Validators.required]],
            value: [rule.value,[Validators.required]],
            type: [rule.type],
            input: [rule.input],
            text: [rule.text],
            unit:[rule.unit],
            value2: [rule.value2],
            all_operators: [rule.all_operators],
        }))
       
    }

    addGroupSet(i){
        const rules = this.form.controls.rules as FormArray;
        const secondArray = rules.controls[i].get('rules') as FormArray;
        secondArray.push(this.fb.group({
            condition: ['And'],
            rules: this.fb.array([])
        }))
    }

    selectedCondition(value: any, i,j,k) {
        const ctrl = <FormArray>this.form.controls['rules'];
        const controlArray = ctrl.controls[i].get('rules') as FormArray;
        
        if(k != undefined){
            const nestArray = controlArray.controls[j].get('rules') as FormArray;
            nestArray.controls[k].get('operator').setValue(this.activeInputs[value]['operators'][0]);
            nestArray.controls[k].get('type').setValue(this.activeInputs[value]['type']);
            nestArray.controls[k].get('input').setValue(this.activeInputs[value]['input']);
            nestArray.controls[k].get('text').setValue(this.activeInputs[value]['text']);
            nestArray.controls[k].get('unit').setValue('');
            nestArray.controls[k].get('value').setValue('');
            nestArray.controls[k].get('value2').setValue('');
            if(this.activeInputs[value]['operators'][0] === 'exist' || this.activeInputs[value]['operators'][0] === 'not exist' ||
            this.activeInputs[value]['operators'][0] === 'empty' || this.activeInputs[value]['operators'][0] === 'not empty' ||
            this.activeInputs[value]['operators'][0] === 'Yes' || this.activeInputs[value]['operators'][0] === 'No' ){
                nestArray.controls[k].get('value').setValue('?');
            }
            if(this.activeInputs[value]['text'] == 'Total Score' || this.activeInputs[value]['input'] == 'unit'){
                nestArray.controls[k].get('unit').setValidators(Validators.required);
            }else{
                nestArray.controls[k].get('unit').clearValidators();
            }
            if(this.activeInputs[value]['text'] == 'Clinician Email (EMR)' ||
                this.activeInputs[value]['text'] == 'Patient Email' || this.activeInputs[value]['text'] == 'Patient Email (EMR)'){
                    nestArray.controls[k].get('value').setValidators([Validators.required,ValidationService.emailValidator]);
            }else{
                nestArray.controls[k].get('value').setValidators([Validators.required]);
            }
            nestArray.controls[k].get('unit').updateValueAndValidity();
            nestArray.controls[k].get('value').updateValueAndValidity();
            nestArray.controls[k].get('value2').updateValueAndValidity();
        }else{
            controlArray.controls[j].get('operator').setValue(this.activeInputs[value]['operators'][0]);
            controlArray.controls[j].get('type').setValue(this.activeInputs[value]['type']);
            controlArray.controls[j].get('input').setValue(this.activeInputs[value]['input']);
            controlArray.controls[j].get('text').setValue(this.activeInputs[value]['text']);
            controlArray.controls[j].get('unit').setValue('');
            controlArray.controls[j].get('value').setValue('');
            controlArray.controls[j].get('value2').setValue('');
            if(this.activeInputs[value]['operators'][0] === 'exist' || this.activeInputs[value]['operators'][0] === 'not exist' ||
            this.activeInputs[value]['operators'][0] === 'empty' || this.activeInputs[value]['operators'][0] === 'not empty' ||
            this.activeInputs[value]['operators'][0] === 'Yes' || this.activeInputs[value]['operators'][0] === 'No'){
                controlArray.controls[j].get('value').setValue('?');
            }
            if(this.activeInputs[value]['text'] == 'Total Score' || this.activeInputs[value]['input'] == 'unit'){
                controlArray.controls[j].get('unit').setValidators(Validators.required);
            }else{
                controlArray.controls[j].get('unit').clearValidators();
            }
            if(this.activeInputs[value]['text'] == 'Clinician Email (EMR)' ||
            this.activeInputs[value]['text'] == 'Patient Email' || this.activeInputs[value]['text'] == 'Patient Email (EMR)'){
                controlArray.controls[j].get('value').setValidators([Validators.required,ValidationService.emailValidator]);
            }else{
                controlArray.controls[j].get('value').setValidators([Validators.required]);
            }
            controlArray.controls[j].get('unit').updateValueAndValidity();
            controlArray.controls[j].get('value').updateValueAndValidity();
            controlArray.controls[j].get('value2').updateValueAndValidity();
        }
        // this.patientService.clearYearPicker(false)
        return this.activeInputs[value]
    }

    btnEventGroup(opr, i) {
        const rule = this.form.controls.rules as FormArray;
        rule.controls[i].get('condition').setValue(opr);

        setTimeout(() => {
            let btns = document.querySelectorAll('.set-' + (i + 1));
            let element;
            btns.forEach(el => {
                el.classList.remove('btn-active');
                if(el.textContent == opr) element = el;
            });
            element.classList.add('btn-active');
        }, 100);
    }

    setCondition(value:any,i,j,k){
        let lang = '';
        if(value['value'] == "EN") lang = 'English';
        if(value['value'] == "SP") lang = 'Spanish';
        const ctrl = <FormArray>this.form.controls['rules'];
        const controlArray = ctrl.controls[i].get('rules') as FormArray;
        if(k != undefined){
            const nestedArray = controlArray.controls[j].get('rules') as FormArray;
            if(lang != '') nestedArray.controls[k].value['value'] = lang;
            nestedArray.controls[k].get('value').setErrors(null);
        }else{
            if(lang != '') controlArray.controls[j].value['value'] = lang;
            controlArray.controls[j].get('value').setErrors(null);
        }
        
    }

    btnEventNest(opr, i, j) {
        const rule = this.form.controls.rules as FormArray;
        const rules = rule.controls[i].get('rules') as FormArray;
        rules.controls[j].get('condition').setValue(opr);

        setTimeout(() => {
            let btns = document.querySelectorAll(`.innerSubGroup${i + 1}-${j + 1}`);
            let element;
            btns.forEach(el => {
                el.classList.remove('btn-active');
                if(el.textContent == opr) element = el;
            });
            element.classList.add('btn-active');
        }, 100);
    }

    addNestedRule(i,j,ruleValue){
        let rule = {
            col_name: '',
            input: '',
            operator: '',
            text: '',
            type: '',
            value: '',
            unit:'',
            value2: '',
            all_operators: false
        };
        const rules = this.form.controls.rules as FormArray;
        const secondArray = rules.controls[i].get('rules') as FormArray;
        const nest = secondArray.controls[j].get('rules') as FormArray;
        if (ruleValue) {
            rule['col_name'] = ruleValue['col_name'];
            rule['input'] = ruleValue['input'];
            rule['operator'] = ruleValue['operator'];
            rule['text'] = ruleValue['text'];
            rule['type'] = ruleValue['type'];
            rule['value'] = ruleValue['value'];
            rule['unit'] = ruleValue['unit'];
            rule['value2'] = ruleValue['value2'];
            rule['all_operators'] = ruleValue['all_operators'];
            if(ruleValue['input'] == 'date'){
                this.temp_data = rule['value'];
                this.outputModel.emit(this.temp_data);
            }
            if(ruleValue['value'] == 'English' && !['contains', 'does not contains'].includes(ruleValue['operator'])) rule['value'] = 'EN';
            if(ruleValue['value'] == 'Spanish' && !['contains', 'does not contains'].includes(ruleValue['operator'])) rule['value'] = 'SP';  
        }
        nest.push(this.fb.group({
            col_name: [rule['col_name'],[Validators.required]],
            operator: [rule.operator,[Validators.required]],
            value: [rule.value,[Validators.required]],
            type: [rule.type],
            input: [rule.input],
            text: [rule.text],
            unit: [rule.unit],
            value2: [rule.value2],
            all_operators: [rule.all_operators]
        }))  
    }
    
    checkCondition() {
        if (this.form.controls.rules) {
            if (this.form.controls.rules.value && this.form.controls.rules.value.length) {
                const all_check = this.form.controls.rules.value.map((e, i) => {
                    const sub_rule_check = e.rules.map((se, j) => {
                        if (se.rules) {
                            if (se.rules[j] && se.rules[j]['condition']) {
                                const sub_check = se.rules.map((el) => {
                                    const check_sub_length = el.rules.length > 0;
                                    return check_sub_length ? this.form.valid : check_sub_length;
                                });
                                return sub_check.every(u => !!u);
                            }
                            const check_sub_length = se.rules.length > 0;
                            return check_sub_length ? this.form.valid : check_sub_length;
                        }
                        return this.form.valid;
                    });
                    return sub_rule_check.length > 0 ? sub_rule_check.every(u => !!u) && this.form.valid : false;
                });
                this.enableRulesBtn = all_check.every(v => !!v);
                return;
            }
        }
        this.enableRulesBtn = true;
    }
}


