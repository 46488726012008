import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, Inject } from '@angular/core';
import { User } from 'app/support/classes';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { ValidationService } from 'app/shared/services/validation.service';
import { UsersService } from 'app/support/services/users.service';
import { GlobalService } from 'app/support/services/auth/global.service';
import { GetApiurl } from 'app/support/app-config';
import { SnackbarCongiration } from 'app/shared/components/snack-bar/SnackbarConfiguration';
import { AuthService } from 'app/support/services/auth/auth.service';
import { ImageCroppedEvent, Dimensions } from 'ngx-image-cropper';
import { CanComponentDeactivate } from 'app/support/services/auth/can-deactivate.guard'
import { ActivateDialogComponent } from 'app/shared/components/dialog-box/activate-dialog/activate-dialog.component';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { promise } from 'protractor';
import { resolve } from 'path';
import { ContactService } from 'app/support/services/contact.service';
import { MatSelectChange, MatGridTileHeaderCssMatStyler, MatOptionSelectionChange } from '@angular/material';
import { ZipOverwriteComponent } from 'app/shared/components/dialog-box/zip-overwrite/zip-overwrite.component';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProfileComponent implements CanComponentDeactivate {
    userId: string;
    profileForm: FormGroup;
    changePasswordForm: FormGroup;
    user: User;
    upload_file: any;
    imageSrc: any;
    showscreen: boolean = false;
    profile_view: number = 1;
    currentpasshide = true;
    newpasshide = true;
    cnfpasshide = true;
    countryCodeList: Array<any> = [];
    croppedImage: any = '';
    userDetails: any;
    contactDetails: any;
    userDetailForm: FormGroup;
    submitChange: boolean = false;
    InitialprofileValues: string = '';
    InitialPasswordFormValues: string = '';
    inProgress: boolean = false;
    cityList: Array<any> = [];
    stateList: Array<any> = [];
    countryList: Array<any> = [];
    phoneTypeList: Array<any>;
    phoneArray: FormArray;
    emailArray: FormArray;

    @ViewChild('currentPassInp', { static: false, }) currentPassInp: ElementRef;
    constructor(
        private snackBar: SnackbarCongiration,
        private usersService: UsersService,
        private contactService: ContactService,
        private fb: FormBuilder,
        private globalService: GlobalService,
        private authService: AuthService,
        public dialog: MatDialog
    ) {
        this.globalService.user$.subscribe(data => {
            this.userId = data['id'].toString();
            this.getUserData();
            this.imageSrc = data['profile_image']
        });

        this.phoneTypeList = [
            { id: 1, value: 'Mobile', icon: 'phone_iphone' },
            { id: 2, value: 'Home', icon: 'home' },
            { id: 3, value: 'Work', icon: 'work_outline' },
            { id: 4, value: 'Landline', icon: 'phone' }
        ];

        this.countryCodeList = [
            { id: 1, value: '+1' },
            { id: 2, value: '+91' }
        ];
    }

    ngOnInit() {
        this.createChangePasswordForm()
    }

    getUserData() {
        this.usersService.getUserData(this.userId).subscribe(
            (response: any) => {
                this.profile_view = 1;
                this.user = response['data'];
                let userId = this.user['id'].toString()
                this.usersService.getUser(userId).subscribe(
                    data => {
                        this.userDetails = data['data']['user_data']
                        this.contactDetails = data['data']['contact_data']['contact']
                        this.cityList = data['data']['contact_data']['city_list']
                        this.stateList = data['data']['contact_data']['state_list']
                        this.countryList = data['data']['contact_data']['country_list']
                        this.createProfileForm();
                        this.showscreen = true;
                    }
                )

            },
            (error) => {
            }
        );
    }

    createProfileForm() {
        this.profileForm = this.fb.group({
            first_name: [this.user.first_name, [Validators.required, Validators.maxLength(45)]],
            middle_name: [this.user.middle_name, [Validators.maxLength(45), Validators.pattern('[A-Za-z]*')]],
            last_name: [this.user.last_name, [Validators.required, Validators.maxLength(45)]],
            email: [{ value: this.user.email, disabled: true }, [Validators.required, ValidationService.emailValidator]],
            mobile: [this.user.mobile, [ValidationService.phoneValidator]],
            country_code: this.user.country_code == '' ? this.countryCodeList[0] : this.countryCodeList.find(el => el['value'] == this.user.country_code),
            email_list: this.fb.array([]),
            phone_list: this.fb.array([]),
            address_one: [this.contactDetails['address_one'], [Validators.required, Validators.maxLength(45)]],
            address_two: [this.contactDetails['address_two']],
            zip_code: [this.contactDetails['zip_code']],
            city: [this.contactDetails.city ? this.cityList.find(el => el == this.contactDetails.city) : this.contactDetails.city],
            state: [this.contactDetails.state ? this.stateList.find(el => el == this.contactDetails.state) : this.contactDetails.state],
            country: [this.contactDetails.country ? this.countryList.find(el => el['country'] == this.contactDetails.country['country']) : this.contactDetails.country]
        });
        this.addPhone(this.contactDetails['contact_phone']);
        this.addEmail(this.contactDetails['contact_email']);
        this.InitialprofileValues = JSON.stringify(this.profileForm.value);
    }

    checkValueChange() {
        let profvalue = JSON.stringify(this.profileForm.value);
        let pwdValue = JSON.stringify(this.changePasswordForm.value);
        if (this.InitialPasswordFormValues != pwdValue || this.InitialprofileValues != profvalue) return true;
        else return false;
    }

    createChangePasswordForm() {
        this.changePasswordForm = this.fb.group({
            currentPassword: ["", [Validators.required]],
            password: ["", [Validators.required, ValidationService.passwordValidator, ValidationService.confirmPasswordValidator]],
            passwordConfirm: ["", [Validators.required, ValidationService.passwordValidator, ValidationService.confirmPasswordValidator]]
        });
        this.InitialPasswordFormValues = JSON.stringify(this.changePasswordForm.value);
    }

    getZipCode() {
        if (this.profileForm.controls['zip_code'].value.length == 5) {
            this.contactService.getZipCode(this.profileForm.controls['zip_code'].value).subscribe(
                (response: any) => {
                    if ('city_list' in response['data']) {
                        this.cityList = response['data']['city_list'].concat([]);
                        this.stateList = response['data']['state_list'].concat([]);
                        this.countryList = response['data']['country_list'].concat([]);
                        this.profileForm.controls['city'].setValue(
                            this.cityList.find(el => el == response['data']['region']['city'])
                        );
                        this.profileForm.controls['state'].setValue(
                            this.stateList.find(el => el == response['data']['region']['state'])
                        );
                        this.profileForm.controls['country'].setValue(
                            this.countryList.find(el => el.id == response['data']['region']['country_id'])
                        );
                        this.profileForm.controls['zip_code'].setErrors(null);
                    }
                    else {
                        this.cityList = [];
                        this.stateList = [];
                        this.countryList = [];
                        this.profileForm.controls['city'].setValue('');
                        this.profileForm.controls['state'].setValue('');
                        this.profileForm.controls['country'].setValue(null);
                        this.profileForm.controls['zip_code'].markAllAsTouched();
                        this.profileForm.controls['zip_code'].setErrors({ 'invalid': true });
                    }
                }
            );
        }
        else {
            this.cityList = [];
            this.stateList = [];
            this.countryList = [];
            this.profileForm.controls['city'].setValue('');
            this.profileForm.controls['state'].setValue('');
            this.profileForm.controls['country'].setValue(null);
        }
    }

    selectCity(event: MatSelectChange) {
        this.contactService.getCityZip(event.value, this.profileForm.value['state'], this.profileForm.value['country']['id']).subscribe(
            (response: any) => {
                if (!(response['data']['zip'].includes(this.profileForm.get('zip_code').value))) {
                    let dialogConfig = new MatDialogConfig();
                    dialogConfig.position = { top: '70px' };
                    dialogConfig.width = '600px';
                    dialogConfig.disableClose = true;
                    dialogConfig.restoreFocus = false;
                    dialogConfig.data = {
                        existing: this.profileForm.get('zip_code').value,
                        zip: response['data']['zip']
                    };
                    let dialogRef = this.dialog.open(ZipOverwriteComponent, dialogConfig);
                    dialogRef.afterClosed().subscribe(
                        value => {
                            if (value['type'])
                                this.profileForm.controls['zip_code'].setValue(value['zip']);
                        }
                    );
                }
            }
        );
    }

    selectState(event: MatSelectChange) {
        this.contactService.getCityList(event.value).subscribe(
            (response: any) => {
                this.cityList = response['data']['city_list'].concat([]);
                this.profileForm.controls['city'].setValue('');
                this.profileForm.controls['city'].markAsTouched();
                this.profileForm.controls['city'].markAsDirty();
            }
        );
    }

    selectCountry(event: MatSelectChange) {
        this.contactService.getStateList(event.value).subscribe(
            (response: any) => {
                this.stateList = response['data']['state_list'].concat([]);
                this.profileForm.controls['state'].setValue('');
                this.profileForm.controls['state'].markAsTouched();
                this.profileForm.controls['state'].markAsDirty();
                this.cityList = [];
                this.profileForm.controls['city'].setValue('');
                this.profileForm.controls['city'].markAsTouched();
                this.profileForm.controls['city'].markAsDirty();

            }
        );
    }

    addPhone(contactPhone: Array<any> = []) {
        this.phoneArray = this.profileForm.get('phone_list') as FormArray;
        if (contactPhone.length) {
            contactPhone.forEach(
                element => {
                    let phone_type;
                    if (element['phone_type'] == "" || element['phone_type'] == null) {
                        element['phone_type'] = {
                            "icon": this.phoneTypeList[0]['icon'],
                            "value": this.phoneTypeList[0]['value']
                        }
                    }
                    if (typeof (element['phone_type']) == "string") {
                        phone_type = this.phoneTypeList.find(ele => ele['value'] == element['phone_type'])
                    } else if (typeof (element['phone_type']) == "object") {
                        phone_type = this.phoneTypeList.find(ele => ele['value'] == element['phone_type']['value'])
                    }
                    this.phoneArray.push(this.fb.group({
                        phone: [element['phone'], [ValidationService.phoneValidator]],
                        phone_type: phone_type,
                        country_code: this.countryCodeList.find(ele => ele['value'] == element['country_code'])
                    }));
                }
            );
        }
        else
            this.phoneArray.push(this.fb.group({
                phone: ['', [ValidationService.phoneValidator]],
                phone_type: this.phoneTypeList[0],
                country_code: this.countryCodeList[0]
            }));
    }

    addNewPhone() {
        if (!this.phoneArray.value.find(el => el['phone'] == ''))
            this.phoneArray.push(this.fb.group({
                phone: ['', [ValidationService.phoneValidator]],
                phone_type: this.phoneTypeList[0],
                country_code: this.countryCodeList[0]
            }));
        else
            this.snackBar.triggerSnackBar({
                message: 'Empty phone field found',
                type: 'warning'
            });
    }

    removePhone(index: number) {
        this.phoneArray.removeAt(index);
    }

    addEmail(contactEmail: Array<any> = []) {
        this.emailArray = this.profileForm.get('email_list') as FormArray;
        if (contactEmail.length)
            contactEmail.forEach(
                element => {
                    this.emailArray.push(this.fb.group({
                        email: [element['email'], [ValidationService.emailValidator]]
                    }));
                }
            );
        else
            this.emailArray.push(this.fb.group({
                email: ['', [ValidationService.emailValidator]]
            }));
    }

    addNewEmail() {
        if (!this.emailArray.value.find(el => el['email'] == ''))
            this.emailArray.push(this.fb.group({
                email: ['', [ValidationService.emailValidator]]
            }));
        else
            this.snackBar.triggerSnackBar({
                message: 'Empty email field found',
                type: 'warning'
            });
    }

    removeEmail(index: number) {
        this.emailArray.removeAt(index);
    }

    changeView(view) {
        if (view == 'profile') {
            this.profile_view = 1;
        } else if (view == 'change_password') {
            this.profile_view = 2;
            this.createProfileForm();
        } else {
            this.profile_view = 3
        }
    }
    onEvent(event) {
        event.target.value = '';
    }

    onSelectFile(event) {
        const dialogRef = this.dialog.open(ProfileCropModal, {
            width: '900px',
            height: '550px',
            data: { 'file': event, 'userDetails': this.user }
        });
        // if (event.target.files && event.target.files[0]) {
        //     const file = event.target.files[0];
        //     this.upload_file = file;
        //     this.UploadImage().then(result => {
        //         if (result) {
        //             let user_data;
        //             this.globalService.user$.subscribe(data => {
        //                 user_data = data;
        //                 user_data['profile_image'] = result['user']['profile_image'] + "?" + Math.floor(Math.random() * 100) + 1;;
        //             });
        //             this.globalService.setUser(user_data);
        //             const reader = new FileReader();
        //             reader.onload = e => {
        //                 this.imageSrc = reader.result;
        //             }
        //             reader.readAsDataURL(file)
        //             this.snackBar.triggerSnackBar({
        //                 message: 'Profile image updated successfully',
        //                 type: 'success'
        //             })
        //         }
        //     }).catch(error => {
        //     })

        // }
    }

    UploadImage(): Promise<any> {
        let url = GetApiurl('users/profile-image/');
        let formData: FormData = new FormData();
        return new Promise((resolve, reject) => {
            formData.append('profile_image', this.upload_file, this.upload_file.name);
            formData.append('user_id', this.user.id.toString())
            let xhr = new XMLHttpRequest();
            xhr.open('post', url, true);
            xhr.setRequestHeader('X-Requested-With', "XMLHttpRequest");
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('token'));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    if (xhr.status == 200) {
                        resolve(JSON.parse(xhr.response));
                    }
                    else {
                        reject(xhr.response);
                    }
                }
            }

        })
    }

    updatePassword(tempVarFrm) {
        let data = this.changePasswordForm.value;
        delete data['password']
        this.usersService.changePassword(data)
            .subscribe(
                (response) => {
                    if (response['status'] == 'success') {
                        this.snackBar.triggerSnackBar({
                            message: response['message'],
                            type: 'success'
                        })
                        tempVarFrm.resetForm();
                        this.changePasswordForm.reset();
                    } else if (response['status'] == 'failed') {
                        this.changePasswordForm.controls['currentPassword'].setErrors({ "incorrect": "Incorrect current password" });
                    }
                    this.InitialPasswordFormValues = JSON.stringify(this.changePasswordForm.value);
                },
                (error) => {
                    this.snackBar.triggerSnackBar({
                        message: error,
                        type: 'error'
                    })
                }
            )

    }

    updateProfile() {
        if (this.profileForm.valid) {
            let data = this.profileForm.value;
            data['contact'] = {
                "address_one": data['address_one'],
                "address_two": data['address_two'],
                "zip_code": data['zip_code'],
                "city": data['city'],
                "state": data['state'],
                "country": data['country'],
                "phone_list": data['phone_list'],
                "email_list": data['email_list'],
            }
            let keys = Object.keys(data)
            keys.forEach((element, index) => {
                if (element in data['contact']) delete data[element]
                if (this.user[element] == data[element]) {
                    delete data[element]
                }
            });
            data['contact']['address_one'] == this.contactDetails['address_one'] ? delete data['contact']['address_one'] : data['contact']['address_one'];
            data['contact']['address_two'] == this.contactDetails['address_two'] ? delete data['contact']['address_two'] : data['contact']['address_two'];
            data['contact']['zip_code'] == this.contactDetails['zip_code'] ? delete data['contact']['zip_code'] : data['contact']['zip_code'];
            !this.checkCity(data['contact']['city']) ? delete data['contact']['city'] : data['contact']['city'];
            !this.checkState(data['contact']['state']) ? delete data['contact']['state'] : data['contact']['state'];
            !this.checkCountry(data['contact']['country']) ? delete data['contact']['country'] : data['contact']['country'];
            !this.checkEmailList(data['contact']['email_list']) ? delete data['contact']['email_list'] : data['contact']['email_list'] = this.changeEmailList(data['contact']['email_list']);
            !this.checkPhoneList(data['contact']['phone_list']) ? delete data['contact']['phone_list'] : data['contact']['phone_list'] = this.changePhoneList(data['contact']['phone_list']);
            if ('country_code' in data) {
                if (data['country_code']['value'] != this.user.country_code)
                    data['country_code'] = data['country_code']['value'];
                else delete data['country_code'];
            }
            if ('first_name' in data) {
                data['name'] = data['last_name'] + ' ' + this.user['first_name'];
            }
            if ('last_name' in data) {
                data['name'] = this.user['last_name'] + ' ' + data['first_name']
            }
            if (!Object.keys(data['contact']).length) delete data['contact']
            if (Object.keys(data).length) {
                let change_keys = Object.keys(data);
                this.inProgress = true;
                this.usersService.updateProfile(data).subscribe(
                    (response: any) => {
                        if (response['status'] == 'success') {
                            this.user = response['user'];
                            let user_data;
                            this.globalService.user$.subscribe(data => {
                                user_data = data;
                            });
                            change_keys.forEach(element => {
                                user_data[element] = response['user'][element];
                            })
                            this.getUserData();
                            if (response['user']['profile_image'] != null)
                                user_data['profile_image'] = response['user']['profile_image'] + "?" + Math.floor(Math.random() * 100) + 1;
                            this.globalService.setUser(user_data);
                            this.snackBar.triggerSnackBar({
                                message: response['message'],
                                type: 'success'
                            })
                        } else if (response['status'] == 'failed') {
                            this.snackBar.triggerSnackBar({
                                message: response['message'],
                                type: 'error'
                            })
                        }
                        this.inProgress = false;
                    },
                    (error: any) => {
                        this.snackBar.triggerSnackBar({
                            message: error,
                            type: 'error'
                        });
                        this.inProgress = false;
                    }
                );
            } else {
                this.getUserData();
                this.snackBar.triggerSnackBar({
                    message: "No changes detected",
                    type: 'warn'
                })
            }
        }
    }

    checkCity(value: any) {
        if (value && this.contactDetails['city']) {
            if (value !== this.contactDetails['city']) return true;
            else return false;
        }
        else if (!value && !this.contactDetails['city']) return false;
        else if (!value && this.contactDetails['city']) return true;
        else return true;
    }

    checkState(value: any) {
        if (value && this.contactDetails['state']) {
            if (value !== this.contactDetails['state']) return true;
            else return false;
        }
        else if (!value && !this.contactDetails['state']) return false;
        else if (!value && this.contactDetails['state']) return true;
        else return true;
    }

    checkCountry(value: any) {
        if (value && this.contactDetails['country']) {
            if (value['country'] !== this.contactDetails['country']['country']) return true;
            else return false;
        }
        else if (!value && !this.contactDetails['country']) return false;
        else if (!value && this.contactDetails['country']) return true;
        else return true;
    }

    checkPhoneList(value: Array<any>) {
        if (this.contactDetails.contact_phone.length) {
            if (value.length == this.contactDetails.contact_phone.length) {
                let valueChange: boolean;
                for (let element of value) {
                    valueChange = true;
                    for (let phone of this.contactDetails.contact_phone) {
                        if (phone['phone'] == element['phone']) {
                            if (phone['phone_type'] == element['phone_type']['value'] &&
                                phone['country_code'] == element['country_code']['value']) { valueChange = false; break; }
                            else return true;
                        }
                    }
                    if (valueChange) return true;
                }
                return false;
            }
            return true;
        }
        else {
            for (let element of value)
                if (element['phone'] != '') return true;
            return false;
        }
    }

    changePhoneList(val: Array<any>) {
        let newPhoneList = { add: [], remove: [], change: [] };
        let tempPhoneList = this.contactDetails.contact_phone.concat([]);
        let value = val.concat([]);

        value.forEach((el, idx) => { if (el['phone'] == '') value.splice(idx, 1); });
        if (tempPhoneList.length)
            this.contactDetails.contact_phone.forEach((tempEl, tempIdx) => {
                val.forEach((valEl, valIdx) => {
                    if (tempEl['phone'] == valEl['phone']) {
                        if (tempEl['phone_type'] == valEl['phone_type']['value'] &&
                            tempEl['country_code'] == valEl['country_code']['value']) {
                            value.splice(value.indexOf(valEl), 1);
                            tempPhoneList.splice(tempPhoneList.indexOf(tempEl), 1);
                        }
                        else {
                            delete value[value.indexOf(valEl)]['phone'];
                            tempPhoneList.splice(tempPhoneList.indexOf(tempEl), 1);
                            if (tempEl['phone_type'] == valEl['phone_type']['value'])
                                delete value[value.indexOf(valEl)]['phone_type'];
                            if (tempEl['country_code'] == valEl['country_code']['value'])
                                delete value[value.indexOf(valEl)]['country_code'];
                            let change = value.splice(value.indexOf(valEl), 1)[0];
                            change['id'] = tempEl['id'];
                            newPhoneList['change'].push(change);
                        }
                    }
                });
            });
        newPhoneList['add'] = value.concat([]);
        newPhoneList['remove'] = tempPhoneList.concat([]);
        return newPhoneList;
    }

    checkEmailList(value: Array<any>) {
        if (this.contactDetails.contact_email.length) {
            if (value.length == this.contactDetails.contact_email.length) {
                let valueChange: boolean;
                for (let element of value) {
                    valueChange = true;
                    for (let email of this.contactDetails.contact_email) {
                        if (email['email'] == element['email']) { valueChange = false; break; }
                    }
                    if (valueChange) return true;
                }
                return false;
            }
            return true;
        }
        else {
            for (let element of value)
                if (element['email'] != '') return true;
            return false;
        }
    }

    changeEmailList(val: Array<any>) {
        let newEmailList = { add: [], remove: [] };
        let tempEmailList = this.contactDetails.contact_email.concat([]);
        let value = val.concat([]);

        value.forEach((el, idx) => { if (el['email'] == '') value.splice(idx, 1); });
        if (tempEmailList.length)
            this.contactDetails.contact_email.forEach((tempEl, tempIdx) => {
                val.forEach((valEl, valIdx) => {
                    if (tempEl['email'] == valEl['email']) {
                        value.splice(value.indexOf(valEl), 1);
                        tempEmailList.splice(tempEmailList.indexOf(tempEl), 1);
                    }
                });
            });
        newEmailList['add'] = value.concat([]);
        newEmailList['remove'] = tempEmailList.concat([]);
        return newEmailList;
    }

    confirm(): Promise<any> {
        return new Promise((resolve) => {
            if (!this.submitChange && this.checkValueChange()) {
                const dialogConfig = new MatDialogConfig();
                // dialogConfig.position = { top: '70px' };
                dialogConfig.width = '500px';
                dialogConfig.disableClose = true;
                dialogConfig.data = { 'yes': null, 'no': null };
                let dialogRef = this.dialog.open(ActivateDialogComponent, dialogConfig);
                dialogRef.afterClosed().subscribe(
                    value => {
                        if (value == 'yes') {
                            if (localStorage.getItem('tokenData')) localStorage.removeItem('tokenData');
                            this.dialog.closeAll();
                            resolve(true);
                        } else {
                            if (localStorage.getItem('tokenData')) {
                                let data = JSON.parse(localStorage.getItem('tokenData'))
                                localStorage.setItem("token", data['token']);
                                localStorage.setItem("firebase:host:phoenix-d7e6a.firebaseio.com", data['firebase:host:phoenix-d7e6a.firebaseio.com']);
                                localStorage.removeItem('tokenData');
                            }
                            this.dialog.closeAll();
                            resolve(false);
                        }
                    }
                );
            }
            else {
                resolve(true);
            }
        })
    }

}

@Component({
    selector: 'profile-crop',
    templateUrl: 'profile-crop-dialog.html',
    styleUrls: ['./profile-crop-dialog.component.scss']
})
export class ProfileCropModal {

    imageChangedEvent: any;
    croppedImage: any;
    ImageFile: any;
    userDetails: any;
    progress: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ProfileCropModal>,
        private snackBar: SnackbarCongiration,
        private usersService: UsersService,
        private globalService: GlobalService,
        public dialog: MatDialog,

        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.imageChangedEvent = data['file'];
        this.userDetails = data['userDetails']
    }

    onNoClick(): void {
        this.dialog.closeAll();
        this.imageChangedEvent = true;
    }

    uploadImage() {
        this.progress = true
        this.usersService.profilePicUpdate(this.ImageFile).subscribe(
            result => {
                this.progress = false
                let user_data;
                this.globalService.user$.subscribe(data => {
                    user_data = data;
                    user_data['profile_image'] = result['user']['profile_image'] + "?" + Math.floor(Math.random() * 100) + 1;;
                });
                this.globalService.setUser(user_data);
                // const reader = new FileReader();
                // reader.onload = e => {
                //     this.imageSrc = reader.result;
                // }
                // reader.readAsDataURL(this.upload_file
                //     )
                this.snackBar.triggerSnackBar({
                    message: 'Profile image updated successfully',
                    type: 'success'
                })
            }
        )
        this.dialogRef.close();
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.ImageFile = { 'file': this.croppedImage, 'name': this.userDetails['first_name'] + '.png' }
    }
    imageLoaded(event) {
    }
    cropperReady(sourceImageDimensions: Dimensions) {
    }
    loadImageFailed() {
    }
}
