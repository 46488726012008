import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, Inject, ViewEncapsulation, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DeleteDialogComponent } from 'app/shared/components/dialog-box/delete-dialog/delete-dialog.component';
import { ValidationService } from 'app/shared/services/validation.service';
import { MxGraphService } from 'app/support/services/mx-graph.service';


export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'YYYY',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'conditional-block-panel',
  templateUrl: './conditional-block-panel.component.html',
  styleUrls: ['./conditional-block-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConditionalBlockPanelComponent implements OnInit, AfterViewInit {

  name: 'conditionalBlock';
  showDeleteBtn: boolean;
  isEdit: boolean = false;
  activeField: any = {};
  isConditional: boolean = false;
  @Input() operators: any;
  @Input() isMarketPlaceView: boolean = false;
  hasChild: boolean = false;

  @Output() outputModel: EventEmitter<any> = new EventEmitter<any>();
  @Output() edit: EventEmitter<any> = new EventEmitter<any>();
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @ViewChildren('matSelect_j') matSelect_j;
  @ViewChildren('matSelect_k') matSelect_k;
  taskForm: FormGroup = new FormGroup({});
  conditionalFields: any = [];

  inputValue: any;
  inputModal: any
  temp_data: any;
  selectedCell: any = {};
  show_operator: boolean = false;
  condition_val: any;
  selected_input: any;
  select_option: Array<any> = [];
  assessmentList: any;
  activeMetaDatas: any;
  activeInputs: any = {};
  deleteModalContent: any = {
      title: '',
      message: ''
  };
  selectedBlock: string = '';
  deleteConfirmTextInput: string = '';
  openDeleteConfirmation: boolean = false;
  activateCondtions: boolean = false;
  grp_rule: boolean = false;
  valuesArr: Array<any> = []
  operatorValuesList: any;
  grpNameList:any;
  dayList = [
    {id:0, value:'Days'},
    {id:1, value:'Weeks'},
    {id:2, value:'Months'},
    {id:3, value:'Years'}
];
scoreList = [
    {id:0, value:'Overall'},
    {id:1, value:'Latest'},
];
enableSaveBtn = false;

  constructor(
    private fb: FormBuilder,
    private mx_graphService: MxGraphService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConditionalBlockPanelComponent>,
  ) {
    this.isEdit = this.data['isEdit'];
    this.grpNameList = this.data['grp_name_list'];
    this.activeMetaDatas = this.data['triggerData'];
    this.hasChild = this.data.cell.getEdgeCount() > 1;
    this.inputModal = this.data['selectedBlockModal']['config']['fields'];
    this.taskForm = this.fb.group({
      conditional_field: ['', Validators.required],
      operator: [''],
      code: [''],
      code2: [''],
      unit: [''],
      all_operators: false,
      assessment_list: [''],
      item_name: [''],
      legend_name: ['']
    })
    if (this.data['selectedBlockModal'].isConditional == true && this.data['cell']['value']['name'] == 'conditionalBlock' && !this.isEdit) {
      this.activeField = this.data['activeField'];
      this.selectedBlock = 'conditionalBlock';
      this.taskForm.get('operator').setValidators(Validators.required);
      this.taskForm.get('code').setValidators(Validators.required);
      if(this.activeField.text == 'Total Score' || this.activeField.input == 'unit'){
        this.taskForm.get('unit').setValidators(Validators.required);
      }
    }
    if(this.data['selectedBlockModal'].isConditional == true && this.data['cell']['value']['name'] == 'multiconditionalBlock'){
      this.selectedBlock = 'multiconditionalBlock';
    }
    this.taskForm.valueChanges.subscribe({
      next: (res) => {
          this.checkCondition();
      }
    })
  }

  ngOnInit() {
    this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
    if (this.isEdit) {
      let parent = this.data.mxGraph.getModel().getCell(this.data.cell['parentId']);
      this.taskForm.get('conditional_field').setValue(this.data['selectedBlockModal']['model']['col_name']);
      if(this.data['selectedBlockModal'].isConditional == true && parent['block_name'] == 'conditionalBlock'){
        this.activeField = this.data['activeField'];
        if(this.activeField.text == 'Total Score' || this.activeField.input == 'unit'){
          this.taskForm.get('unit').setValidators(Validators.required);
        }
        this.selectedBlock = 'conditionalBlock';
        this.taskForm.get('operator').setValidators(Validators.required);
        this.taskForm.get('code').setValidators(Validators.required);
        this.taskForm.get('operator').setValue(this.data['selectedBlockModal']['condition']['operator']);
        this.taskForm.get('code').setValue(this.data['selectedBlockModal']['condition']['value']);
        this.taskForm.get('code2').setValue(this.data['selectedBlockModal']['condition']['value2']);
        this.taskForm.get('unit').setValue(this.data['selectedBlockModal']['condition']['unit']);
        this.temp_data = this.data['selectedBlockModal']['condition']['value']
        if(this.temp_data == 'English' && !['contains', 'does not contains'].includes(this.data['selectedBlockModal']['condition']['operator'])) this.taskForm.get('code').setValue('EN');
        if(this.temp_data == 'Spanish' && !['contains', 'does not contains'].includes(this.data['selectedBlockModal']['condition']['operator'])) this.taskForm.get('code').setValue('SP');
        this.outputModel.emit(this.temp_data);
        this.edit.emit(this.isEdit);
      }
      if(this.data['selectedBlockModal'].isConditional == true && parent['block_name'] == 'multiconditionalBlock'){
        this.selectedBlock = 'multiconditionalBlock';
        this.edit.emit(this.isEdit);
      }
    }
  }

  ngAfterViewInit() {

  }

setOperator(){
  this.taskForm.get('code').setValue('');
  this.taskForm.get('code2').setValue('');
  this.taskForm.get('unit').setValue('');
  if((this.taskForm.get('operator').value === 'equal' || this.taskForm.get('operator').value === 'not equal') && 
  (this.activeField.text == 'Clinician Email (EMR)' || this.activeField.text == 'Patient Email' || this.activeField.text == 'Patient Email (EMR)')){
      this.taskForm.get('code').setValidators([Validators.required, ValidationService.emailValidator]);
  }else{
      this.taskForm.get('code').setValidators([Validators.required]);
  }
  if(this.taskForm.get('operator').value === 'exist' || this.taskForm.get('operator').value === 'not exist' || 
  this.taskForm.get('operator').value === 'empty' || this.taskForm.get('operator').value === 'not empty' ||
  this.taskForm.get('operator').value === 'Yes' || this.taskForm.get('operator').value === 'No'){
      this.taskForm.get('code').setValue(['?']);
  }else if(this.taskForm.get('operator').value === 'between'){
        this.taskForm.get('code2').setValidators(Validators.required);
  }else{
    this.taskForm.get('code2').clearValidators();
  }
  this.taskForm.get('code').updateValueAndValidity();
  this.taskForm.get('code2').updateValueAndValidity();
  this.taskForm.get('unit').updateValueAndValidity();
  this.taskForm.get('all_operators').setValue(false);
}

selectConditionCode(evt,i){
  if(evt.value == 'EN') this.taskForm.value['code'] = 'English';
  if(evt.value == 'SP') this.taskForm.value['code'] = 'Spanish';
}

  addValue() {
    this.mx_graphService.setGraphLoadingTrue()
    let check = (this.data['selectedBlockModal']['model']['col_name'] != this.taskForm.value['conditional_field']);
    if (this.hasChild && (this.isEdit && check)) {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.width = '40%';
      dialogConfig.data = { 'yes': null, 'no': null };
      dialogConfig.position = { top: '70px' };
      dialogConfig.disableClose = true,
        dialogConfig.data = {
          title: 'Save Changes?',
          sub_title: 'Changing this conditional block will not match the conditions in its child blocks. All child blocks attached to this conditional block will be removed. Would you like to continue?',
          no_button: 'No, keep it',
          yes_button: 'Yes, continue with change'
        }
      let deleteRef = this.dialog.open(DeleteDialogComponent, dialogConfig);
      deleteRef.afterClosed().subscribe(
        value => {
          if (value == 'yes') {
            this.dialogRef.close('remove');
          }
        }
      );
    } else {
      this.data['selectedBlockModal']['model'].col_name = this.taskForm.value['conditional_field'];
      if(this.isEdit){
        let parent = this.data.mxGraph.getModel().getCell(this.data.cell['parentId']);
        if (this.data['selectedBlockModal'].isConditional == true && parent['block_name'] == 'conditionalBlock' ) {
          this.data['selectedBlockModal']['condition']['operator'] = this.taskForm.value['operator'];
          this.data['selectedBlockModal']['condition']['value'] = this.taskForm.value['code'];
          this.data['selectedBlockModal']['condition']['value2'] = this.taskForm.value['code2'];
          this.data['selectedBlockModal']['condition']['unit'] = this.taskForm.value['codunite'];
        }
        if(this.data['selectedBlockModal'].isConditional == true && parent['block_name'] == 'multiconditionalBlock' ) {
          if(this.data['selectedBlockModal']['condition']['rules'] == undefined){
            this.data['selectedBlockModal']['condition'] = this.taskForm.value;
        }else{
            this.data['selectedBlockModal']['condition']['value'] = this.taskForm.value;
        }
        }
      }else{
        if (this.data['selectedBlockModal'].isConditional == true && this.data['cell']['value']['name'] == 'conditionalBlock') {
          this.data['selectedBlockModal']['condition']['operator'] = this.taskForm.value['operator'];
          this.data['selectedBlockModal']['condition']['value'] = this.taskForm.value['code'];
          this.data['selectedBlockModal']['condition']['value2'] = this.taskForm.value['code2'];
          this.data['selectedBlockModal']['condition']['unit'] = this.taskForm.value['unit'];
        }
        if(this.data['selectedBlockModal'].isConditional == true && this.data['cell']['value']['name'] == 'multiconditionalBlock') {
          this.data['selectedBlockModal']['condition'] = this.taskForm.value;
        }
      }
      let output = {
        data: this.data
      }
      this.dialogRef.close(output);
    }

  }

  removeCell() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40%';
    dialogConfig.data = { 'yes': null, 'no': null };
    dialogConfig.position = { top: '70px' };
    dialogConfig.disableClose = true,
      dialogConfig.data = {
        title: 'Delete Flow?',
        sub_title: 'Are you sure? You want to delete this flow?',
        no_button: 'No, keep it',
        yes_button: 'Yes, Delete'
      }
    let deleteRef = this.dialog.open(DeleteDialogComponent, dialogConfig);
    deleteRef.afterClosed().subscribe(
      value => {
        this.mx_graphService.setGraphLoadingTrue()
        if (value == 'yes') {
          this.dialogRef.close('remove');
        }
      }
    );
  }

  closeModal() {
    this.mx_graphService.setGraphLoadingTrue()
    this.dialogRef.close('close');
  }

  checkConditionOperators(i){
    let count;
    count = this.taskForm.controls['code'].value;
    if (count.length == this.activeField.options.length) {
      this.taskForm.get('all_operators').setValue(true);
    } else {
      this.taskForm.get('all_operators').setValue(false);
    }
  }

  checkCondition() {
    if (this.data['selectedBlockModal'].isConditional == true && this.selectedBlock == 'multiconditionalBlock') {
        if (this.taskForm.controls.rules) {
            if(this.taskForm.controls.rules.value.length == 0){
                this.enableSaveBtn = false;
                return;
            } 
            if (this.taskForm.controls.rules.value && this.taskForm.controls.rules.value.length) {
                const all_check = this.taskForm.controls.rules.value.map((e, i) => {
                    const sub_rule_check = e.rules.map((se, j) => {
                        if (se.rules) {
                            if (se.rules[j] && se.rules[j]['condition']) {
                                const sub_check = se.rules.map((el) => {
                                    const check_sub_length = el.rules.length > 0;
                                    return check_sub_length ? this.taskForm.valid : check_sub_length;
                                });
                                return sub_check.every(u => !!u);
                            }
                            const check_sub_length = se.rules.length > 0;
                            return check_sub_length ? this.taskForm.valid : check_sub_length;
                        }
                        return this.taskForm.valid;
                    });
                    return sub_rule_check.length > 0 ? sub_rule_check.every(u => !!u) && this.taskForm.valid : false;
                });
                this.enableSaveBtn = all_check.every(v => !!v);
                return;
            }
        }
    }else{
        this.enableSaveBtn = this.taskForm.valid;
        return
    }
}
  
  selectCondtionAllOperators(event) {
    try {
      if (event.checked) {

        this.taskForm.get('all_operators').setValue(true);
        this.operatorValuesList = this.activeField.options;
        this.operatorValuesList.forEach(e => {
            if (e){
            this.valuesArr.push(e.id);
            }
        })
        this.taskForm.get('code').setValue(this.valuesArr.concat([]))
        this.matSelect_k._results[0].options._results.forEach((x,y) => {
          x.select();
        });
      } else {
        this.matSelect_k._results[0].options._results.forEach((x,y) => {
          x.deselect();
        });
        this.taskForm.get('all_operators').setValue(false);
        this.taskForm.get('code').setValue([]);
      }
    }
    finally {
      this.valuesArr = [];
    }
  }

}

