import { ElementRef, Injectable} from '@angular/core';
import { mxgraph, mxgraphFactory } from     "mxgraph-factory";
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Subject } from 'rxjs';
import { AddBlocksPanelComponent } from 'app/features/main/workflow-modals/add-blocks-panel/add-blocks-panel.component';
import { TriggerRulePanelComponent } from 'app/features/main/workflow-modals/trigger-rule-panel/trigger-rule-panel.component';
import { DeleteDialogComponent } from 'app/shared/components/dialog-box/delete-dialog/delete-dialog.component';
import { SnackbarCongiration } from 'app/shared/components/snack-bar/SnackbarConfiguration';
import { includes } from 'lodash';
import { title } from 'process';
import { Title } from '@angular/platform-browser';
import { CustomMxCodec } from '../classes/codec';

declare var _;
declare var $;

const {
    mxGraph,
    mxCodec,
    mxImage,
    mxUtils,
    mxCellOverlay,
    mxConstants,
    mxPoint,
    mxCell,
    mxEvent,
    mxKeyHandler,
    mxHierarchicalLayout,
    mxMorphing,
    mxEdgeStyle,
    mxCompactTreeLayout,
    mxLayoutManager,
    mxOutline,
    mxDivResizer,
    mxCellTracker,
    mxCellHighlight,
    mxRubberband,
} = mxgraphFactory({
    mxLoadResources: false,
    mxLoadStylesheets: true,
});

const HIGHLIGHT_COMPLETED = '#0FA90C';
const HIGHLIGTT_SKIPPED_OR_FAILED = '#A90C0C';
const HIGHLIGHT_RUNNING = '#A9A9A9';

const addBtnUrl = '/assets/icons/extra-icons/add.svg';
const cancelBtn = '/assets/icons/extra-icons/cancel.png';
const editBtnUrl = '/images/mx-graph/edit.svg';

@Injectable({
    providedIn: 'root'
})
export class MxGraphService {
    mxGraph: mxgraph.mxGraph;
    element: ElementRef;
    outlineContainer: ElementRef;
    diagramComponent:any;
    options:any={};
    xmlSource:string=null;
    mxVertexHandler: mxgraph.mxVertexHandler;
    selectedMainObjectFields:any = null;
    mxOutline:mxgraph.mxOutline = null;
    unsaveConfirm:boolean=false;

    blockWidth:number = 250;
    blockHeight:number = 80;

    emailBlockWidth = 450;
    emailBlockHeight = 140;

    officeTaskBlockWidth = 450;
    officeTaskBlockHeight = 140;

    delayBlockWidth = 260;
    delayBlockHeight = 140;

    multiConditionBlockWidth = 180;
    multiConditionBlockHeight = 150;

    conditionBlockSize = 180;
    multipleBlockSize = 180;
    viewMode: boolean = false;
    isMarketPlaceView: boolean = false;
    isScheduler: boolean;

    //view diagram
    fieldsData:any = null;
    debugMode:boolean = false;
    tagName:any[] = [];
    cellTracker :mxgraph.mxCellTracker = null;

    cellHighlighter:any={};
    mxLayout:mxgraph.mxCompactTreeLayout;
    // doRegenerateDag:boolean = false;
    block_name:any;
    mainObj:any
    default_fields: [];
    // clinician_fields: [];
    public clinicData = new Subject<any>();
    public conditionData = new Subject<any>();
    public mainObject = new Subject<any>(); 
    selectedTriggerdata:any = null;
    includeIds = [];
    
    blocksSize:any = {
        addjobBlock: {
            width: 280,
            height: 120
        },
        emailBlock: {
            width: 450,
            height: 140
        },
        officeTaskBlock: {
            width: 450,
            height: 160
        },
        delayBlock: {
            width: 260,
            height: 120
        },
        chatmessageBlock: {
            width: 450,
            height: 140
        },
        smsBlock: {
            width: 420,
            height: 140
        },
        createAppointmentBlock: {
            width: 450,
            height: 250
        },
        reassignBlock: {
            width: 450,
            height: 250
        },
        launchAssessmentBlock:{
            width: 350,
            height: 140
        },
        rescheduleBlock:{
            width: 450,
            height: 220
        },
        includeReschedule:{
            width: 450,
            height: 220
        },
        includeReassign:{
            width: 450,
            height: 220
        },
        scheduleBlock:{
            width:450,
            height:220
        },
        launchBlock:{
            width: 300,
            height: 120
        },
        updatePatientBlock:{
            width:420,
            height:180
        },
        includeBlock: {
            width: 400,
            height: 150
        },
        runBlock: {
            width: 375,
            height: 150
        }
    };
    initialConfigure: any;
    conditionalData: any;
    workflowAction: any;
    assessmentList: any;
    grpNameList:any;
    textLength: any;
    edgeBlockNameList: Array<String> = [];
    filterChildArr = [];
    gotoActivate: boolean = false;
    includeActivate : boolean = false;
    goto_id: '';
    schedule_id: '';
    selected_parent_ids = [];
    graphLoading: boolean = false;
    previousSourceXml: string = null;
    previousCell: any;
    previousResult: any;
    newlyAddedCells: Array<any> = [];
    targetIds: Array<any> = [];
    constructor(
        private sidePanelService: MatDialog , 
        private snackBar: SnackbarCongiration      
    ) {
    }

    conditionDataGet() {
        return this.conditionData.asObservable();
    }

    getObject(data){
        this.gotoActivate = false;
        this.includeActivate = false;
        this.mainObj = data['mainObjects'];
        this.default_fields = data['patient_field_list']; 
        // this.clinician_fields = data['clinician_field_list'];
        // this.assessmentList = data['assessmentList']
    }

    getmainObj(){
        this.mainObject.next(this.mainObj);
        return this.mainObject.asObservable();
    }

    processXml(inputXml) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(inputXml, "application/xml");

        const nodes = xmlDoc.querySelectorAll('[input="string"]');

        nodes.forEach(node => {
            const valueAttr = node.getAttribute("value");
            if (valueAttr !== null) {
                const cdataSection = xmlDoc.createCDATASection(valueAttr);
                
                node.removeAttribute("value");

                const cdataNode = xmlDoc.createElement("value");
                cdataNode.appendChild(cdataSection);
                node.appendChild(cdataNode);
            }
        });

        const serializer = new XMLSerializer();
        return serializer.serializeToString(xmlDoc);
    }

    getXmlData(){
        return this.getXml();
    }
    
    setDiagramComponent(comp:any) {
        this.diagramComponent=comp;
    }

    getGraphLoading() {
        return this.graphLoading
    }

    setGraphLoadingFalse() {
        this.clearGraphLoading(1000)
    }

    setGraphLoadingTrue() {
        this.graphLoading = true
    }

    getPreviousXmlSource() {
        return this.previousSourceXml
    }

    clearPreviousXmlSource() {
        this.previousSourceXml = ''
        this.gotoActivate = false
    }

    // undoChangesInXml() {
    //     let cell = this.newlyAddedCells
    //     if(cell.length > 0) {
    //         try {
    //             this.mxGraph.getModel().beginUpdate();
    //             let _cells = [];
    //             let _edges = [];
    //             this.mxGraph.traverse(cell[cell.length -1], true, (vertex, edges) => {
    //                 this.graphLoading = true
    //                 console.log(vertex, edges);
    //                 if (vertex.id != 'cs_initializer') {
    //                     _cells.push(vertex);
    //                     _edges.push(edges);
    //                     return true;
    //                 }
    //                 this.graphLoading = false
    //             });
    //             this.mxGraph.removeCells(_cells, true);
    //         }
    //         finally {
    //             this.mxGraph.getModel().endUpdate();
    //         }
    //         this.refresh();
    //     }   
    // }

    

    prepareConditionalEdageTemplate(cell:mxgraph.mxCell) {
        const value = cell.getValue();
        let edgeText = $('<span></span>');
        let displayText;
        // const operatorsDisplayText = this.triggerService.getOperatorsDisplayText();
        let mainContainer = $('<div></div>').addClass('task-block condition-edge-task-block').css({
            textAlign:'center',
            padding:'3px 8px',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            fontSize:'14px',
            color: 'blue'
        });

        // const displayText = value['text'] + ' ' +  operatorsDisplayText[value['operator']] + ' ' +  this.getLabelByText(value['col_name'], value['value']);
        // mainContainer.text(displayText);
        if(value['text'] == "" && cell['block_name'] == 'condition_edge') {
            edgeText.text(this.setConditions(cell['target']['value']['model']));
        } else if (cell['block_name'] == 'else_condition_edge') {
            displayText = 'Else';
        } else if(value['text'] == undefined) {
            if(cell['target']['value']['condition']){
                if(cell['target']['value']['condition']['value'] != undefined){
                    edgeText.text(this.setConditions(cell['target']['value']['condition']['value']));
                }else{
                    edgeText.text(this.setConditions(cell['target']['value']['condition']));
                }  
            }else {
                edgeText.text(this.setConditions(value));
            }
        }else{ 
            if(value['value2']){
                edgeText.text('if - ' + value['text'] + ' ' + value['operator'] + ' ' +  value['value'] + '-' + value['value2']);
            }else if(value['unit']){
                edgeText.text('if - ' + value['text'] + ' ' + value['operator'] + ' ' +  value['value'] + '-' + value['unit']);
            }else{
                edgeText.text('if - ' + value['text'] + ' ' + value['operator'] + ' ' +  value['value']);
            }
            
        }
        edgeText.css({width: '100%',display: 'inline-block',
            overflow: 'hidden',
            // position: 'absolute',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth:'250px'}).text(displayText)
        mainContainer.css({cursor: 'pointer'});
        
        return mainContainer.append(edgeText);
    };

    prepareMultiCondition(cell:mxgraph.mxCell){
        const value = cell.getValue();
        let temp = [];
        // value['model']['condition_fields'].forEach(el => {
        //     temp.push(el.text);
        // })
        // temp.push(this.setConditions(value['model']));
        const {condition} = value;
        let assignedTo = temp;
        let tagsDisplay = '--';
        let mainContainer = $('<div></div>').addClass('delay-block').css({width: this.multiConditionBlockWidth, textAlign:'center', padding:'15px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingTop: 10, whiteSpace: 'break-spaces',fontWeight:'bold'}).text('Select condition');
        let taskDiv = $('<div></div>').addClass('task-title');
        let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('d-block').css({paddingBottom:'10px', whiteSpace: 'break-spaces'});
        let assignContent = $('<div></div>').css({color:'blue', paddingLeft:5}).text(assignedTo);
        let bodyLine2 = $('<div></div>').addClass('d-block').css({whiteSpace: 'break-spaces'});
        bodyLine.append(assignContent);
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        mainContainer.append(separator);
        if(assignedTo) {
            mainContainer.append(bodyLine);
        }
        mainContainer.append(bodyLine2);
        let len = (temp.length);
        
        // mainContainer.css({width: len})
        return mainContainer;
    }

    preparePatientCreate(cell:mxgraph.mxCell){
        const value = cell.getValue();
        let mainContainer = $('<div></div>').addClass('office-task-block').css({width: this.multiConditionBlockWidth, textAlign:'center', padding:'15px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingTop: 10,fontWeight:'bold'}).text('Create Patient');
        let taskDiv = $('<div></div>').addClass('task-title');
        // let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('d-block').css({paddingBottom:'10px', whiteSpace: 'break-spaces'});
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        // mainContainer.append(separator);
        mainContainer.append(bodyLine);
        return mainContainer;
    }

    prepareClinicianCreate(cell:mxgraph.mxCell){
        const value = cell.getValue();
        let mainContainer = $('<div></div>').addClass('office-task-block').css({width: this.multiConditionBlockWidth, textAlign:'center', padding:'15px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingTop: 10,fontWeight:'bold'}).text('Create Clinician');
        let taskDiv = $('<div></div>').addClass('task-title');
        // let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('d-block').css({paddingBottom:'10px', whiteSpace: 'break-spaces'});
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        // mainContainer.append(separator);
        mainContainer.append(bodyLine);
        return mainContainer;
    }

    prepareClinicianUpdate(cell:mxgraph.mxCell){
        const value = cell.getValue();
        let mainContainer = $('<div></div>').addClass('office-task-block').css({width: this.multiConditionBlockWidth, textAlign:'center', padding:'15px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingTop: 10,fontWeight:'bold'}).text('Update Clinician');
        let taskDiv = $('<div></div>').addClass('task-title');
        // let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('d-block').css({paddingBottom:'10px', whiteSpace: 'break-spaces'});
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        // mainContainer.append(separator);
        mainContainer.append(bodyLine);
        return mainContainer;
    }

    prepareClinicianAssign(cell:mxgraph.mxCell){
        const value = cell.getValue();
        let mainContainer = $('<div></div>').addClass('office-task-block').css({width: this.multiConditionBlockWidth, textAlign:'center', padding:'15px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingTop: 10,fontWeight:'bold'}).text('Assign Clinician');
        let taskDiv = $('<div></div>').addClass('task-title');
        // let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('d-block').css({paddingBottom:'10px', whiteSpace: 'break-spaces'});
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        // mainContainer.append(separator);
        mainContainer.append(bodyLine);
        return mainContainer;
    }

    preparePatientUpdate(cell:mxgraph.mxCell){
        const value = cell.getValue();
        let temp = [];
        value['model'].forEach( el => {
            temp.push(el.value);
        })
        const {condition} = value;
        let assignedTo = temp;
        let mainContainer = $('<div></div>').addClass('office-task-block').css({width: this.multiConditionBlockWidth, textAlign:'center', padding:'15px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingTop: 10,fontWeight:'bold'}).text('Update Patient');
        let taskDiv = $('<div></div>').addClass('task-title');
        // let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('d-block').css({paddingBottom:'10px', whiteSpace: 'break-spaces'});
        let assignTitle = $('<span></span>').css({fontWeight:'bold'}).text('Field(s) to update -');
        let assignContent = $('<span></span>').css({color:'blue', paddingLeft:5}).text(assignedTo);
        bodyLine.append(assignTitle);
        bodyLine.append(assignContent);
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        // mainContainer.append(separator);
        mainContainer.append(bodyLine);
        return mainContainer;
    }

    prepareReassignAppointment(cell:mxgraph.mxCell){
        const value = cell.getValue();
        let notification = $('<div></div>').text( '(' + 'Notification :' + value['assessment_data']['notificationDevice'] + ')');
        let mainContainer = $('<div></div>').addClass('office-task-block').css({width: this.officeTaskBlockWidth, textAlign:'center', padding:'15px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingTop: 10,fontWeight:'bold'}).text('Reassign Assessment');
        let taskDiv = $('<div></div>').addClass('task-title');
        // let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('d-block').css({paddingBottom:'10px', whiteSpace: 'break-spaces'});
        let bodyLine2 = $('<div></div>').addClass('d-block').css({whiteSpace: 'break-spaces', maxHeight: 150, overflow: 'scroll'});
        if(value['assessment_data']['notification_settings'] == true){
            bodyLine2.append(notification);
        }
        if(value['assessment_data']['assessment_settings'] == true && value['assessment_data']['add_assessment_settings'] != undefined){
            value['assessment_data']['add_assessment_settings'].forEach((e,i)=>{
                let reminder = $('<div></div>');
                let appointment = $('<div></div>').text('IF SmarTest Execution Time ' + e['execution_operator'] + ' ' + e['execution_days'] + ' Days Before day of Appointment,');
                let launch = $('<div></div>');
                if(e['launch_option'] == 1){
                    launch.text('Then Do Nothing');
                }else if(e['launch_option'] == 2){
                    launch.text('Then Run now');
                }else if(e['launch_option'] == 3){
                    launch.text('Then Launch ' + e['launch_days'] + ' Days Before Day of Appointment At ' + e['launch_hrs'] + ' : ' + e['launch_mins']);
                }else{
                    launch.text('Then Launch ' + e['launch_before_hrs'] + ' : ' + e['launch_before_mins'] + ' Before Appointment Time');
                }
                if(e['retry_count'] >= 1){
                    reminder.text('With ' + e['retry_count'] + ' reminder(s) - ')
                    e['retry_remainder'].forEach((ele,ind)=>{
                        if(ele['retry_option'] == 1){
                            reminder.append(ele['retry_days'] + ' Days Before Day of Appointment At ' + ele['retry_hrs'] + ' : ' + ele['retry_mins'] + ', ');
                        }else{
                            reminder.append(ele['retry_before_hrs'] + ' : ' + ele['retry_before_mins'] + ' Before Appointment Time' + ', ');
                        }
                        
                    })
                }
                bodyLine2.append(appointment,launch,reminder);
            })
            let expire = $('<div></div>');
            if(value['assessment_data']['assessment_expiration_option'] == 1){
                expire.text('Link expires at ' + value['assessment_data']['assessment_expire_appointment_days_hrs'] + ' : ' + value['assessment_data']['assessment_expire_appointment_days_mins'] + ' on Day of Appointment' );
            }else{
                expire.text('Link expires at ' + value['assessment_data']['assessment_expire_hrs'] + ' : ' + value['assessment_data']['assessment_expire_mins'] + value['assessment_data']['assessment_expire_operator'] + ' Appointment Time' );
            }
            bodyLine2.append(expire);
        }
        if(value['assessment_data']['respondent']) {
            let respondent = $('<div></div>');
            if(value['assessment_data']['respondent'].length > 0){
                respondent.text('(' + 'Respondent :' + value['assessment_data']['respondent'] + ')');
            }else{
                respondent.text('(' + 'Respondent :' + 'None' + ')');
            }
            bodyLine2.append(respondent)
        }        
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        // mainContainer.append(separator);
        mainContainer.append(bodyLine);
        if(value['assessment_data']) {
            mainContainer.append(bodyLine2);
        }
        return mainContainer;
    }

    prepareCancelAppointment(cell:mxgraph.mxCell){
        const value = cell.getValue();
        let mainContainer = $('<div></div>').addClass('office-task-block').css({width: this.multiConditionBlockWidth, textAlign:'center', padding:'15px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingTop: 10,fontWeight:'bold'}).text('Cancel Assessment');
        let taskDiv = $('<div></div>').addClass('task-title');
        let bodyLine = $('<div></div>').addClass('d-block').css({paddingBottom:'10px', whiteSpace: 'break-spaces'});
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        mainContainer.append(bodyLine);
        return mainContainer;
    }

    // addOverlayGoto(cell){
    //     let overLay: mxgraph.mxCellOverlay = new mxCellOverlay(
    //         new mxImage(addBtnUrl, 5, 5),
    //         'Cancel',
    //         mxConstants.ALIGN_RIGHT,
    //         mxConstants.ALIGN_RIGHT,
    //         new mxPoint(0,0),
    //         'pointer'
    //     );
    //     const geo = this.mxGraph.getCellGeometry(cell);
    //     overLay.cursor = 'hand';
    //     this.mxGraph.addCellOverlay(cell, overLay);
    // }

    prepareGoto(cell:mxgraph.mxCell){
        const value = cell.getValue();
        let mainContainer = $('<div></div>').addClass('office-task-block').css({width: this.multiConditionBlockWidth, textAlign:'center', padding:'15px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingTop: 10,fontWeight:'bold'}).text('Goto');
        let taskDiv = $('<div></div>').addClass('task-title');
        let bodyLine = $('<div></div>').addClass('d-block').css({paddingBottom:'10px', whiteSpace: 'break-spaces'});
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        mainContainer.append(bodyLine);
        return mainContainer;
    }

    setConditions(dic) {
        let res = '';
        let condition = '';
        if (dic['condition'].toUpperCase() === 'AND') condition = 'and';
        else if (dic['condition'].toUpperCase() === 'OR') condition = 'or';
        
        dic['rules'].forEach((val, ind) => {
          if (val['rules']) res += `(${this.setConditions(val)})`;
          if (val['text'] && val['operator'] && val['value'] !== '' &&  val['value2'] !== '' ) {
            res += '(' + (val['text']+' '+ val['operator'] +' '+ val['value'] + '-' + val['value2'] )+ ')';
          }else if (val['unit']){
            res += '(' + (val['text']+' '+ val['operator'] +' '+ val['value'] + '-' + val['unit'])+ ')';
          }else if(val['text'] && val['operator'] && val['value'] !== ''){
            res += '(' + (val['text']+' '+ val['operator'] +' '+ val['value'])+ ')';
          }
          res += (dic['rules'].length !== ind + 1 ? ` ${condition} ` : '');
        });
        return res;
    }
    connectGoto(cell){
        this.graphLoading = true
        let parent = this.mxGraph.getDefaultParent()
        let source = this.mxGraph.getModel().getCell(this.goto_id);
        cell['goto_id'] = this.goto_id;
        source['goto_child'] = cell['id']; 
        this.mxGraph.insertEdge(source, cell['id'], '', source, cell,'curved=1');
        this.resetHightlightedCells();
        // this.mxGraph.getModel().endUpdate();
        this.mxGraph.refresh();
        this.clearGraphLoading(1000)
        this.gotoActivate = false;
    }

    connectInclude(cell){
        this.graphLoading = true
        let target = this.mxGraph.getModel().getCell(this.schedule_id);
        if(cell['targetIds'] != undefined){
            cell['targetIds'].push(this.schedule_id);
        }else{
            cell['targetId'] = this.schedule_id;
        }
        target['parentIds'].push("includeBlock_"+cell['id']);
        this.mxGraph.insertEdge(cell, target['id'], '', cell, target, 'curved=1;strokeColor=#2196F3');
        this.resetHightlightedCells();
        // this.mxGraph.getModel().endUpdate();
        this.mxGraph.refresh();
        this.includeActivate = false;
        this.snackBar.triggerSnackBar({
            message: 'You can prioritize the assessment list in the connected block',
            type: 'failure'
        })
        this.clearGraphLoading(1000)
    }

    prepareLaunch(cell:mxgraph.mxCell){
        const value = cell.getValue();
        let mainContainer = $('<div></div>').addClass('office-task-block').css({width: this.multiConditionBlockWidth, textAlign:'center', padding:'15px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingTop: 10,fontWeight:'bold'}).text('Run Assessment');
        let taskDiv = $('<div></div>').addClass('task-title');
        // let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('d-block').css({paddingBottom:'10px', whiteSpace: 'break-spaces'});
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        // mainContainer.append(separator);
        mainContainer.append(bodyLine);
        return mainContainer;
    }

    prepareSchedule(cell:mxgraph.mxCell){
        const value = cell.getValue();
        let mainContainer = $('<div></div>').addClass('office-task-block').css({width: this.officeTaskBlockWidth, textAlign:'center', padding:'15px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px',fontWeight:'bold'}).text('What do you want to happen?');
        let taskDiv = $('<div></div>').addClass('task-title');
        let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('d-block').css({paddingBottom: '5px',whiteSpace: 'break-spaces',wordBreak:'break-all'});
        let assignTitle = $('<span></span>').css({fontWeight:'bold'}).text('Schedule Assessment');
        let bodyLine2 = $('<div></div>').addClass('d-block').css({whiteSpace: 'break-spaces', maxHeight: 150, overflow: 'scroll'});
        if (value['assessment_data']['respondent']) {
            let respondent = $('<div></div>');
            if (value['assessment_data']['respondent'].length > 0) {
                respondent.text('(' + 'Respondent :' + value['assessment_data']['respondent'] + ')');
            } else {
                respondent.text('(' + 'Respondent :' + 'None' + ')');
            }
            bodyLine2.append(respondent)
        }
        let notification = $('<div></div>').text( '(' + 'Notification :' + value['assessment_data']['notificationDevice'] + ')');
        bodyLine.append(assignTitle);
        if(value['assessment_data']['notification_settings'] == true){
            bodyLine2.append(notification);
        }
        if(value['assessment_data']['assessment_settings'] == true && value['assessment_data']['add_assessment_settings'] != undefined){
            value['assessment_data']['add_assessment_settings'].forEach((e,i)=>{
                let reminder = $('<div></div>');
                let appointment = $('<div></div>').text('IF SmarTest Execution Time ' + e['execution_operator'] + ' ' + e['execution_days'] + ' Days Before day of Appointment,');
                let launch = $('<div></div>');
                if(e['launch_option'] == 1){
                    launch.text('Then Do Nothing');
                }else if(e['launch_option'] == 2){
                    launch.text('Then Run now');
                }else if(e['launch_option'] == 3){
                    launch.text('Then Launch ' + e['launch_days'] + ' Days Before Day of Appointment At ' + e['launch_hrs'] + ' : ' + e['launch_mins']);
                }else{
                    launch.text('Then Launch ' + e['launch_before_hrs'] + ' : ' + e['launch_before_mins'] + ' Before Appointment Time');
                }
                if(e['retry_count'] >= 1){
                    reminder.text('With ' + e['retry_count'] + ' reminder(s) - ')
                    e['retry_remainder'].forEach((ele,ind)=>{
                        if(ele['retry_option'] == 1){
                            reminder.append(ele['retry_days'] + ' Days Before Day of Appointment At ' + ele['retry_hrs'] + ' : ' + ele['retry_mins'] + ', ');
                        }else{
                            reminder.append(ele['retry_before_hrs'] + ' : ' + ele['retry_before_mins'] + ' Before Appointment Time' + ', ');
                        }
                        
                    })
                }
                bodyLine2.append(appointment,launch,reminder,separator);
            })
            let expire = $('<div></div>');
            if(value['assessment_data']['assessment_expiration_option'] == 1){
                expire.text('Link expires at ' + value['assessment_data']['assessment_expire_appointment_days_hrs'] + ' : ' + value['assessment_data']['assessment_expire_appointment_days_mins'] + ' on Day of Appointment' );
            }else{
                expire.text('Link expires at ' + value['assessment_data']['assessment_expire_hrs'] + ' : ' + value['assessment_data']['assessment_expire_mins'] + value['assessment_data']['assessment_expire_operator'] + ' Appointment Time' );
            }
            bodyLine2.append(expire);
        }
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        mainContainer.append(separator);
        mainContainer.append(bodyLine);
        
        if(value['assessment_data']) {
            mainContainer.append(bodyLine2);
        }
        return mainContainer;
    }

    prepareAssignAssessmentTemplate(cell:mxgraph.mxCell){
        const value = cell.getValue();
        let temp = [];
        value['model'].forEach( el => {
            temp.push(el.value);
        })
        const {condition} = value;
        let assignedTo = temp;
        let tagsDisplay = '--';
        let mainContainer = $('<div></div>').addClass('office-task-block').css({width: this.officeTaskBlockWidth, textAlign:'center', padding:'15px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px',fontWeight:'bold'}).text('What do you want to happen?');
        let taskDiv = $('<div></div>').addClass('task-title');
        let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('d-block').css({paddingBottom: '5px',whiteSpace: 'break-spaces',wordBreak:'break-all'});
        let assignTitle = $('<span></span>').css({fontWeight:'bold'}).text('Schedule Assessment');
        let assignContent = $('<span></span>').css({color:'blue', paddingLeft:5}).text(assignedTo);
        let bodyLine2 = $('<div></div>').addClass('d-block').css({whiteSpace: 'break-spaces', maxHeight: 150, overflow: 'scroll'});
        if (value['assessment_data']['respondent']) {
            let respondent = $('<div></div>');
            if (value['assessment_data']['respondent'].length > 0) {
                respondent.text('(' + 'Respondent :' + value['assessment_data']['respondent'] + ')');
            } else {
                respondent.text('(' + 'Respondent :' + 'None' + ')');
            }
            bodyLine2.append(respondent);
        }
        let notification = $('<div></div>').text( '(' + 'Notification :' + value['assessment_data']['notificationDevice'] + ')');
        bodyLine.append(assignTitle);
        bodyLine.append(assignContent);
        if(value['assessment_data']['notification_settings'] == true){
            bodyLine2.append(notification);
        }
        if(value['assessment_data']['assessment_settings'] == true && value['assessment_data']['add_assessment_settings'] != undefined){
            value['assessment_data']['add_assessment_settings'].forEach((e,i)=>{
                let reminder = $('<div></div>');
                let appointment = $('<div></div>').text('IF SmarTest Execution Time ' + e['execution_operator'] + ' ' + e['execution_days'] + ' Days Before day of Appointment,');
                let launch = $('<div></div>');
                if(e['launch_option'] == 1){
                    launch.text('Then Do Nothing');
                }else if(e['launch_option'] == 2){
                    launch.text('Then Run now');
                }else if(e['launch_option'] == 3){
                    launch.text('Then Launch ' + e['launch_days'] + ' Days Before Day of Appointment At ' + e['launch_hrs'] + ' : ' + e['launch_mins']);
                }else{
                    launch.text('Then Launch ' + e['launch_before_hrs'] + ' : ' + e['launch_before_mins'] + ' Before Appointment Time');
                }
                if(e['retry_count'] >= 1){
                    reminder.text('With ' + e['retry_count'] + ' reminder(s) - ')
                    e['retry_remainder'].forEach((ele,ind)=>{
                        if(ele['retry_option'] == 1){
                            reminder.append(ele['retry_days'] + ' Days Before Day of Appointment At ' + ele['retry_hrs'] + ' : ' + ele['retry_mins'] + ', ');
                        }else{
                            reminder.append(ele['retry_before_hrs'] + ' : ' + ele['retry_before_mins'] + ' Before Appointment Time' + ', ');
                        }
                        
                    })
                }
                bodyLine2.append(appointment,launch,reminder,separator);
            })
            let expire = $('<div></div>');
            if(value['assessment_data']['assessment_expiration_option'] == 1){
                expire.text('Link expires at ' + value['assessment_data']['assessment_expire_appointment_days_hrs'] + ' : ' + value['assessment_data']['assessment_expire_appointment_days_mins'] + ' on Day of Appointment' );
            }else{
                expire.text('Link expires at ' + value['assessment_data']['assessment_expire_hrs'] + ' : ' + value['assessment_data']['assessment_expire_mins'] + value['assessment_data']['assessment_expire_operator'] + ' Appointment Time' );
            }
            bodyLine2.append(expire);
        }
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        mainContainer.append(separator);
        if(assignedTo) {
            mainContainer.append(bodyLine);
        }
        if(value['assessment_data']) {
            mainContainer.append(bodyLine2);
        }
        return mainContainer;
    }

    prepareRescheduleAppointment(cell:mxgraph.mxCell){
        const value = cell.getValue();
        let temp = [];
        // value['model'].forEach( el => {
        //     temp.push(el.value);
        // })
        const {condition} = value;
        let assignedTo = temp;
        let tagsDisplay = '--';
        let notification = $('<div></div>').text( '(' + 'Notification :' + value['model']['notificationDevice'] + ')');
        let mainContainer = $('<div></div>').addClass('office-task-block').css({width: this.officeTaskBlockWidth, textAlign:'center', padding:'15px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingBottom: 10,fontWeight:'bold'}).text('What do you want to happen?');
        let taskDiv = $('<div></div>').addClass('task-title');
        let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('d-block').css({paddingBottom: '5px',whiteSpace: 'break-spaces',wordBreak:'break-all'});
        let assignTitle = $('<span></span>').css({fontWeight:'bold'}).text('Reschedule Assessment');
        let assignContent = $('<span></span>').css({color:'blue', paddingLeft:5}).text(assignedTo);
        let bodyLine2 = $('<div></div>').addClass('d-block').css({whiteSpace: 'break-spaces', maxHeight: 150, overflow: 'scroll'});
        bodyLine.append(assignTitle);
        if(value['model']['respondent']) {
            let respondent = $('<div></div>');
            if(value['model']['respondent'].length > 0){
                respondent.text('(' + 'Respondent :' + value['model']['respondent'] + ')');
            }else{
                respondent.text('(' + 'Respondent :' + 'None' + ')');
            }
            bodyLine2.append(respondent)
        }
        if(value['model']['notification_settings'] == true){
            bodyLine2.append(notification);
        }
        if(value['model']['assessment_settings'] == true && value['model']['add_assessment_settings'] != undefined){
            value['model']['add_assessment_settings'].forEach((e,i)=>{
                let reminder = $('<div></div>');
                let appointment = $('<div></div>').text('IF SmarTest Execution Time ' + e['execution_operator'] + ' ' + e['execution_days'] + ' Days Before day of Appointment,');
                let launch = $('<div></div>');
                if(e['launch_option'] == 1){
                    launch.text('Then Do Nothing');
                }else if(e['launch_option'] == 2){
                    launch.text('Then Run now');
                }else if(e['launch_option'] == 3){
                    launch.text('Then Launch ' + e['launch_days'] + ' Days Before Day of Appointment At ' + e['launch_hrs'] + ' : ' + e['launch_mins']);
                }else{
                    launch.text('Then Launch ' + e['launch_before_hrs'] + ' : ' + e['launch_before_mins'] + ' Before Appointment Time');
                }
                if(e['retry_count'] >= 1){
                    reminder.text('With ' + e['retry_count'] + ' reminder(s) - ')
                    e['retry_remainder'].forEach((ele,ind)=>{
                        if(ele['retry_option'] == 1){
                            reminder.append(ele['retry_days'] + ' Days Before Day of Appointment At ' + ele['retry_hrs'] + ' : ' + ele['retry_mins'] + ', ');
                        }else{
                            reminder.append(ele['retry_before_hrs'] + ' : ' + ele['retry_before_mins'] + ' Before Appointment Time' + ', ');
                        }
                        
                    })
                }
                bodyLine2.append(appointment,launch,reminder,separator);
            })
            let expire = $('<div></div>');
            if(value['model']['assessment_expiration_option'] == 1){
                expire.text('Link expires at ' + value['model']['assessment_expire_appointment_days_hrs'] + ' : ' + value['model']['assessment_expire_appointment_days_mins'] + ' on Day of Appointment' );
            }else{
                expire.text('Link expires at ' + value['model']['assessment_expire_hrs'] + ' : ' + value['model']['assessment_expire_mins'] + value['model']['assessment_expire_operator'] + ' Appointment Time' );
            }
            bodyLine2.append(expire);
        }
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        mainContainer.append(separator);
        if(assignedTo) {
            mainContainer.append(bodyLine);
        }
        mainContainer.append(bodyLine2);
        return mainContainer;
    }

    prepareIncludeReschedule(cell:mxgraph.mxCell){
        const value = cell.getValue();
        let temp = [];
        // value['model'].forEach( el => {
        //     temp.push(el.value);
        // })
        const {condition} = value;
        let assignedTo = temp;
        let tagsDisplay = '--';
        let notification = $('<div></div>').text( '(' + 'Notification :' + value['model']['notificationDevice'] + ')');
        let mainContainer = $('<div></div>').addClass('office-task-block').css({width: this.officeTaskBlockWidth, textAlign:'center', padding:'15px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingBottom: 10,fontWeight:'bold'}).text('What do you want to happen?');
        let taskDiv = $('<div></div>').addClass('task-title');
        let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('d-block').css({paddingBottom: '5px',whiteSpace: 'break-spaces',wordBreak:'break-all'});
        let assignTitle = $('<span></span>').css({fontWeight:'bold'}).text('Reschedule Assessment');
        let assignContent = $('<span></span>').css({color:'blue', paddingLeft:5}).text(assignedTo);
        let bodyLine2 = $('<div></div>').addClass('d-block').css({whiteSpace: 'break-spaces', maxHeight: 150, overflow: 'scroll'});
        bodyLine.append(assignTitle);
        if(value['model']['respondent']) {
            let respondent = $('<div></div>');
            if(value['model']['respondent'].length > 0){
                respondent.text('(' + 'Respondent :' + value['model']['respondent'] + ')');
            }else{
                respondent.text('(' + 'Respondent :' + 'None' + ')');
            }
            bodyLine2.append(respondent)
        }
        if(value['model']['notification_settings'] == true){
            bodyLine2.append(notification);
        }
        if(value['model']['assessment_settings'] == true && value['model']['add_assessment_settings'] != undefined){
            value['model']['add_assessment_settings'].forEach((e,i)=>{
                let reminder = $('<div></div>');
                let appointment = $('<div></div>').text('IF SmarTest Execution Time ' + e['execution_operator'] + ' ' + e['execution_days'] + ' Days Before day of Appointment,');
                let launch = $('<div></div>');
                if(e['launch_option'] == 1){
                    launch.text('Then Do Nothing');
                }else if(e['launch_option'] == 2){
                    launch.text('Then Run now');
                }else if(e['launch_option'] == 3){
                    launch.text('Then Launch ' + e['launch_days'] + ' Days Before Day of Appointment At ' + e['launch_hrs'] + ' : ' + e['launch_mins']);
                }else{
                    launch.text('Then Launch ' + e['launch_before_hrs'] + ' : ' + e['launch_before_mins'] + ' Before Appointment Time');
                }
                if(e['retry_count'] >= 1){
                    reminder.text('With ' + e['retry_count'] + ' reminder(s) - ')
                    e['retry_remainder'].forEach((ele,ind)=>{
                        if(ele['retry_option'] == 1){
                            reminder.append(ele['retry_days'] + ' Days Before Day of Appointment At ' + ele['retry_hrs'] + ' : ' + ele['retry_mins'] + ', ');
                        }else{
                            reminder.append(ele['retry_before_hrs'] + ' : ' + ele['retry_before_mins'] + ' Before Appointment Time' + ', ');
                        }
                        
                    })
                }
                bodyLine2.append(appointment,launch,reminder,separator);
            })
            let expire = $('<div></div>');
            if(value['model']['assessment_expiration_option'] == 1){
                expire.text('Link expires at ' + value['model']['assessment_expire_appointment_days_hrs'] + ' : ' + value['model']['assessment_expire_appointment_days_mins'] + ' on Day of Appointment' );
            }else{
                expire.text('Link expires at ' + value['model']['assessment_expire_hrs'] + ' : ' + value['model']['assessment_expire_mins'] + value['model']['assessment_expire_operator'] + ' Appointment Time' );
            }
            bodyLine2.append(expire);
        }
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        mainContainer.append(separator);
        if(assignedTo) {
            mainContainer.append(bodyLine);
        }
        mainContainer.append(bodyLine2);
        return mainContainer;
    }


    prepareIncludeReassign(cell:mxgraph.mxCell){
        const value = cell.getValue();
        let notification = $('<div></div>').text( '(' + 'Notification :' + value['assessment_data']['notificationDevice'] + ')');
        let mainContainer = $('<div></div>').addClass('office-task-block').css({width: this.officeTaskBlockWidth, textAlign:'center', padding:'15px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingTop: 10,fontWeight:'bold'}).text('Reassign Assessment');
        let taskDiv = $('<div></div>').addClass('task-title');
        // let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('d-block').css({paddingBottom:'10px', whiteSpace: 'break-spaces'});
        let bodyLine2 = $('<div></div>').addClass('d-block').css({whiteSpace: 'break-spaces', maxHeight: 150, overflow: 'scroll'});
        if(value['assessment_data']['notification_settings'] == true){
            bodyLine2.append(notification);
        }
        if(value['assessment_data']['respondent']) {
            let respondent = $('<div></div>');
            if(value['assessment_data']['respondent'].length > 0){
                respondent.text('(' + 'Respondent :' + value['assessment_data']['respondent'] + ')');
            }else{
                respondent.text('(' + 'Respondent :' + 'None' + ')');
            }
            bodyLine2.append(respondent)
        }    
        if(value['assessment_data']['assessment_settings'] == true && value['assessment_data']['add_assessment_settings'] != undefined){
            value['assessment_data']['add_assessment_settings'].forEach((e,i)=>{
                let reminder = $('<div></div>');
                let appointment = $('<div></div>').text('IF SmarTest Execution Time ' + e['execution_operator'] + ' ' + e['execution_days'] + ' Days Before day of Appointment,');
                let launch = $('<div></div>');
                if(e['launch_option'] == 1){
                    launch.text('Then Do Nothing');
                }else if(e['launch_option'] == 2){
                    launch.text('Then Run now');
                }else if(e['launch_option'] == 3){
                    launch.text('Then Launch ' + e['launch_days'] + ' Days Before Day of Appointment At ' + e['launch_hrs'] + ' : ' + e['launch_mins']);
                }else{
                    launch.text('Then Launch ' + e['launch_before_hrs'] + ' : ' + e['launch_before_mins'] + ' Before Appointment Time');
                }
                if(e['retry_count'] >= 1){
                    reminder.text('With ' + e['retry_count'] + ' reminder(s) - ')
                    e['retry_remainder'].forEach((ele,ind)=>{
                        if(ele['retry_option'] == 1){
                            reminder.append(ele['retry_days'] + ' Days Before Day of Appointment At ' + ele['retry_hrs'] + ' : ' + ele['retry_mins'] + ', ');
                        }else{
                            reminder.append(ele['retry_before_hrs'] + ' : ' + ele['retry_before_mins'] + ' Before Appointment Time' + ', ');
                        }
                        
                    })
                }
                bodyLine2.append(appointment,launch,reminder);
            })
            let expire = $('<div></div>');
            if(value['assessment_data']['assessment_expiration_option'] == 1){
                expire.text('Link expires at ' + value['assessment_data']['assessment_expire_appointment_days_hrs'] + ' : ' + value['assessment_data']['assessment_expire_appointment_days_mins'] + ' on Day of Appointment' );
            }else{
                expire.text('Link expires at ' + value['assessment_data']['assessment_expire_hrs'] + ' : ' + value['assessment_data']['assessment_expire_mins'] + value['assessment_data']['assessment_expire_operator'] + ' Appointment Time' );
            }
            bodyLine2.append(expire);
        }    
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        // mainContainer.append(separator);
        mainContainer.append(bodyLine);
        if(value['assessment_data']) {
            mainContainer.append(bodyLine2);
        }
        return mainContainer;
    }


    prepareLaunchAssessment(cell:mxgraph.mxCell){
        const value = cell.getValue();
        let temp = [];
        value['model'].forEach( el => {
            temp.push(el.value);
        })
        const {condition} = value;
        let assignedTo = temp;
        let tagsDisplay = '--';
        let mainContainer = $('<div></div>').addClass('office-task-block').css({width: this.blocksSize.runBlock.width, textAlign:'center', padding:'15px', fontSize:'12px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingBottom: 10,fontWeight:'bold'}).text('What do you want to happen?');
        let taskDiv = $('<div></div>').addClass('task-title');
        let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('d-block').css({paddingBottom: '5px',whiteSpace: 'break-spaces'});
        let assignTitle = $('<span></span>').css({fontWeight:'bold'}).text('Run Assessment(s)');
        let assignContent = $('<span></span>').css({
            color:'blue', 
            paddingLeft:5,
            width: '100%', 
            display: 'inline-block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '250px'
        }).text(assignedTo);
        let bodyLine2 = $('<div></div>').addClass('d-block').css({whiteSpace: 'break-spaces'});
        bodyLine.append(assignTitle);
        bodyLine.append(assignContent);
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        mainContainer.append(separator);
        if(assignedTo) {
            mainContainer.append(bodyLine);
        }
        mainContainer.append(bodyLine2);
        return mainContainer;
    }

    prepareInclude(cell:mxgraph.mxCell){
        const value = cell.getValue();
        let temp = [];
        value['model'].forEach( el => {
            temp.push(el.value);
        })
        const {condition} = value;
        let assignedTo = temp;
        let tagsDisplay = '--';
        let mainContainer = $('<div></div>').addClass('office-task-block').css({width: this.blocksSize.includeBlock.width, textAlign:'center', padding:'15px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingBottom: 10,fontWeight:'bold'}).text('Include');
        let taskDiv = $('<div></div>').addClass('task-title');
        let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('d-block').css({paddingBottom: '5px',whiteSpace: 'break-spaces'});
        // let assignTitle = $('<span></span>').text('Run Assessment(s)');
        let assignContent = $('<span></span>').css({color:'blue', paddingLeft:5}).text(assignedTo);
        let bodyLine2 = $('<div></div>').addClass('d-block').css({whiteSpace: 'break-spaces'});
        // bodyLine.append(assignTitle);
        bodyLine.append(assignContent);
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        mainContainer.append(separator);
        if(assignedTo) {
            mainContainer.append(bodyLine);
        }
        mainContainer.append(bodyLine2);
        return mainContainer;
    }

    setTriggerConditions(dic) {
        let res = '';
        let condition = '';
        if (dic['condition'].toUpperCase() === 'AND') condition = 'and';
        else if (dic['condition'].toUpperCase() === 'OR') condition = 'or';
        
        dic['rules'].forEach((val, ind) => {
          if (val['rules']) res += `(${this.setTriggerConditions(val)})`;
          if (val['text'] && val['operator'] && val['value'] && val['value2']) {
            res += '(' + (val['text']+' '+ val['operator'] +' '+ val['value'] + '-' + val['value2'] )+ ')';
          }else if(val['unit']){
            res += '(' + (val['text']+' '+ val['operator'] +' '+ val['value'] + '-' + val['unit'])+ ')';
          }else if(val['text'] && val['operator'] && val['value']){
            res += '(' + (val['text']+' '+ val['operator'] +' '+ val['value'])+ ')';
          }
          res += (dic['rules'].length !== ind + 1 ? ` ${condition} ` : '');
        });
        return res;
    }

    prepareInitBlockContainer(ModalData:any, callback:any = null) {
        const selectedMainObjectFields = this.selectedMainObjectFields;
        // const operatorsDisplayText = this.triggerService.getOperatorsDisplayText();
        const {rules} = ModalData;
        const padding = 15;
        const containerBottom = 5;
        const maxWidth = 250;
        const fixedWidth = 250;
        const maxHeight = 50;
        let data:any = {};
        let titleDiv = $('<div></div>').css({fontSize:'14px',fontWeight:'bold'}).text('SmartTest Trigger: ' + this.mainObj[ModalData['mainObject']].value);
        let separator = $('<div/>').css({marginBottom: 5, height:10, borderBottom:'1px solid #DADADA', display: 'block'});
        this.element.nativeElement.lastChild['style'].minHeight = parseInt(this.element.nativeElement.lastChild['style'].minHeight) + 100;
        let mainContainer = $('<div></div>').css({
            whiteSpace: 'normal',
            paddingRight: padding,
            paddingLeft: padding,
            textAlign: 'center',
            fontSize: '14px',
            boxSizing: 'border-box',
        }).attr('id', 'cs-init-block-container')
            .append(titleDiv)
            .append(separator);
        let cnt=0;
        let textCollection:any = [];
        let result = this.setTriggerConditions(ModalData)
        _.forEach(rules, (rule:any) => {
            cnt++;
            // let $fieldContainer = $('<div></div>').css('padding-bottom', containerBottom, 'font-weight', 'bold');
            // let $andDiv = $('<div></div>').css('padding-bottom', containerBottom).text(ModalData['condition']);
            // let $fieldDisplay = $('<span></span>').css('padding-right', '5px');
            // // let $fieldSet = $('<span></span>').css('font-size', 'smaller');
            // let $operatorDisplay = $('<span></span>').css('padding-right', '5px').css('font-weight', 'normal');
            // let $valueDisplay = $('<span></span>').css('color','blue');
            // const fieldData = rule['text'];
            // if(_.has(fieldData)) {
            //     let tmp = [];
            //     if(_.isArray(rule['value'])) {
            //         _.forEach(rule['value'], (id) => {
            //             const activeOption = _.find(fieldData, (item) => {
            //                 return item.id == id;
            //             });
            //             if(activeOption) {
            //                 tmp.push(activeOption);
            //             }
            //         });
            //         valueDisplayText=tmp.join(', ');
            //     }
            //     else {
            //         const activeOption = _.find(fieldData, (item) => {
            //             return item.id == rule['value'];
            //         });
            //         if(activeOption) {
            //             valueDisplayText = activeOption;
            //         }
            //     }
            // }
            const tmpDisplayText = rule['text'] + ' ' + rule['operator'] + ' ' + rule['value'];
            textCollection.push(tmpDisplayText.length);
            // $fieldSet.text('[' + rule['type'] + ']' + '.');
            // $fieldDisplay.text(rule['text']);
            // $operatorDisplay.text([rule['operator']]);
            // $valueDisplay.text(rule['value']);
            // $fieldContainer.append($fieldDisplay).append($operatorDisplay).append($valueDisplay);
            // mainContainer.append($fieldContainer);
            // if(cnt !== rules.length) {
            //     mainContainer.append($andDiv);
            // }
        });
        let $fieldContainer = $('<div></div>').css({paddingBottom: containerBottom, color: 'blue', overflow: 'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}).text(result);
        mainContainer.append($fieldContainer);
        const maxDisplayText = _.max(textCollection);
        this.textLength = textCollection.reduce((a, b) => a + b, 0)
        textCollection=[];
        mainContainer.css('width', (maxDisplayText * 9) > maxWidth ? maxWidth : fixedWidth);

        if(typeof callback === 'function') {
            mainContainer.appendTo('body');
            data = {
                width: mainContainer.outerWidth(),
                height: maxHeight + this.element.nativeElement.offsetTop,
                container: mainContainer
            };
            mainContainer.css('height', mainContainer.outerHeight());
            mainContainer.remove();
            callback(ModalData, data);
        }
        else {
            data['container'] = mainContainer;
        }
        return data;
    }

    renderInitBlockContainer(cell, result:any) {
        this.prepareInitBlockContainer(result['formData'], (formData, displayInfo) => {
            this.mxGraph.labelChanged(cell, formData, event);
            let current = cell.getGeometry();
            current.width = displayInfo.width;
            current.height = displayInfo.height;
            cell.setGeometry(current);
            this.mxGraph.updateCellSize(cell, true);
        })
    }

    getParentIds(cellVal,ids,rootCell){
        Object.values(cellVal['cells']).forEach((e,i) => { 
          if(e['id'] == rootCell){
            ids.push(e['id']);
            if(e['parentId'] != 1){
              `( ${this.getParentIds(cellVal,ids,e['parentId'])} )`;
            }else{
              ids.push(e['parentId'])
              return ids;
            }
          }
        })
        return ids;
    }

    initialize(container: ElementRef, options:object={}) {
        this.element = container;
        this.options = options;
        this.unsaveConfirm = false;
        if(_.has(options, 'isMarketPlaceView')) {
            this.isMarketPlaceView = options['isMarketPlaceView'];
        }
        this.isScheduler = options['isScheduler'];
        if(this.options.hasOwnProperty('xmlSource')) {
            this.xmlSource = this.options.xmlSource;
        }
        if(this.options.hasOwnProperty('outlineContainer')) {
            this.outlineContainer = this.options.outlineContainer;
        }
        if(this.options.hasOwnProperty('mainObjectsFields')) {
            this.fieldsData = this.options.mainObjectsFields;
        }
        if(this.options.hasOwnProperty('workflowAction')) {
            this.workflowAction = this.options.workflowAction;
        }

        mxEvent.disableContextMenu(this.element.nativeElement);

        this.mxGraph = new mxGraph(this.element.nativeElement);
        this.mxGraph.setCellsSelectable(false);
        this.mxGraph.setCellsMovable(!this.options.viewMode);
        this.mxGraph.setEdgeLabelsMovable(!this.options.viewMode);
        this.mxGraph.setVertexLabelsMovable(false);
        this.mxGraph.setEnabled(false);
        this.mxGraph.setCellsLocked(this.options.viewMode);
        this.mxGraph.setPanning(true);
        this.mxGraph.centerZoom = false;
        this.mxGraph.panningHandler.useLeftButtonForPanning = true;
        // this.mxGraph.setTooltips(!this.options.viewMode);
        this.mxGraph.setConnectable(true);
        this.mxGraph.connectionHandler.setCreateTarget(!this.options.viewMode);

        if(this.outlineContainer) {
            this.outlineContainer.nativeElement.innerHTML='';
            setTimeout(()=>{
                new mxDivResizer(this.outlineContainer.nativeElement, this.element.nativeElement);
                const tr:mxgraph.mxOutline = new mxOutline(this.mxGraph, this.outlineContainer.nativeElement);
                tr['sizer'].stroke = '#746ca6';
                tr['sizer'].fill = '#746ca6';
                tr['selectionBorder'].stroke = '#746ca6';
                tr.createSizer();
            },1);
        }

        new mxKeyHandler(this.mxGraph);
        this.overrides();
        this.setStyle();
        this.setEdgeStyle();
        this.treeLayout();

        var layout:mxgraph.mxCompactTreeLayout = new mxCompactTreeLayout(this.mxGraph, false);
        //layout.useBoundingBox = false;
        layout.edgeRouting = false;
        layout.levelDistance = 60;
        layout.nodeDistance = 16;

        // Allows the layout to move cells even though cells
        // aren't movable in the graph
        layout.isVertexMovable = function(cell)
        {
            return true;
        };

        var layoutMgr = new mxLayoutManager(this.mxGraph);

        layoutMgr.getLayout = function(cell)
        {
            if (cell.getChildCount() > 0)
            {
                return layout;
            }
        };
        //new mxRubberband(this.mxGraph);

        this.mxGraph.addListener(mxEvent.UPDATE_CELL_SIZE, (sender, evt) => {
            const graph = this.mxGraph;
            //const layout:mxgraph.mxHierarchicalLayout = new mxHierarchicalLayout(this.mxGraph);
            this.mxLayout.execute(graph.getDefaultParent());
            graph.view.refresh();
        });

        this.mxGraph.addListener(mxEvent.CELLS_REMOVED, (graph:mxgraph.mxGraph) => {
            //this.mxLayout.execute(graph.getDefaultParent());
            this.clearGraphLoading(1000)
            graph.view.refresh();
        });

        this.mxGraph.addListener(mxEvent.CELLS_ADDED, (graph:mxgraph.mxGraph) => {
            this.clearGraphLoading(1000)
            //this.mxLayout.execute(graph.getDefaultParent());
            graph.view.refresh();
        });

        if(this.workflowAction != "view-workflow") {
            //this.cellTracker = new mxCellTracker(this.mxGraph, '#6E66A2', null);

            this.mxGraph.addListener(mxEvent.CLICK, mxUtils.bind(this,(sender, evt) => {
                let cell: mxgraph.mxCell = evt.getProperty('cell');
                if(this.gotoActivate === true){
                    if(!this.selected_parent_ids.includes(cell['id']) && cell['block_name'] != 'condition_edge' && cell['block_name'] != 'else_condition_edge'){
                        let dialogConfig = new MatDialogConfig();
                        dialogConfig.width = '40%';
                        dialogConfig.data = { 'yes': null, 'no': null };
                        dialogConfig.position = { top: '70px' };
                        dialogConfig.disableClose = true,
                            dialogConfig.data = {
                                title: 'Goto Block',
                                sub_title: 'Are you sure to link this Block?',
                                no_button: 'No, keep it',
                                yes_button: 'Yes, Link'
                            }
                        let dialogRef = this.sidePanelService.open(DeleteDialogComponent, dialogConfig);
                        dialogRef.afterClosed().subscribe(
                            value => {
                                if (value == 'yes') {
                                    this.connectGoto(cell);
                                }
                            }
                        );
                    }else{
                        this.snackBar.triggerSnackBar({
                            message: 'Selected block should not connect with Goto',
                            type: 'failure'
                        })
                    }
                }else if(this.includeActivate === true && cell['block_name'] == 'includeBlock'){
                    let dialogConfig = new MatDialogConfig();
                    dialogConfig.width = '40%';
                    dialogConfig.data = { 'yes': null, 'no': null };
                    dialogConfig.position = { top: '70px' };
                    dialogConfig.disableClose = true,
                        dialogConfig.data = {
                            title: 'Include Block',
                            sub_title: 'Are you sure to link this Block?',
                            no_button: 'No, keep it',
                            yes_button: 'Yes, Link'
                        }
                    let dialogRef = this.sidePanelService.open(DeleteDialogComponent, dialogConfig);
                    dialogRef.afterClosed().subscribe(
                        value => {
                            if (value == 'yes') {
                                this.connectInclude(cell);
                            }
                        }
                    );
                }
                else if(this.gotoActivate == false){
                    if (cell && cell.isVertex() && cell.getValue() !== '' && cell['block_name'] !== 'elseConditionalBlock') {
                        if (cell.getId() === 'cs_initializer') {
                            const initialState = {
                                title: 'Update a trigger flow',
                                mxGraph: this.mxGraph,
                                selectedCell: cell,
                                isEdit: true,
                                triggerObjects: this.mainObj,
                                isScheduler: this.isScheduler,
                                isMarketPlaceView: this.isMarketPlaceView,
                            };
                            const dialogConfig = new MatDialogConfig();
                            let data = initialState;
                            dialogConfig.maxWidth = '1000px';
                            dialogConfig.width = '1000px';
                            dialogConfig.height = '100%';
                            dialogConfig.data = data;
                            dialogConfig.disableClose = true;
                            dialogConfig.position = {
                                right: "0"
                            }
                            const bsModal = this.sidePanelService.open(TriggerRulePanelComponent, dialogConfig);
                            bsModal.afterClosed().subscribe(result => {
                                if(result['resetInclude']) {
                                    this.gotoActivate = false
                                    this.includeActivate = false;
                                    this.resetHightlightedCells();
                                }
                                this.graphLoading = true
                                if(result!='close' && result['remove'] != true){
                                    this.selectedMainObjectFields = result['fields'];
                                    this.assessmentList = result['assessment_lists'];
                                    this.grpNameList = result['grp_name_list'];
                                    this.selectedTriggerdata = result['triggerData'];
                                }else if(result['remove'] == true){
                                    this.selectedMainObjectFields = result['fields'];
                                    this.assessmentList = result['assessment_lists'];
                                    this.grpNameList = result['grp_name_list'];
                                    this.selectedTriggerdata = result['triggerData'];
                                    let _cells = [];
                                    let _edges = [];
                    
                                    this.mxGraph.traverse(cell, true , (vertex, edges) => {
                                        if(vertex.id != 'cs_initializer'){
                                            _cells.push(vertex);
                                            _edges.push(edges);
                                            return true;
                                        }
                                    });
                                    this.mxGraph.removeCells(_cells, true);
                                    this.mxGraph.refresh();
                                    // this.doRegenerateDag=true;
                                }
                                try {
                                    this.renderInitBlockContainer(cell, result);
                                    let xml = this.getXml()
                                    if (xml) {
                                        this.destroyMxGraph()
                                        this.initialize(this.element, {
                                            xmlSource: xml,
                                            isScheduler: 0,
                                            outlineContainer: this.outlineContainer,
                                            mainObjectsFields: this.fieldsData,
                                            workflowAction: this.workflowAction,
                                            viewMode: false
                                        });
                                        this.render();
                                    }
                                }
                                catch(e) {
                                    this.clearGraphLoading(1000)
                                }
                                finally {
                                    this.mxGraph.getModel().endUpdate();
                                    this.mxGraph.refresh();
                                    this.clearGraphLoading(1000)
                                }
                                this.clearGraphLoading(1000)
                            });
                        }
                        else {
                            const initialState = {
                                cell: cell,
                                mxGraph: this.mxGraph,
                                sender: sender,
                                event: event,
                                selectedTriggerFields: this.selectedMainObjectFields,
                                assessment_list: this.assessmentList,
                                default_fields: this.default_fields,
                                // clinician_fields: this.clinician_fields,
                                grp_name_list: this.grpNameList,
                                options: this.options,
                                title: 'sc',
                                isEdit: true,
                                isScheduler: this.isScheduler,
                                isMarketPlaceView: this.isMarketPlaceView,
                                triggerData:this.selectedTriggerdata
                            }
                            const dialogConfig = new MatDialogConfig();
                            let data = initialState;
                            dialogConfig.maxWidth = '1000px';
                            dialogConfig.width = '1000px';
                            dialogConfig.height = '100%';
                            dialogConfig.data = data;
                            dialogConfig.disableClose = true;
                            dialogConfig.position = {
                                right: "0"
                            }
                            const bsModalBlock = this.sidePanelService.open(AddBlocksPanelComponent, dialogConfig);
                            bsModalBlock.afterClosed().subscribe(result => {
                                if(result == 'remove') {
                                    this.previousSourceXml = this.getXml()
                                } 
                                if(result == 'particular') {
                                    this.previousSourceXml = this.getXml()
                                }
                                try {
                                    this.unsaveConfirm=true;
                                    let model = this.getGraph().getModel();
                                    // this.graphLoading = true
                                    this.mxGraph.getModel().beginUpdate();
                                    let _cells = [];
                                    let _edges = [];
                                    let selectedChildArr = this.mxGraph.model.getCell(cell['parentId']).edges;
                                    _.forEach(selectedChildArr, (childArr: mxgraph.mxCell) => {
                                        if(childArr['source']['id'] == cell['parentId'] && childArr['block_name'] != undefined) {
                                            this.filterChildArr.push(childArr)
                                        }
                                    });
                                    if(result === 'remove') {
                                        if(this.filterChildArr.length <= 2) {
                                            _.forEach(this.filterChildArr, (elseCell: mxgraph.mxCell) => {
                                                if(elseCell['block_name'] == 'else_condition_edge'){
                                                    this.mxGraph.traverse(this.mxGraph.model.getCell(elseCell.getId()).target, true , (vertex, edges) => {
                                                        _cells.push(vertex);
                                                        _edges.push(edges);
                                                        return true;
                                                    });
                                                }
                                            })
                                        }
                                        let parent = this.mxGraph.model.getCell(cell['parentId']);
                                        if(parent['block_name'] == 'includeBlock'){
                                            parent['targetIds'].forEach((e,i)=>{
                                                if(e == cell['id']){
                                                    parent['targetIds'].splice(i,1);
                                                }
                                            })
                                        }
                                        if(cell['targetIds']){
                                            this.targetIds = [...cell['targetIds']];
                                            Object.values(model['cells']).forEach(((e: mxgraph.mxCell) => {
                                                if(e['targetIds']){
                                                    e['targetIds'] = e['targetIds'].filter(value => !this.targetIds.includes(value))
                                                    
                                                }
                                            })) 
                                        }
                                        if(cell['block_name'] == 'scheduleBlock' || cell['block_name'] == 'includeReschedule' || 
                                        cell['block_name'] == 'includeReassign' || cell['block_name'] == 'launchBlock'){
                                            Object.values(model['cells']).forEach(((e: mxgraph.mxCell) => {
                                                if(e['targetId'] == cell['id']){
                                                    e['targetId'] = ""; 
                                                }
                                                if(e['targetIds'] && e['targetIds'].includes(cell['id'])){
                                                    e['targetIds'].forEach((el,i) => {
                                                        if(el == cell['id']){
                                                            e['targetIds'].splice(i,1);
                                                        }
                                                    })
                                                }
                                            }))
                                        }
                                        
                                        if(cell['block_name'] == 'gotoBlock'){
                                            _cells.push(cell);
                                            this.mxGraph.traverse(cell, true , (vertex, edges) => {
                                                _edges.push(edges);
                                                return true;
                                            });
                                        }else{
                                            this.mxGraph.traverse(cell, true , (vertex, edges) => {
                                                _cells.push(vertex);
                                                _edges.push(edges);
                                                return true;
                                            });
                                        }
                                        this.mxGraph.removeCells(_cells, true);
                                        this.clearGraphLoading(3000)
                                        // if (cell['block_name'] == 'launchBlock' || cell['block_name'] == 'scheduleBlock') {
                                        //     let xml = this.getXml()
                                        //     if (xml) {
                                        //         this.destroyMxGraph()
                                        //         this.initialize(this.element, {
                                        //             xmlSource: xml,
                                        //             isScheduler: 0,
                                        //             outlineContainer: this.outlineContainer,
                                        //             mainObjectsFields: this.fieldsData,
                                        //             workflowAction: this.workflowAction,
                                        //             viewMode: false
                                        //         });
                                        //         this.render();
                                        //     }
                                        // }
                                        // this.doRegenerateDag=true;
                                    }else if(result == 'particular'){
                                        _cells.push(cell);
                                        this.mxGraph.removeCells(_cells, true);
                                        let source = this.mxGraph.getModel().getCell(cell['parentId']);
                                        if(source['block_name'] == 'includeBlock'){
                                            source['targetIds'].forEach((e,i)=>{
                                                if(e == cell['id']){
                                                    source['targetIds'].splice(i,1);
                                                }
                                            })
                                            source['targetIds'].push(cell['targetId']);
                                        }
                                        Object.values(model['cells']).forEach(((e: mxgraph.mxCell) => {
                                            if(e['parentId'] == cell['id']){
                                                e['parentId'] = cell['parentId'];
                                                if(e['block_name'] != 'elseConditionalBlock'){
                                                    this.mxGraph.insertEdge(source, e['id'], '', source, e);
                                                }
                                            }
                                        }))
                                        // this.mxGraph.getModel().endUpdate();
                                        this.mxGraph.refresh();
                                        
                                    }else if(result != 'close') {
                                        let vertexUpdatedValue: any = {};
                                        vertexUpdatedValue['name'] = result['name'];
                                        vertexUpdatedValue['model'] = result['model'];
                                        let ed: mxgraph.mxCell = cell.getEdgeAt(0);
                                        if(_.has(result, 'assessment_data')) {
                                            vertexUpdatedValue['assessment_data'] = result['assessment_data'];
                                        }
                                        if(_.has(result, 'default')) {
                                            vertexUpdatedValue['default'] = result['default'];
                                        }
                                        if (_.has(result, 'condition') && result['condition']['col_name'] !== '' && result['condition']) {
                                            vertexUpdatedValue['condition'] = result['condition'];
                                            if(ed['block_name'] == undefined){
                                                ed['block_name'] = 'condition_edge';
                                            }
                                            ed.setValue(vertexUpdatedValue['condition']);
                                        }
                                        if (_.has(result, 'isConditional')) {
                                            vertexUpdatedValue['isConditional'] = result['isConditional'];
                                        }
                                        this.mxGraph.labelChanged(cell, vertexUpdatedValue, event);
                                        //this.mxGraph.labelChanged(cell, formData, event);
                                        if(this.blocksSize[result['name']]) {
                                            const scale = this.blocksSize[result['name']];
                                            let current = cell.getGeometry();
                                            if(current.width !== scale.width || current.height !== scale.height) {
                                                current.width = scale.width;
                                                current.height = scale.height;
                                                cell.setGeometry(current);
                                                this.mxGraph.updateCellSize(cell, true);
                                            }
                                        }
                                        this.refresh();
                                    }
    
                                }
                                catch(e) {
                                    this.clearGraphLoading(1000)
                                }
                                finally {
                                    this.mxGraph.getModel().endUpdate();
                                    this.mxGraph.refresh();
                                    this.clearGraphLoading(1000)
                                    this.filterChildArr = [];
                                }
                            });
                        }
                    }
                }
            }))
        }
    }

    getLabelByText(fieldName:any, value:any, optionKeyName:string = 'id', optionTextName:string = 'text') {
        const fieldObject = this.selectedMainObjectFields[fieldName];
        if(_.has(fieldObject, 'options')) {
            const options = fieldObject['options'];
            const activeObj = _.find(options, (item) => {
                return item[optionKeyName] == value;
            })
            if(activeObj) {
                return activeObj[optionTextName];
            }
            return value;
        }
        return value;
    };

    overrides() {
        this.mxGraph.getLabel = (cell:mxgraph.mxCell):any => {
            this.initialConfigure = cell;
            let self = this;
            //let c = mxGraph.prototype.getLabel.apply(this.mxGraph, arguments);
            let label = this.mxGraph.labelsVisible ? this.mxGraph.convertValueToString(cell) : '';
            const blockName:string = cell['block_name'];
            /*if(!blockName && cell.isVertex()) {
                return label;
            }*/
            if(blockName === 'initializer' && cell.getValue() == '') {
                let divP = document.createElement('div');
                divP.setAttribute('id', 'cs_initializer_01_container');
                let div  = document.createElement('div');
                div.style.textAlign = 'center';
                divP.style.width =  this.blockWidth+'px';
                div.className = 'init-block';
                div.style.paddingBottom = '10px';
                const labelText = this.isScheduler ? 'How do you want to schedule  this workflow?' : 'How do you want to trigger  this workflow?';
                mxUtils.write(div, labelText);

                const btnText = this.isScheduler  ? 'Set up scheduler' : 'Start';
                let btn  = document.createElement('button');
                btn.className = 'btn btn-primary';
                mxUtils.write(btn, btnText);
                btn.addEventListener(mxEvent.CLICK, (e:any) => {
                    self.workflowTrigger(cell, e);
                });
                divP.appendChild(div);
                divP.appendChild(btn);
                return divP;
            }
            else if(blockName === 'initializer' && typeof cell.getValue() == 'object' ) {
                const objValue:any = cell.getValue();
                let labelContainer = document.getElementById('cs_initializer_01_container');
                if(labelContainer) {
                    labelContainer.remove();
                }
                let divP = document.createElement('div');
                divP.setAttribute('id', 'cs_initializer_trigger');
                divP.style.cursor = 'pointer';
                //divP.style.width = this.blockWidth+'px';

                let div  = document.createElement('div');
                div.style.textAlign = 'center';
                //div.style.width = '160px';
                div.className = 'init-block';
                if(this.isScheduler) {
                    let divHead  = document.createElement('div');
                    divHead.style.textAlign = 'center';
                    //divHead.style.width = '160px';
                    divHead.className = 'init-block';
                    divHead.style.fontWeight = 'bold';
                    divHead.style.padding = '0 4px';
                    mxUtils.write(divHead, 'Scheduled workflow');
                    divP.appendChild(divHead);
                }
                const labelText = this.isScheduler ? objValue['cronText'] : objValue.mainObject + ' trigger';
                const a = this.prepareInitBlockContainer(objValue)
                let title = a.container[0].innerText;
                a.container.attr('title', title);
                divP.appendChild(a.container.get(0));
                return divP;
            }
            else if((blockName === 'conditionalBlock' || blockName === 'ifconditionalBlock') && typeof cell.getValue() == 'object') {
                return this.getBlockInnerContainer(blockName, cell.getValue(), cell.getId());
            }
            else if((blockName === 'condition_edge' || blockName === 'else_condition_edge')&& cell.isEdge()) {
                let divP = document.createElement('div');
                const container = this.prepareConditionalEdageTemplate(cell);
                let title = container[0].innerText;
                container.attr('title', title);
                if(cell.source['block_name'] == "multiconditionalBlock") {
                    if(container[0].innerText.length > 30){
                        container.css({width:'20em'});
                    }
                }
                divP.appendChild(container.get(0));
                return divP;
            } else if(blockName === 'createAppointmentBlock' && typeof cell.getValue() == 'object') {
                let divP = document.createElement('div');
                divP.style.cursor = 'pointer';
                const container = this.prepareAssignAssessmentTemplate(cell);
                divP.appendChild(container.get(0));
                return divP;
            } else if(blockName == 'multiconditionalBlock') {
                let divP = document.createElement('div');
                divP.style.cursor = 'pointer';
                const a = this.prepareMultiCondition(cell);
                divP.appendChild(a.get(0));
                return divP;
            } else if(blockName === 'elseConditionalBlock' && typeof cell.getValue() == 'object') {
                let divP = document.createElement('div');
                const container = this.prepareElseBlock(cell);
                divP.appendChild(container);
                return divP;
            } else if(blockName === 'createPatientBlock'){
                let divP = document.createElement('div');
                divP.style.cursor = 'pointer';
                const a = this.preparePatientCreate(cell);
                divP.appendChild(a.get(0));
                return divP;
            } else if(blockName === 'updatePatientBlock'){
                let divP = document.createElement('div');
                divP.style.cursor = 'pointer';
                const a = this.preparePatientUpdate(cell);
                divP.appendChild(a.get(0));
                return divP;
            }else if(blockName === 'launchAssessmentBlock'){
                let divP = document.createElement('div');
                divP.style.cursor = 'pointer';
                const a = this.prepareLaunchAssessment(cell);
                let title = a[0].innerText;
                a.attr('title', title);
                divP.appendChild(a.get(0));
                return divP;
            }else if(blockName === 'cancelAppointmentBlock'){
                let divP = document.createElement('div');
                divP.style.cursor = 'pointer';
                const a = this.prepareCancelAppointment(cell);
                divP.appendChild(a.get(0));
                return divP;
            }else if(blockName === 'rescheduleBlock'){
                let divP = document.createElement('div');
                divP.style.cursor = 'pointer';
                const a = this.prepareRescheduleAppointment(cell);
                divP.appendChild(a.get(0));
                return divP;
            }else if(blockName === 'reassignBlock'){
                let divP = document.createElement('div');
                divP.style.cursor = 'pointer';
                const a = this.prepareReassignAppointment(cell);
                divP.appendChild(a.get(0));
                return divP;
            }else if(blockName === 'gotoBlock'){
                let divP = document.createElement('div');
                divP.style.cursor = 'pointer';
                const a = this.prepareGoto(cell);
                divP.appendChild(a.get(0));
                return divP;
            }else if(blockName === 'createClinicianBlock'){
                let divP = document.createElement('div');
                divP.style.cursor = 'pointer';
                const a = this.prepareClinicianCreate(cell);
                divP.appendChild(a.get(0));
                return divP;
            }else if(blockName === 'updateClinicianBlock'){
                let divP = document.createElement('div');
                divP.style.cursor = 'pointer';
                const a = this.prepareClinicianUpdate(cell);
                divP.appendChild(a.get(0));
                return divP;
            }else if(blockName === 'assignClinicianBlock'){
                let divP = document.createElement('div');
                divP.style.cursor = 'pointer';
                const a = this.prepareClinicianAssign(cell);
                divP.appendChild(a.get(0));
                return divP;
            }else if(blockName === 'includeBlock'){
                let divP = document.createElement('div');
                divP.style.cursor = 'pointer';
                const a = this.prepareInclude(cell);
                divP.appendChild(a.get(0));
                return divP;
            }else if(blockName === 'scheduleBlock'){
                let divP = document.createElement('div');
                divP.style.cursor = 'pointer';
                const a = this.prepareSchedule(cell);
                divP.appendChild(a.get(0));
                return divP;
            }
            else if(blockName === 'launchBlock'){
                let divP = document.createElement('div');
                divP.style.cursor = 'pointer';
                const a = this.prepareLaunch(cell);
                divP.appendChild(a.get(0));
                return divP;
            }else if(blockName === 'includeReschedule'){
                let divP = document.createElement('div');
                divP.style.cursor = 'pointer';
                const a = this.prepareIncludeReschedule(cell);
                divP.appendChild(a.get(0));
                return divP;
            }else if(blockName === 'includeReassign'){
                let divP = document.createElement('div');
                divP.style.cursor = 'pointer';
                const a = this.prepareIncludeReassign(cell);
                divP.appendChild(a.get(0));
                return divP;
            }
            return label;
        };

        // this.mxGraph.getTooltipForCell = (cell:mxgraph.mxCell) => {
        //     if(cell['block_name'] === 'condition_edge' ) {
        //         const container = this.prepareConditionalEdageTemplate(cell);
        //         return container[0].innerText;
        //     }
        // };

        let graphGetPreferredSizeForCell = this.mxGraph.getPreferredSizeForCell;
        this.mxGraph.getPreferredSizeForCell = (cell:mxgraph.mxCell) => {
            let result = graphGetPreferredSizeForCell.apply(this.mxGraph, [cell]);
            //let style = this.mxGraph.getCellStyle(cell);
            let current = cell.getGeometry();
            result['width'] = current.width;
            result['height'] = current.height;
            return result;
        };
    }

    getBlockInnerContainer(name:string, value:any, id:string='') {
        let divP, div, imgDiv, img, span, img2;
        // const operatorsDisplayText = this.triggerService.getOperatorsDisplayText();
        switch (name) {
            case 'condition_edge':
                divP = document.createElement('div');
                divP.style.backgroundColor = '#FFF';
                divP.style.border = '1px dashed #ccc';
                div  = document.createElement('div');
                div.style.padding = '3px 6px';
                div.style.textAlign = 'center';
                // if(this.debugMode)
                //     mxUtils.write(div,  value['text'] + ' ' +  operatorsDisplayText[value['operator']] + ' ' +  this.getLabelByText(value['col_name'], value['value'])  + ' id:' + id);
                // else
                //     mxUtils.write(div,  value['text'] + ' ' +  operatorsDisplayText[value['operator']] + ' ' +  this.getLabelByText(value['col_name'], value['value']) );
                divP.appendChild(div);
                return divP;

            case 'conditionalBlock':
                const column =  this.selectedMainObjectFields[value['model']['col_name']];
                // const column = value['model']['text'];
                divP = document.createElement('div');
                imgDiv = document.createElement('div');
                imgDiv.style.textAlign = 'center';
                img = document.createElement('img');
                img.setAttribute('alt','...');
                img.className='mr-5p';
                img.src = '/assets/icons/extra-icons/statement.svg';
                span = document.createElement('div');
                span.style.fontWeight = 'bold';
                if(this.debugMode)
                    mxUtils.write(span,  'If statement id:'+id);
                else
                    mxUtils.write(span,  'If-else statement');
                imgDiv.appendChild(img);
                imgDiv.appendChild(span);
                div  = document.createElement('div');
                divP.style.cursor = 'pointer';
                div.style.textAlign = 'center';
                div.style.width = '160px';
                div.style.color = 'blue';
                div.style.overflow = 'hidden';
                div.style.textOverflow = 'ellipsis';
                div.style.whiteSpace = 'nowrap';
                div.style.paddingLeft = '23px';
                div.style.paddingRight = '10px';
                div.className = 'init-block';
                div.title = column.text;
                mxUtils.write(div,  column.text);

                divP.appendChild(imgDiv);
                divP.appendChild(div);
                return divP;
            case 'ifconditionalBlock':
                const columnName =  this.selectedMainObjectFields[value['model']['col_name']];
                // const column = value['model']['text'];
                divP = document.createElement('div');
                imgDiv = document.createElement('div');
                imgDiv.style.textAlign = 'center';
                img = document.createElement('img');
                img.setAttribute('alt','...');
                img.className='mr-5p';
                img.src = '/assets/icons/extra-icons/statement.svg';
                span = document.createElement('div');
                span.style.fontWeight = 'bold';
                if(this.debugMode)
                    mxUtils.write(span,  'If statement id:'+id);
                else
                    mxUtils.write(span,  'If statement');
                imgDiv.appendChild(img);
                imgDiv.appendChild(span);
                div  = document.createElement('div');
                divP.style.cursor = 'pointer';
                div.style.textAlign = 'center';
                div.style.width = '160px';
                div.style.color = 'blue';
                div.className = 'init-block';
                mxUtils.write(div,  columnName.text);

                divP.appendChild(imgDiv);
                divP.appendChild(div);
                return divP;
        }
    }

    getGraph() {
        return this.mxGraph;
    }

    getContainerWidth() {
        return this.element.nativeElement.offsetWidth;
    }

    // editOverlay(cell: mxgraph.mxCell) {
    //     let editOverLay: mxgraph.mxCellOverlay = new mxCellOverlay(
    //         new mxImage(editBtnUrl, 18, 18),
    //         'Edit',
    //         mxConstants.ALIGN_RIGHT,
    //         mxConstants.ALIGN_TOP,
    //         new mxPoint(-12,12),
    //         'pointer'
    //     );
    //     editOverLay.cursor = 'hand';
    //     this.mxGraph.addCellOverlay(cell, editOverLay);
    //     editOverLay.addListener(mxEvent.CLICK, (sender, event) => {
    //         if(cell.getId() === 'cs_initializer') {
    //             const bsModal:BsModalRef = this.modalService.show(TriggerFlowPanelComponent, {
    //                 initialState: {
    //                     title: 'Update a trigger flow',
    //                     mxGraph: this.mxGraph,
    //                     selectedCell: cell,
    //                     isEdit: true,
    //                     triggerObjects: this.fieldsData,
    //                     isScheduler: this.isScheduler,
    //                     isMarketPlaceView: this.isMarketPlaceView,
    //                 },
    //                 'size': 'sm',
    //                 'class': 'workflow-sidepanel'
    //             });
    //             bsModal.content.onClose.subscribe(result => {
    //                 this.selectedMainObjectFields = result['fields'];
    //                 try {
    //                     this.mxGraph.labelChanged(cell, result['formData'], event);
    //                 }
    //                 finally {
    //                     this.mxGraph.getModel().endUpdate();
    //                 }
    //             });
    //         }
    //         else {
    //             // const bsModalBlock = this.modalService.show(AddBlockPanelComponent, {
    //             //     initialState: {
    //             //         cell: cell,
    //             //         mxGraph: this.mxGraph,
    //             //         sender:sender,
    //             //         event: event,
    //             //         selectedTriggerFields: this.selectedMainObjectFields,
    //             //         options: this.options,
    //             //         title: 'sc',
    //             //         isEdit: true,
    //             //         isMarketPlaceView: this.isMarketPlaceView,
    //             //     },
    //             //     size: 'sm',
    //             //     'class': 'ng-addblock-sidepanel'
    //             // });
    //             // bsModalBlock.content.onClose.subscribe( result => {
    //             //     try {
    //             //         let vertexUpdatedValue:any = {};
    //             //         vertexUpdatedValue['name'] = result['name'];
    //             //         vertexUpdatedValue['model'] = result['model'];
    //             //         let ed:mxgraph.mxCell = cell.getEdgeAt(0);
    //             //         if(_.has(result, 'condition') && result['condition']['col_name'] !== '') {
    //             //             vertexUpdatedValue['condition'] = result['condition'];
    //             //             ed.setValue(vertexUpdatedValue['condition']);
    //             //         }
    //             //         if(_.has(result, 'isConditional')) {
    //             //             vertexUpdatedValue['isConditional'] = result['isConditional'];
    //             //         }
    //             //         this.mxGraph.labelChanged(cell, vertexUpdatedValue, event);
    //             //         this.refresh();
    //             //     }
    //             //     finally {
    //             //         this.mxGraph.getModel().endUpdate();
    //             //         this.refresh();
    //             //     }
    //             // });
    //         }
    //     });
    // }

    getTempData(data){
        this.clinicData.next(data);
    }

    getClinicVal(){
        return this.clinicData.asObservable();
    }
    // getFormvalue(data, cell:mxgraph.mxCell){
    //     this.conditionalData['model']['col_name'] = data['conditional_field'];
    //     this.conditionalData['condition'] = {
    //         code : data['code'],
    //         operator : data['operator'],
    //     }
    //     this.getCondition(this.conditionalData, cell)
    //     this.conditionData.next(this.conditionalData['model']['col_name']);
    // }

    // getTaskValue(data, cell:mxgraph.mxCell){
    //     this.conditionalData['model'] = data['assessments']
    //     this.conditionalData['condition'] = {
    //         code : data['code'],
    //         operator : data['operator'],
    //     }
    //     this.getCondition(this.conditionalData, cell)
    // }

    // getCondition(result, cell:mxgraph.mxCell){
    //     let $parent = this.mxGraph.getDefaultParent();
    //     this.unsaveConfirm=true;
    //     const blockSizes = this.blocksSize;
    //     let bn = result['name'];
    //     this.mxGraph.clearSelection();
    //     let vertexOptions={}, vertexModel=result['model'], delayBlock:any={}, newVertex ;
    //     if(blockSizes[bn]) {
    //         vertexOptions['width'] = blockSizes[bn]['width'];
    //         vertexOptions['height'] = blockSizes[bn]['height'];
    //     }
    //     newVertex = this.addChild(result, cell, vertexOptions);
    //     this.doRegenerateDag=true;
    //     this.refresh();
    // }
    addOverlay(cell: mxgraph.mxCell) {
        if(!cell.getGeometry()) {
            const geo = this.mxGraph.getCellGeometry(cell);
            return;
        }
        let overLay: mxgraph.mxCellOverlay = new mxCellOverlay(
            new mxImage(addBtnUrl, 24, 24),
            'Add',
            mxConstants.ALIGN_CENTER,
            mxConstants.ALIGN_CENTER,
            // new mxPoint(0,0),
            // 'pointer'
        );
        const geo = this.mxGraph.getCellGeometry(cell);

        overLay.cursor = 'hand';

        overLay.addListener(mxEvent.CLICK, (sender, event) => {
            this.previousSourceXml = this.getXml()
            const initialState = {
                cell: cell,
                mxGraph: this.mxGraph,
                sender:sender,
                event: event,
                selectedTriggerFields: this.selectedMainObjectFields,
                default_fields: this.default_fields,
                // clinician_fields: this.clinician_fields,
                assessment_list:this.assessmentList,
                grp_name_list: this.grpNameList,
                options: this.options,
                title: 'sc',
                isMarketPlaceView: this.isMarketPlaceView,
                triggerData:this.selectedTriggerdata
            };
            const dialogConfig = new MatDialogConfig();
            let data = initialState;
            dialogConfig.maxWidth = '1000px';
            dialogConfig.width = '1000px';
            dialogConfig.height = '100%';
            dialogConfig.data = data;
            dialogConfig.disableClose = true;
            dialogConfig.position = {
                right: "0"
            }
            if(cell['value']['name'] == 'gotoBlock'){
                if(this.gotoActivate != true){
                    if(cell['edges'].length > 1){
                        this.snackBar.triggerSnackBar({
                            message: 'Goto connected with other block ',
                            type: 'failure'
                          })
                    }else{
                        let model = this.getGraph().getModel();
                        let ids = []
                        let rootCell = cell['id'];
                        let parent_ids = this.getParentIds(model,ids,rootCell);
                        this.setHightlightCells(parent_ids,rootCell);
                    }
                }
                if(cell['goto_child'] == undefined){
                    this.snackBar.triggerSnackBar({
                        message: 'Select block to connect with Goto',
                        type: 'failure'
                      })
                }
                
                
            }else if((cell['value']['name'] == 'scheduleBlock' || cell['value']['name'] == 'launchBlock' || cell['value']['name'] == 'includeReschedule' || 
            cell['value']['name'] == 'includeReassign') && this.includeActivate != true){
                let model = this.getGraph().getModel();
                this.setIncludeCells(model,cell['id']);
            }
            else{
                if(cell['block_name'] === 'initializer' && cell['edges'] != null && cell['edges'].length > 0){
                    this.snackBar.triggerSnackBar({
                        message: 'Trigger block already have child block',
                        type: 'failure'
                    })
                }else{
                    const fsModel = this.sidePanelService.open(AddBlocksPanelComponent, dialogConfig);
                    let $parent = this.mxGraph.getDefaultParent();
                    fsModel.afterClosed().subscribe(result => {
                        if(result == 'remove') {
                            this.previousSourceXml = this.getXml()
                        } 
                        if(result == 'particular') {
                            this.previousSourceXml = this.getXml()
                        }
                        // this.graphLoading = true
                        if(result != 'close') {
                            this.unsaveConfirm=true;
                            const blockSizes = this.blocksSize;
                            let bn = result['name'];
                            this.mxGraph.clearSelection();
                            let vertexOptions={}, vertexModel=result['model'], delayBlock:any={}, newVertex ;
                            if(blockSizes[bn]) {
                                vertexOptions['width'] = blockSizes[bn]['width'];
                                vertexOptions['height'] = blockSizes[bn]['height'];
                            }
                            newVertex = this.addChild(result, cell, vertexOptions);
                            // this.doRegenerateDag=true;
                            this.edgeBlockNameList.splice(0, this.edgeBlockNameList.length)
                            _.forEach(cell.edges, (e: mxgraph.mxCell) => {
                                if (_.has(e, 'block_name')) { 
                                    this.edgeBlockNameList.push(e['block_name'])
                                }
                            })
                            if(newVertex['value']['isConditional'] == true && !_.includes(this.edgeBlockNameList, 'else_condition_edge')){
                                this.edgeBlockNameList.splice(0, this.edgeBlockNameList.length);
                                this.addElseBlock(newVertex, result, cell);
                            }
                            this.refresh();
                        }
                        this.clearGraphLoading(1000)
                    });
                }
                
            }  
        });
        this.mxGraph.addCellOverlay(cell, overLay);
    }

    prepareElseBlock(cell) {
        let divP, span, div;
        divP = document.createElement('div');
        span = document.createElement('div');
        // mxUtils.write(span,  'Else Block');
        div  = document.createElement('div');
        divP.style.cursor = 'pointer';
        div.style.textAlign = 'center';
        div.style.width = '160px';
        // div.className = 'init-block';
        div.style.color = 'blue';
        span.style.color = 'black';
        // mxUtils.write(div, cell['value']['model']['value']);
        divP.appendChild(div);
        div.appendChild(span);
        return divP;
    }

    addElseBlock(newVertex, result, cell){
        const parent = this.mxGraph.getDefaultParent();
        let vertex : mxgraph.mxCell, vertexValue:any = {}, conditionIfBlockId = cell['id'];
        vertexValue['name'] = 'elseConditionalBlock';
        vertexValue['model']= result['condition'];
        this.mxGraph.getModel().beginUpdate();
        const x = (this.getContainerWidth() / 2);
        try {
            vertex = this.mxGraph.insertVertex(parent, null, vertexValue, x, x+20, 0, 0); 
            vertex['block_name'] = 'elseConditionalBlock';
            vertex['parentId'] = cell['parentId'];
            vertex['value']['ifBlockId'] = conditionIfBlockId;
            let edge = this.mxGraph.insertEdge(parent, null, '', cell, vertex);
            edge['block_name'] = 'else_condition_edge';
            edge.geometry.x = 1;
            edge.geometry.y = 0;
            edge.geometry.offset = new mxPoint(0, -20);
            this.addOverlay(vertex);

            // code for edge with curved and edge styles
            /* let edge = this.mxGraph.insertEdge(parent, null, '', newVertex, vertex, 'exitX=0;');
            let edge = this.mxGraph.insertEdge(parent, null, '', newVertex, vertex,
                'edgeStyle=orthogonalEdgeStyle;elbow=vertical;' + 'exitX=-4;exitY=0.5;exitPerimeter=1;entryX=0.5;entryY=0;entryPerimeter=0;');
            let edge = this.mxGraph.insertEdge(parent, null, '', newVertex, vertex,
                'edgeStyle=orthogonalEdgeStyle;elbow=vertical;' + 'exitX=4;exitY=0.5;exitPerimeter=1;entryX=0.5;entryY=0;entryPerimeter=1;');
            let edge = this.mxGraph.insertEdge(parent, null, '', newVertex, vertex, 'entryX=1;entryY=0;');
            'exitX=0;exitY=0.5;exitPerimeter=1;entryX=0.5;entryY=1;entryPerimeter=0;');
            this.mxGraph.getModel().endUpdate();
            let current = vertex.getGeometry();
            current.x = current.x - 100;
            current.y = current.y;
            vertex.setGeometry(current);
            this.refresh(); */
        }
        finally{
            this.mxGraph.getModel().endUpdate();
        }
        return vertex;
    }

    executeLayout(change, post) {
        this.mxGraph.getModel().beginUpdate();
        const layout:mxgraph.mxHierarchicalLayout = new mxHierarchicalLayout(this.mxGraph);
        try {
            if (change != null) {
                change();
            }
            layout.execute(this.mxGraph.getDefaultParent());
        }
        catch (e) {
            throw e;
        }
        finally {
            const morph: mxgraph.mxMorphing = new mxMorphing(this.mxGraph);
            morph.addListener(mxEvent.DONE, mxUtils.bind(this, () => {
                if(post != null) {
                    post();
                }
            }));
            morph.startAnimation();
            this.mxGraph.getModel().endUpdate();
        }
    }

    treeLayout(parent: mxgraph.mxCell = null) {
        let layout = this.mxLayout = new mxCompactTreeLayout(this.mxGraph, false);
        layout.edgeRouting = false;
        layout.levelDistance = 40;
        layout.nodeDistance = 40;
        layout.isVertexMovable = function(cell:mxgraph.mxCell)
        {
            return true;
        };
        let layoutMgr = new mxLayoutManager(this.mxGraph);
        let graph = this.mxGraph;
        layoutMgr.getLayout = function(cell){
            //setTimeout(()  => {
            graph.view.refresh();
            //},300);
            if (cell.getChildCount() > 0) {
                return layout;
            }
        };
    }

    getSourceEdge(edges:mxgraph.mxCell[], id:any) {
        let sourcedEdge:any = null;
        edges.forEach( (edge) => {
            if(edge.source.getId() === id) {
                sourcedEdge = edge;
                return true;
            }
        });
        return sourcedEdge;
    }

    addChild(data:any, cell:mxgraph.mxCell, options:any={},isHideOverlay:boolean=false) {
        // let arraytoUpdate = this.newlyAddedCells
        // arraytoUpdate.push(cell)
        // this.newlyAddedCells = arraytoUpdate
        this.graphLoading = true
        const parent = this.mxGraph.getDefaultParent();
        let vertex:mxgraph.mxCell;
        let blockName = data['name'], blockModel = data['model'],isConditional = data['isConditional'], vertexValue:any = {}, style='';
        vertexValue['name'] = blockName;
        vertexValue['model'] = blockModel;
        vertexValue['assessment_data'] = data['assessment_data'];
        vertexValue['default'] = data['default'];
        vertexValue['isConditional'] = data['isConditional'];
        if(isConditional) {
            vertexValue['condition'] = data['condition'];
        }

        this.mxGraph.getModel().beginUpdate();
        const geo = this.mxGraph.getCellGeometry(cell);
        let vertexWidth = this.blockWidth,
            vertexHeight = this.blockHeight;
        if(_.has(options,'width')) {
            vertexWidth = options['width'];
            vertexHeight = options['height'];
        }
        if(blockName==='conditionalBlock' || blockName==='ifconditionalBlock') {
            style='shape=rhombus';
            vertexWidth = this.conditionBlockSize;
            vertexHeight = this.conditionBlockSize;
        }
        if(blockName==='multiconditionalBlock') {
            style='shape=rhombus'
            vertexWidth = this.multipleBlockSize;
            vertexHeight = this.multipleBlockSize;
        }
        try{
            vertex = this.mxGraph.insertVertex(parent, null, vertexValue, vertexWidth, geo.y, vertexWidth, vertexHeight, style);
            vertex['block_name'] = blockName;
            vertex['parentId'] = cell.getId();
            let model = this.getGraph().getModel();
            Object.values(model['cells']).forEach(((e: mxgraph.mxCell) => {
                let blocks = ['ifconditionalBlock','conditionalBlock','multiconditionalBlock']
                if(e['parentId'] == cell['id'] && vertex['id'] != e['id'] && !blocks.includes(cell['block_name'])){
                    e['parentId'] = vertex['id'];
                    if(vertex['block_name'] == 'conditionalBlock' || vertex['block_name'] == 'multiconditionalBlock'){
                        this.addElseBlock(e, data, vertex);
                    }
                }
            }))
            if(blockName === 'includeBlock'){
                vertex['targetIds'] = [];
            }
            if(cell['block_name'] == 'includeBlock' && cell['targetIds'] != undefined){
                cell['targetIds'].push(vertex['id']);
            }
            if(blockName === 'scheduleBlock' || blockName === 'launchBlock' || blockName === 'includeReschedule' || blockName === 'includeReassign'){
                vertex['parentIds'] = [];
                vertex['parentIds'].push("includeBlock_" + cell.getId());
                cell['targetId'] = vertex['id'];
            }

            let edge;
            if(isConditional) {
                edge = this.mxGraph.insertEdge(parent, null, vertexValue['condition'], cell, vertex);
                edge['block_name'] = 'condition_edge';
            }
            else {
                const cellEdges = this.mxGraph.getModel().getEdges(cell);
                const sourceEdge = this.getSourceEdge(cellEdges, cell.getId());
                edge = this.mxGraph.insertEdge(parent, null, '', cell, vertex);

                this.mxGraph.splitEdge(sourceEdge, [vertex], edge);
            }
            edge.geometry.x = 1;
            edge.geometry.y = 0;
            edge.geometry.offset = new mxPoint(0, -20);
            if(!isHideOverlay ) {
                this.addOverlay(vertex);
                //if(blockName !== 'conditionalBlock') this.editOverlay(vertex);
            }
            // if(blockName == 'gotoblock'){
            //     this.addOverlayGoto(vertex);
            // }

        }
        catch(e) {
            this.clearGraphLoading(1000)
        }
        finally {
            this.mxGraph.getModel().endUpdate();
            this.mxGraph.refresh();
            this.clearGraphLoading(1000)
        }
        return vertex;
    }

    workflowTrigger(cell: mxgraph.mxCell, e:any) {
        const initialState = {
            title: this.isScheduler ? 'Setup a scheduler' : 'Setup a trigger flow',
            mxGraph: this.mxGraph,
            selectedCell: cell,
            triggerObjects: this.mainObj,
            isScheduler: this.isScheduler,
            isMarketPlaceView: this.isMarketPlaceView,
        };
        const dialogConfig = new MatDialogConfig();
        let data = initialState;
        dialogConfig.maxWidth = '1000px';
        dialogConfig.width = '1000px';
        dialogConfig.height = '100%';
        dialogConfig.data = data;
        dialogConfig.disableClose = true;
        dialogConfig.position = {
            right: "0"
        }

        const fsModel = this.sidePanelService.open(TriggerRulePanelComponent, dialogConfig);

        fsModel.afterClosed().subscribe(result => {
            this.graphLoading = true
            if(result!='close'){
                this.selectedMainObjectFields = result['fields'];
                this.assessmentList = result['assessment_lists'];
                this.grpNameList = result['grp_name_list'];
                this.selectedTriggerdata = result['triggerData'];
                try {
                    this.addOverlay(cell);
                    //this.editOverlay(cell);
                    this.renderInitBlockContainer(cell, result);
                    //this.mxGraph.labelChanged(cell, result['formData'], e);
                }
                catch(e) {
                    this.clearGraphLoading(1000)
                }
                finally {
                    this.mxGraph.getModel().endUpdate();
                    this.clearGraphLoading(1000)
                }
            }
            this.clearGraphLoading(1000)
        });
        this.mxGraph.getModel().endUpdate();
        this.mxGraph.refresh();
    }

    sendData(data, cell:mxgraph.mxCell){
        this.addOverlay(cell);
        this.renderInitBlockContainer(this.initialConfigure, data);
        this.mxGraph.getModel().endUpdate();
    }

    setStyle() {
        let style = this.mxGraph.getStylesheet().getDefaultVertexStyle();
        mxConstants.RECTANGLE_ROUNDING_FACTOR = 0.03;
        mxConstants.SHADOWCOLOR = 'rgba(0, 0, 0, 0.05)';
        mxConstants.SHADOW_OFFSET_X = 0;
        mxConstants.SHADOW_OFFSET_Y = 4;

        style[mxConstants.STYLE_FONTSIZE] = '12';
        style[mxConstants.STYLE_FONTCOLOR] = '#505b65';
        style[mxConstants.STYLE_FILLCOLOR] = '#fff';
        style[mxConstants.STYLE_STROKECOLOR] = '#dadada';
        style[mxConstants.STYLE_STROKEWIDTH] = 1;
        style[mxConstants.STYLE_CURVED] = 4;
        style[mxConstants.STYLE_SHAPE] = 'label';
        style[mxConstants.STYLE_SHADOW] = '1';
        style[mxConstants.STYLE_ROUNDED] = '1';
    }

    setEdgeStyle() {
        let style = this.mxGraph.getStylesheet().getDefaultEdgeStyle();
        style[mxConstants.STYLE_ROUNDED] = true;
        style[mxConstants.STYLE_STROKECOLOR] = '#505b65';
        style[mxConstants.STYLE_STROKEWIDTH] = 1;
        style[mxConstants.STYLE_EXIT_X] = 0.5; // center
        style[mxConstants.STYLE_EXIT_Y] = 1.0; // bottom
        style[mxConstants.STYLE_EXIT_PERIMETER] = 0; // disabled
        style[mxConstants.STYLE_ENTRY_X] = 0.5; // center
        style[mxConstants.STYLE_ENTRY_Y] = 0; // top
        style[mxConstants.STYLE_ENTRY_PERIMETER] = 0; // disabled

        // Disable the following for straight lines
        style[mxConstants.STYLE_EDGE] = mxEdgeStyle.TopToBottom;
    }

    registerHistoryView(items:any):void {
        const tasks = items.tasks;
        this.mxGraph.addListener(mxEvent.CLICK, (sender, evt) => {
            if(_.isUndefined(evt.getProperty('cell'))) {
                return false;
            }
            let cell: mxgraph.mxCell = evt.getProperty('cell');
            if(!cell.isVertex()) {
                return false;
            }
            let blockId = cell['block_name'] === "initializer" ? cell.getId() : cell['block_name'] + '_' + cell.getId();
            if(_.has(tasks, blockId)) {
                const initialState = {
                    cell: cell,
                    mxGraph: this.mxGraph,
                    taskItem: tasks[blockId],
                    title: 'sc'
                };
            }

        });
    }

    setHightlightCells(items,id) {
        this.goto_id = id;
        this.selected_parent_ids = items;
        this.resetHightlightedCells(() => {
            _.forEach(this.mxGraph.getModel().cells, (cell:mxgraph.mxCell) => {
                let colorCode = HIGHLIGHT_RUNNING;
                let blockId = cell.getId();
                if(items.includes(blockId)) {
                    this.cellHighlighter[blockId] = new mxCellHighlight(this.mxGraph, colorCode, 5);
                    this.cellHighlighter[blockId].highlight(this.mxGraph.view.getState(cell));
                }
            })
        });
        this.gotoActivate = true;
    }

    setIncludeCells(cellVal,id){
        this.schedule_id = id;
        this.resetHightlightedCells(() => {
            _.forEach(this.mxGraph.getModel().cells, (cell:mxgraph.mxCell) => {
                let colorCode = HIGHLIGHT_RUNNING;
                let blockId = cell.getId();
                if(cell['block_name'] != undefined && cell['block_name'] != 'includeBlock') {
                    this.cellHighlighter[blockId] = new mxCellHighlight(this.mxGraph, colorCode, 5);
                    this.cellHighlighter[blockId].highlight(this.mxGraph.view.getState(cell));
                }
            })
        });
        this.includeActivate = true;
    }

    resetHightlightedCells(setHighLight:Function = ()=>{}) {
        if(_.size(this.cellHighlighter) > 0 ) {
            const highlights = Object.values(this.cellHighlighter);
            _.forEach(highlights, ( highlight:mxgraph.mxCellHighlight ) => {
                highlight.destroy();
            });
        }
        this.mxGraph.removeListener(mxEvent.CLICK);
        return setHighLight();
    }

    reset() {
        this.selectedMainObjectFields = null;
    }

    destroyMxGraph(afterDestroy:Function = _.noop) {
        if(this.mxGraph) {
            this.mxGraph.destroy();
        }
        afterDestroy();
    }

    refresh(interval:number = 0) {
        setTimeout( () => {
            this.mxGraph.view.refresh();
        }, interval)
    }

    prepareActiveInput(activeMainObject:any, MainObjectValue:any, cb:Function) {
        this.selectedMainObjectFields = {};

        activeMainObject.fields.forEach((value) => {
            this.selectedMainObjectFields[value.col_name] = {
                input: value.input,
                type: value.type,
                text: value.text,
                unit: value.unit,
                value2: value.value2,
                operators: value.operators
            };
            if(value.hasOwnProperty('options') && value.options.length > 0) {
                this.selectedMainObjectFields[value.col_name]['options'] = value.options;
            }
        });
        this.assessmentList = activeMainObject.tags[0];
        this.grpNameList = activeMainObject.tags[1];
        cb();
    }

    getXml(uriEncode:boolean=false) {
        const codec = new mxCodec();
        let result = codec.encode(this.mxGraph.getModel());
        let xml = mxUtils.getXml(result);
        if(uriEncode) {
            return encodeURIComponent(xml);
        }
        return xml;
    }

    parseXml(xml:string) {
        let doc = mxUtils.parseXml(xml);
        let codec = new mxCodec(doc);
    }
    

    render() {
        if(! this.xmlSource ) {
            this.mxGraph.getModel().beginUpdate();
            try {
                const parent = this.mxGraph.getDefaultParent();
                const blockWidth = this.blockWidth, blockHeight = this.blockHeight;
                const x = (this.getContainerWidth() / 2) - (blockWidth / 2);
                //const x = 30;
                const y = 20;
                const startBlock = this.mxGraph.insertVertex(parent, 'cs_initializer', '', x, y, blockWidth, blockHeight);
                startBlock['block_name']='initializer';
                startBlock['parentId']='0';
                //const endBlock = this.mxGraph.insertVertex(parent, 'cs_end_block', 'End block', x, 220, blockWidth, blockHeight);
                //const connector = this.mxGraph.insertEdge(parent, null, '', startBlock, endBlock);
                //this.addOverlay(startBlock, true);
            }
            finally {
                this.mxGraph.getModel().endUpdate();
            }
        }
        else {
            let doc = mxUtils.parseXml(this.xmlSource);
            let codec = new CustomMxCodec(doc);
            let initializerBlock:mxgraph.mxCell = codec.getObject('cs_initializer');
            let blockValue:any = initializerBlock.getValue();
            let activeMainObject = this.mainObj[blockValue.mainObject];
            this.selectedTriggerdata = activeMainObject;
            this.prepareActiveInput(activeMainObject, blockValue, () => {
                codec.decode(doc.documentElement, this.mxGraph.getModel());
                _.forEach(this.mxGraph.getModel().cells, (cell:mxgraph.mxCell) => {
                    if(cell.isVertex() && !this.options.viewMode && !this.isMarketPlaceView) {
                        if(this.workflowAction != "view-workflow"){
                            this.addOverlay(cell);
                        }
                    }
                });
            });
           
        }

        setTimeout( () => {
            this.mxGraph.zoomActual();
        },300)
    }

    prepareDag() {
        let mxGraphModel = this.getGraph().getModel();
        let rootCell = mxGraphModel.getCell('1');
        const getBlockObject = () => {
            if(!rootCell.children.length) {
                return [];
            }
            let vertex:any = {};
            _.chain(rootCell.children)
                .filter( (cell:mxgraph.mxCell) => {
                    return cell.isVertex();
                })
                .map( (cell:mxgraph.mxCell) => {
                    _.forEach(cell.edges, (e:mxgraph.mxCell) => {
                        if(_.has(e,'block_name') && e['block_name'] === "condition_edge" && e['source']['id'] == cell.getId()) {
                            const value = {
                                name: 'conditionEdge',
                                model: e.getValue(),
                                parentId: ""+cell.getId(),
                                targetId: ""+e['target']['id'],
                                id: ""+e.getId(),
                            };
                            vertex[e.getId()] = value;
                        }
                    });
                    return vertex[cell.getId()] = {
                        ...cell.getValue(),
                        parentId: ""+cell['parentId'],
                        id: cell.getId() === 'cs_initializer' ? 'cs_initializer' : ""+cell.getId()
                    }
                })
                .value();
            let transformVertex = _.clone(vertex);
            _.chain(transformVertex)
                .filter( (item) => _.has(item, 'targetId'))
                .map( (item) => {
                    vertex[item['targetId']]['parentId'] = ""+item.id;
                    return item;
                });
            return vertex;
        };
        const processEndBlock = () => {
            if(! _.size(_dag) ) {
                return [];
            }
            let endBlocks:any[] = [];
            let arrayValues:any[] = _.flatten(Object.values(_dag));
            let arrayKeys:any[] = _.flatten(Object.keys(_dag));
            endBlocks = _.filter(arrayValues, (id) => {
                return !_.contains(arrayKeys, id);
            });
            return endBlocks;
        };

        let _dag:any = {
            'cs_initializer': []
        };
        const blocksList = getBlockObject();
        let firstChildren = _.where(blocksList, {parentId: "cs_initializer"});
        if(firstChildren.length) {
            _.forEach(firstChildren, (item:any) => {
                _dag['cs_initializer'].push(item.id);
            })
        }

        const _generateDag = (blockId:string) => {
            if(_dag[blockId].length) {
                _.forEach(_dag[blockId], (_id) => {
                    let children = _.where( Object.values(blocksList), {parentId: _id});
                    if(children.length) {
                        _dag[_id] = [];
                        _.forEach(children, (child:any) => {
                            _dag[_id].push(child.id);
                        });
                        _generateDag(_id);
                    }
                    else {
                        return;
                    }
                });
            }
        };
        _generateDag('cs_initializer');
        let airObject:any = {
            dagObject: _dag,
            blockObjects: getBlockObject(),
            endBlocks: processEndBlock()
        };
        let mainObjectName='';
        let finalairDagMessage:any = {}, finalairEndDagMessage:any[]=[], finalairBlocksMessage:any={};
        _.forEach(airObject['dagObject'], (children, id) => {
            let dagName = id === 'cs_initializer' ? 'cs_initializer' : airObject['blockObjects'][id]['name']+'_'+id;
            finalairDagMessage[dagName] = [];
            _.forEach(children, (child_id) => {
                finalairDagMessage[dagName].push(airObject['blockObjects'][child_id]['name']+'_'+child_id);
            });
        });
        _.forEach(airObject['endBlocks'], (endId) => {
            finalairEndDagMessage.push(airObject['blockObjects'][endId]['name']+'_'+endId);
        });
        _.forEach(airObject['blockObjects'], (block, id) => {
            let _blockName = id === 'cs_initializer' ? 'cs_initializer' :  block['name'] + '_' + id;
            if(id === 'cs_initializer') {
                mainObjectName=block.mainObject;
            }
            finalairBlocksMessage[_blockName] = block;
        });
        return {
            dagObject: finalairDagMessage,
            blockObjects: finalairBlocksMessage,
            endBlocks: finalairEndDagMessage,
            mainObjectName: mainObjectName
        };
    }

    clearGraphLoading(interval) {
        setTimeout(() => {
            this.graphLoading = false
        }, interval);
    }
}


