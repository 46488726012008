import { Component, ViewChild } from "@angular/core";
import {
    Columns,
    GridConfig,
    Renderers,
    RowEvent,
    AssignClinic,
} from "app/shared/grid-datatable/grid-datatable";
import { HeaderRendererComponent } from "app/shared/grid-datatable/header-renderer/header-renderer.component";
import { CellRendererComponent } from "app/shared/grid-datatable/cell-renderer/cell-renderer.component";
import { ConfigureLayoutComponent } from "app/workflow-setup/configure-layout/configure-layout.component";
import { GlobalService } from "app/support/services/auth/global.service";
import { SmartTestService } from "app/support/services/smart-test.service";
import { MatDialogConfig, MatDialog } from "@angular/material";
import { DeleteDialogComponent } from "app/shared/components/dialog-box/delete-dialog/delete-dialog.component";
import { SnackbarCongiration } from "app/shared/components/snack-bar/SnackbarConfiguration";
import { EditSmartTestComponent } from "../edit-smart-test/edit-smart-test.component";
import { ReferenceComponent } from "../reference/reference.component";
import { CreateSmartTestComponent } from "../create-smart-test/create-smart-test/create-smart-test.component";
import { AssignSmartTestModalComponent } from "../assign-smart-test-modal/assign-smart-test-modal/assign-smart-test-modal.component";
import { MxGraphService } from "app/support/services/mx-graph.service";
import * as moment from "moment";
import { GridApi } from "ag-grid-community";

@Component({
    selector: "app-smart-test-list",
    templateUrl: "./smart-test-list.component.html",
    styleUrls: ["./smart-test-list.component.scss"],
})
export class SmartTestListComponent {
    columns: Columns;
    config: GridConfig;
    renderers: Renderers;
    fullScreenModal: any = {
        title: "",
        subTitle: "",
    };
    openModal: boolean = false;
    workflowMainObjectsFields: any = {};
    tagsList: any = [];
    activeMenuItem: string = "";
    configureMenuList: any[] = [
        {
            id: "workflowsetup_header",
            title: "Workflow",
            isHeader: true,
        },
        {
            id: "workflow_setup",
            title: "Workflow setup",
            isActive: true,
        },
        {
            id: "history_header",
            title: "History",
            isHeader: true,
        },
        {
            id: "history",
            title: "Audit trail",
            isActive: true,
        },
    ];

    deleteModalContent: any = {
        warning_accepted: false,
    };

    openDeleteConfirmation: boolean = false;

    @ViewChild(ConfigureLayoutComponent, { static: false })
    configureLayout: ConfigureLayoutComponent;
    user: any;
    smartTestListData: any = [];
    formLoad: boolean = false;
    active_check: boolean = false;
    selectedRows: any[] = [];
    private gridApi!: GridApi;

    constructor(
        private globalService: GlobalService,
        private smartTestService: SmartTestService,
        private dialog: MatDialog,
        private snackBar: SnackbarCongiration,
        private mx_graphService: MxGraphService,
        private full_screen: MatDialog
    ) {
        const dateComparator = (date1, date2) => {
            if (date1 == "- - - -") return -1;
            if (date2 == "- - - -") return 1;
            if (new Date(date1) > new Date(date2)) return 1;
            if (new Date(date1) < new Date(date2)) return -1;
            return 0;
        };

        this.getSmartTestListData();
        this.globalService.user$.subscribe((user) => (this.user = user));
        let icons = {
            sortUnSort:
                '<img src="assets/images/icons/unsort.png" width="14" />',
            sortAscending:
                '<img src="assets/images/icons/upsort.png" width="12" />',
            sortDescending:
                '<img src="assets/images/icons/downsort.png" width="12" />',
        };
        this.config = {
            rowSelection: true,
            search: true,
            searchUrl: {
                searchValuesUrl: "dynamic-smart-test/dynamic-search-values",
                searchResultUrl: "dynamic-smart-test/dynamic-search-result",
            },
            searchList: [],
            sidepan: true,
            sidepanMenu: ["columns"],
            remoteUrl: "dynamic-smart-test",
            remoteParams: {},
        };

        this.renderers = {
            cellRenderer: [
                { column: "Active Status", renderer: ["status"] },
                { column: "Action", renderer: ["action"] },
            ],
            table: "dynamic-smart-test",
        };

        if (this.user["is_superuser"] == true) {
            this.config["searchList"] = [
                {
                    searchKey: "SMARTest Name",
                    property: "name",
                    field: "name",
                    display: true,
                    searchValues: [],
                    active: false,
                },
                {
                    searchKey: "Rule Type",
                    property: "rule_type",
                    field: "rule_type",
                    display: true,
                    searchValues: [],
                    active: false,
                },
                {
                    searchKey: "Assigned Clinics",
                    property: "assigned_clinic_list",
                    field: "assigned_clinic_list",
                    display: true,
                    searchValues: [],
                    active: false,
                },
            ];

            this.columns = {
                columnDefs: [
                    {
                        headerName: "Name",
                        headerTooltip: "Name",
                        headerCheckboxSelection: true,
                        headerCheckboxSelectionFilteredOnly: true,
                        field: "name",
                        flex: 1,
                        icons: icons,
                        unSortIcon: true,
                        sortable: true,
                        tooltipField: "name",
                        suppressMovable: true,
                        checkboxSelection: true,
                        sortingOrder: ["asc", "desc"],
                        valueGetter:
                            'node.data&&node.data.name ? node.data.name:"- - - -"',
                    },
                    {
                        headerName: "Description",
                        headerTooltip: "Description",
                        field: "description",
                        icons: icons,
                        unSortIcon: true,
                        sortable: true,
                        flex: 1,
                        tooltipField: "description",
                        suppressMovable: true,
                        valueGetter:
                            'node.data&&node.data.description ? node.data.description:"- - - -"',
                    },
                    {
                        headerName: "Rule Type",
                        headerTooltip: "Rule Type",
                        field: "rule_type",
                        flex: 1,
                        unSortIcon: true,
                        sortable: true,
                        icons: icons,
                        tooltipField: "rule_type",
                        suppressMovable: true,
                        sortingOrder: ["asc", "desc"],
                        valueGetter:
                            'node.data&&node.data.rule_type ? node.data.rule_type:"- - - -"',
                    },
                    {
                        headerName: "Assigned Clinics",
                        headerTooltip: "Assigned Clinics",
                        field: "assigned_clinic_list",
                        flex: 1,
                        unSortIcon: true,
                        sortable: true,
                        icons: icons,
                        valueGetter:
                            'node.data&&node.data.assigned_clinic_list.length > 0  ? node.data.assigned_clinic_list:"- - - -"',
                        tooltipField: "assigned_clinic_list",
                        suppressMovable: true,
                        sortingOrder: ["asc", "desc"],
                    },
                    {
                        headerName: "Date & Time",
                        headerTooltip: "Date & Time",
                        field: "updated_at",
                        sortable: true,
                        unSortIcon: true,
                        comparator: dateComparator,
                        minWidth: 200,
                        tooltipField: "updated_at",
                        suppressMovable: true,
                        sortingOrder: ["asc", "desc"],
                        icons: icons,
                        valueGetter: function (params) {
                            if (!params.data.updated_at) {
                                return "- - - -";
                            } else {
                                return moment(params.data.updated_at).format(
                                    "MMM-DD-YYYY, hh:mm A"
                                );
                            }
                        },
                    },
                    {
                        headerName: "Active Status",
                        field: "is_active",
                        headerComponentFramework: HeaderRendererComponent,
                        suppressMovable: true,
                        cellRendererFramework: CellRendererComponent,
                        maxWidth: 140,
                        minWidth: 140,
                    },
                    {
                        headerName: "Action",
                        field: "action",
                        headerTooltip: "Workflow Action",
                        maxWidth: 250,
                        minWidth: 250,
                        icons: icons,
                        suppressMovable: true,
                        cellRendererFramework: CellRendererComponent,
                        pinned: "right",
                        headerClass: "text-center",
                        cellStyle: { textAlign: "center" },
                    },
                ],
                columnTog: [
                    {
                        columnName: "SMARTest Name",
                        field: "name",
                        visible: true,
                    },
                    {
                        columnName: "Description",
                        field: "description",
                        visible: true,
                    },
                    {
                        columnName: "Rule Type",
                        field: "rule_type",
                        visible: true,
                    },
                    {
                        columnName: "Assigned Clinics",
                        field: "assigned_clinic_list",
                        visible: true,
                    },
                    {
                        columnName: "Date & Time",
                        field: "updated_at",
                        visible: true,
                    },
                ],
            };
        } else if (this.user["user_type"]["type"] == "2") {
            this.config["searchList"] = [
                {
                    searchKey: "SMARTest Name",
                    property: "name",
                    field: "name",
                    display: true,
                    searchValues: [],
                    active: false,
                },
                {
                    searchKey: "Rule Type",
                    property: "rule_type",
                    field: "rule_type",
                    display: true,
                    searchValues: [],
                    active: false,
                },
                {
                    searchKey: "Clinic Name",
                    property: "clinic_name",
                    field: "clinic_name",
                    display: true,
                    searchValues: [],
                    active: false,
                },
                {
                    searchKey: "Status",
                    property: "is_active",
                    field: "is_active",
                    display: true,
                    searchValues: [],
                    active: true,
                },
            ];

            this.columns = {
                columnDefs: [
                    {
                        headerName: "SMARTest Name",
                        headerTooltip: "SMARTest Name",
                        field: "name",
                        flex: 1,
                        icons: icons,
                        unSortIcon: true,
                        sortable: true,
                        tooltipField: "name",
                        suppressMovable: true,
                        sortingOrder: ["asc", "desc"],
                    },
                    {
                        headerName: "Description",
                        headerTooltip: "Description",
                        field: "description",
                        icons: icons,
                        unSortIcon: true,
                        sortable: true,
                        flex: 1,
                        tooltipField: "description",
                        suppressMovable: true,
                        valueGetter:
                            'node.data&&node.data.description ? node.data.description:"- - - -"',
                    },
                    {
                        headerName: "Rule Type",
                        headerTooltip: "Rule Type",
                        field: "rule_type",
                        flex: 1,
                        unSortIcon: true,
                        sortable: true,
                        icons: icons,
                        tooltipField: "rule_type",
                        suppressMovable: true,
                        sortingOrder: ["asc", "desc"],
                    },
                    {
                        headerName: "Clinic Name",
                        headerTooltip: "Clinic Name",
                        field: "clinic_name",
                        flex: 1,
                        unSortIcon: true,
                        sortable: true,
                        icons: icons,
                        tooltipField: "clinic_name",
                        suppressMovable: true,
                        sortingOrder: ["asc", "desc"],
                        valueGetter:
                            'node.data&&node.data.clinic_name ? node.data.clinic_name:"- - - -"',
                    },
                    {
                        headerName: "Date & Time",
                        headerTooltip: "Date & Time",
                        field: "updated_at",
                        sortable: true,
                        unSortIcon: true,
                        comparator: dateComparator,
                        minWidth: 200,
                        tooltipField: "updated_at",
                        suppressMovable: true,
                        sortingOrder: ["asc", "desc"],
                        icons: icons,
                        valueGetter: function (params) {
                            if (!params.data.updated_at) {
                                return "- - - -";
                            } else {
                                return moment(params.data.updated_at).format(
                                    "MMM-DD-YYYY, hh:mm A"
                                );
                            }
                        },
                    },
                    {
                        headerName: "Active Status",
                        headerTooltip: "Active Status",
                        maxWidth: 150,
                        minWidth: 150,
                        sortable: false,
                        field: "is_active",
                        suppressMovable: true,
                        cellRendererFramework: CellRendererComponent,
                    },
                    {
                        headerName: "Action",
                        headerTooltip: "Workflow Action",
                        minWidth: 80,
                        maxWidth: 80,
                        icons: icons,
                        suppressMovable: true,
                        pinned: "right",
                        headerClass: "text-center",
                        cellStyle: { textAlign: "center" },
                        cellRendererFramework: CellRendererComponent,
                    },
                ],
                columnTog: [
                    {
                        columnName: "SMARTest Name",
                        field: "name",
                        visible: true,
                    },
                    {
                        columnName: "Description",
                        field: "description",
                        visible: true,
                    },
                    {
                        columnName: "Rule Type",
                        field: "rule_type",
                        visible: true,
                    },
                    {
                        columnName: "Clinic Name",
                        field: "clinic_name",
                        visible: true,
                    },
                    {
                        columnName: "Date & Time",
                        field: "updated_at",
                        visible: true,
                    },
                ],
            };
        }
    }

    restoreSelection() {
        if (this.gridApi) {
            this.gridApi.forEachNode((node) => {
                if (this.selectedRows.some((row) => row.id === node.data.id)) {
                    node.setSelected(true);
                }
            });
        }
    }

    onExportWorflows(data) {
        this.smartTestService.exportWorkflow(data).subscribe(
            (response) => {
                if (response) {
                    let dataType = response["type"];
                    let binaryData = [];
                    binaryData.push(response);
                    let downloadLink = document.createElement("a");
                    downloadLink.href = window.URL.createObjectURL(
                        new Blob(binaryData, { type: dataType })
                    );
                    downloadLink.setAttribute("download", "export.csv");
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    this.snackBar.triggerSnackBar({
                        message: "Successfully exported Workflows",
                        type: "success",
                    });
                }
            },
            (error) => {
                this.snackBar.triggerSnackBar({
                    message: "Error on exporting Workflows",
                    type: "error",
                });
            }
        );
    }

    onGridReady(params: any) {
        this.gridApi = params.api;
    }

    getSmartTestListData() {
        this.smartTestService.getSmartTestList().subscribe((response) => {
            this.smartTestListData = response["data"];
            this.smartTestService.loadConfigure("false");
        });
    }

    rowEvent(event: RowEvent) {
        this.mx_graphService.clearPreviousXmlSource();
        this.mx_graphService.setGraphLoadingFalse();
        if (event.action == "view") {
            event["data"]["viewMode"] = false;
            event["data"]["action"] = event.action;
            this.getDialog(event);
        } else if (event.action == "active-workflow-status") {
            this.activeStatus(event);
        } else if (event.action == "change-workflow-status") {
            this.changeStatus(event);
        } else if (event.action == "edit") {
            this.smartTestService.loadConfigure("true");
            let dialogConfig = new MatDialogConfig();
            dialogConfig.maxWidth = "600px";
            dialogConfig.width = "600px";
            dialogConfig.height = "100%";
            dialogConfig.data = event["data"];
            dialogConfig.disableClose = true;
            dialogConfig.position = {
                right: "0",
            };

            let dialogRef = this.dialog.open(
                EditSmartTestComponent,
                dialogConfig
            );
            dialogRef.afterClosed().subscribe((res) => {
                if (res != undefined) {
                    this.getDialog(res);
                }
                this.smartTestService.loadConfigure("false");
            });
        } else if (event.action == "delete") {
            this.deleteWorkFlow(event.data, event.data["id"]);
        } else if (event.action == "clone") {
            this.cloneWorkflow(event.data, event.data["id"]);
        } else if (event.action == "view-workflow") {
            event["data"]["viewMode"];
            event["data"]["action"] = event.action;
            this.getDialog(event);
        }
    }

    deleteWorkFlow(data: Object, index: number) {
        this.openDeleteConfirmation = true;
        let dialogConfig = new MatDialogConfig();
        dialogConfig.width = "40%";
        dialogConfig.data = { yes: null, no: null };
        dialogConfig.position = { top: "70px" };
        (dialogConfig.disableClose = true),
            (dialogConfig.data = {
                title: "Delete Workflow?",
                sub_title: "Are you sure you want to delete this Workflow?",
                no_button: "No, keep it",
                yes_button: "Yes, Delete the Workflow",
            });
        let dialogRef = this.dialog.open(DeleteDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((value) => {
            if (value == "yes") {
                this.smartTestService.loadConfigure("true");
                this.deleteModalContent["id"] = index;
                this.deleteModalContent["warning_accepted"] = true;
                this.removeWorkflow(this.deleteModalContent);
            } else {
                this.smartTestService.loadConfigure("false");
            }
        });
    }

    removeWorkflow(data: Object) {
        this.smartTestService.deleteWorkflow(data).subscribe(
            (response) => {
                if (response["message"] == "SMARTest deleted successfully") {
                    this.snackBar.triggerSnackBar({
                        message: "Workflow deleted successfully",
                        type: "success",
                    });
                } else if (
                    response["message"] ==
                    "This workflow is active to some clinic"
                ) {
                    this.snackBar.triggerSnackBar({
                        message: "This workflow is active to some clinic",
                        type: "warning",
                    });
                }
                this.reloadCurrentPage();
                this.smartTestService.loadConfigure("false");
            },
            (error) => {
                this.snackBar.triggerSnackBar({
                    message: "Error in deleting Workflow",
                    type: "error",
                });
            }
        );
    }

    reloadCurrentPage() {
        this.smartTestService.refreshRowData("refresh");
    }

    makeBold(str) {
        return str.bold();
    }

    openReactiveModal(alreadyExistRule, event) {
        try {
            let dialogConfig = new MatDialogConfig();
            dialogConfig.width = "40%";
            dialogConfig.data = { yes: null, no: null };
            dialogConfig.position = { top: "100px" };
            let smarttestName = alreadyExistRule["name"];
            let regEx = new RegExp(smarttestName, "g");
            let helpText = `There is already an active workflow with this rule type. ${alreadyExistRule["name"]} is currently active. Would you like to deactivate this workflow and activate the currently selected workflow?`;
            let dialogText = helpText.replace(
                regEx,
                this.makeBold(smarttestName)
            );
            (dialogConfig.disableClose = true),
                (dialogConfig.data = {
                    title: "Close this workflow?",
                    sub_title: dialogText,
                    no_button: "No, keep it",
                    yes_button: "Yes, continue with change",
                });
            let dialogRef = this.dialog.open(
                DeleteDialogComponent,
                dialogConfig
            );
            dialogRef.afterClosed().subscribe((value) => {
                if (value == "yes") {
                    event["data"]["active_rule_id"] =
                        alreadyExistRule["assign_clinic_dynamic_smart_test"];
                    this.smartTestService
                        .workflowStatusUpdate(event["data"])
                        .subscribe((response) => {
                            this.active_check = false;
                            if (response["status"] == false) {
                                let alreadyExistRule =
                                    response["active_rule_id"];
                                if (alreadyExistRule) {
                                    this.openReactiveModal(
                                        alreadyExistRule,
                                        event
                                    );
                                }
                            } else if (response["data"]["is_active"] == true) {
                                this.snackBar.triggerSnackBar({
                                    message:
                                        "Rule type is activated successfully",
                                    type: "success",
                                });
                            } else {
                                this.snackBar.triggerSnackBar({
                                    message:
                                        "Rule type is deactivated successfully",
                                    type: "success",
                                });
                            }
                            this.reloadCurrentPage();
                            this.getSmartTestListData();
                        });
                }
            });
        } catch (e) {}
    }

    activeStatus(event: any) {
        this.smartTestService.loadConfigure("true");
        if (this.active_check == false) {
            if (event["data"]["rule_type"] != null) {
                this.active_check = true;
                event["data"]["is_active"] = event["isActivate"];
                if (
                    event["isActivate"] == false &&
                    event["data"]["assigned_clinic_list"].length >= 1
                ) {
                    this.smartTestService
                        .getActiveClinic(event["data"])
                        .subscribe((res) => {
                            if (res["data"]["active_clinic_list"].length) {
                                let dialogConfig = new MatDialogConfig();
                                dialogConfig.width = "40%";
                                dialogConfig.data = { yes: null, no: null };
                                dialogConfig.position = { top: "100px" };
                                dialogConfig.data = {
                                    title: "Deactivate this workflow?",
                                    sub_title: `This Workflow will no longer active in those clinics - ${res["data"]["active_clinic_list"]}`,
                                    no_button: "No, keep it",
                                    yes_button: "Yes, continue",
                                };
                                let dialogRef = this.dialog.open(
                                    DeleteDialogComponent,
                                    dialogConfig
                                );
                                dialogRef.afterClosed().subscribe((value) => {
                                    if (value == "yes") {
                                        this.workflowStatus(event);
                                    } else {
                                        this.active_check = false;
                                        this.reloadCurrentPage();
                                        this.getSmartTestListData();
                                    }
                                });
                            } else {
                                this.workflowStatus(event);
                            }
                        });
                } else {
                    this.workflowStatus(event);
                }
            } else {
                this.snackBar.triggerSnackBar({
                    message: "Workflow without rule type is unable to activate",
                    type: "failure",
                });
                this.reloadCurrentPage();
                this.getSmartTestListData();
            }
        }
    }

    workflowStatus(event) {
        this.smartTestService
            .workflowActiveStatus(event["data"])
            .subscribe((response) => {
                if (response["data"]["is_active"] == true) {
                    this.snackBar.triggerSnackBar({
                        message: "Workflow is activated successfully",
                        type: "success",
                    });
                } else {
                    this.snackBar.triggerSnackBar({
                        message: "Workflow is deactivated successfully",
                        type: "success",
                    });
                }
                this.active_check = false;
                this.reloadCurrentPage();
                this.getSmartTestListData();
            });
    }

    changeStatus(event: any) {
        this.smartTestService.loadConfigure("true");
        if (this.active_check == false) {
            this.active_check = true;
            event["data"]["is_active"] = event["isActivate"];
            event["data"]["clinic_id"] = event.data["clinic"];
            this.smartTestService
                .workflowStatusUpdate(event["data"])
                .subscribe((response) => {
                    this.active_check = false;
                    if (response["status"] == false) {
                        let alreadyExistRule = response["active_rule_id"];
                        if (alreadyExistRule) {
                            this.openReactiveModal(alreadyExistRule, event);
                        } else {
                            this.snackBar.triggerSnackBar({
                                message: response["message"],
                                type: "failure",
                            });
                        }
                    } else if (response["data"]["is_active"] == true) {
                        this.snackBar.triggerSnackBar({
                            message: "Rule type is activated successfully",
                            type: "success",
                        });
                    } else {
                        this.snackBar.triggerSnackBar({
                            message: "Rule type is deactivated successfully",
                            type: "success",
                        });
                    }
                    this.reloadCurrentPage();
                    this.getSmartTestListData();
                });
        }
    }

    assignClinic(event: AssignClinic) {
        if (event.action == "assign-clinic") {
            this.smartTestService.loadConfigure("true");
            if (event.data["rule_type"] == null) {
                this.snackBar.triggerSnackBar({
                    message: "No workflow is created for this smart test",
                    type: "warning",
                });
            } else {
                let dialogConfig = new MatDialogConfig();
                dialogConfig.maxWidth = "600px";
                dialogConfig.width = "600px";
                dialogConfig.height = "100%";
                dialogConfig.data = event.data;
                dialogConfig.disableClose = true;
                dialogConfig.position = {
                    right: "0",
                };

                let dialogRef = this.dialog.open(
                    AssignSmartTestModalComponent,
                    dialogConfig
                );
                dialogRef.afterClosed().subscribe((res) => {
                    if (res == "empty") {
                        this.snackBar.triggerSnackBar({
                            message:
                                "SMARTest workflow can not be activated due to it not having a clinic assigned",
                            type: "failure",
                        });
                    } else if (res == "success") {
                        this.snackBar.triggerSnackBar({
                            message: "Workflow successfully assigned",
                            type: "success",
                        });
                    }
                    this.smartTestService.loadConfigure("false");
                });
            }
        }
    }

    cloneWorkflow = (data: Object, id: number) => {
        let dialogConfig = new MatDialogConfig();
        dialogConfig.maxWidth = "600px";
        dialogConfig.width = "600px";
        dialogConfig.height = "100%";
        dialogConfig.data = { ...data, action: "clone" };
        dialogConfig.disableClose = true;
        dialogConfig.position = {
            right: "0",
        };

        if (data != null) {
            if (data["rule_type"]) {
                let cloneRef = this.dialog.open(
                    CreateSmartTestComponent,
                    dialogConfig
                );
                cloneRef.afterClosed().subscribe((res) => {
                    this.reloadCurrentPage();
                    if (res["action"] == "clone-configure") {
                        this.getDialog(res);
                    }
                });
            } else {
                this.snackBar.triggerSnackBar({
                    message: "Workflow not cloned: Rule type is not created",
                    type: "warning",
                });
            }
        }
    };

    onCreateButtonClick() {
        let dialogConfig = new MatDialogConfig();
        dialogConfig.maxWidth = "600px";
        dialogConfig.width = "600px";
        dialogConfig.height = "100%";
        dialogConfig.data = { action: "create" };
        dialogConfig.disableClose = true;
        dialogConfig.position = {
            right: "0",
        };
        let dialogRef = this.dialog.open(
            CreateSmartTestComponent,
            dialogConfig
        );
        dialogRef.afterClosed().subscribe((res) => {
            if (res != undefined) {
                this.getDialog(res);
            }
        });
    }

    getDialog(value: any) {
        this.smartTestService.loadConfigure("true");
        if (!this.openModal) {
            this.openModal = true;
            this.smartTestService
                .getConfigure(value["data"])
                .subscribe((res) => {
                    this.mx_graphService.getObject(res);
                    if (res["data"][0] != undefined) {
                        value["data"]["dag_object"] =
                            res["data"][0]["dag_object"];
                        value["data"]["xml_source"] =
                            res["data"][0]["xml_source"];
                    }
                    const dialogConfig = new MatDialogConfig();
                    let data = value["data"];
                    this.fullScreenModal.title = data.name;
                    this.fullScreenModal.subTitle = "Workflow setup";
                    data["index"] = data.id;
                    data["action"] = value["action"];
                    dialogConfig.maxWidth = "100vw";
                    dialogConfig.maxHeight = "100vh";
                    dialogConfig.width = "100%";
                    dialogConfig.height = "100%";
                    dialogConfig.data = data;
                    dialogConfig.panelClass = "custom-modalbox";
                    const fsModel = this.full_screen.open(
                        ReferenceComponent,
                        dialogConfig
                    );
                    fsModel.afterClosed().subscribe((res) => {
                        this.reloadCurrentPage();
                        this.smartTestService.loadConfigure("false");
                        this.openModal = false;
                    });
                });
        }
    }
}
