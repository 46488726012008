import { NgModule } from '@angular/core';

import { FuseIfOnDomDirective } from 'app/shared/directives/fuse-if-on-dom/fuse-if-on-dom.directive';
import { FuseInnerScrollDirective } from 'app/shared/directives/fuse-inner-scroll/fuse-inner-scroll.directive';
import { FusePerfectScrollbarDirective } from 'app/shared/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseMatSidenavHelperDirective, FuseMatSidenavTogglerDirective } from 'app/shared/directives/fuse-mat-sidenav/fuse-mat-sidenav.directive';
import { CounterDirective } from './counter.directive';
import { IsNumberDirective } from './is-number.directive';
import { DateMaskDirective } from './date-mask.directive';
@NgModule({
    declarations: [
        FuseIfOnDomDirective,
        FuseInnerScrollDirective,
        DateMaskDirective,
        FuseMatSidenavHelperDirective,
        FuseMatSidenavTogglerDirective,
        FusePerfectScrollbarDirective,
        IsNumberDirective,
        CounterDirective,
    ],
    imports     : [],
    exports     : [
        FuseIfOnDomDirective,
        FuseInnerScrollDirective,
        DateMaskDirective,
        IsNumberDirective,
        FuseMatSidenavHelperDirective,
        FuseMatSidenavTogglerDirective,
        FusePerfectScrollbarDirective,
        CounterDirective,
    ]
})
export class FuseDirectivesModule
{
}
