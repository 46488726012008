import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, catchError, timeout } from 'rxjs/operators';
import { InterfaceService } from './auth/interface.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PatientAssessmentService extends InterfaceService {

  public assessment_template_type = new BehaviorSubject<any>(null)

  private view =new BehaviorSubject<string>('Assessment');
  screenview$=this.view.asObservable()
  
  assessment_template_value$: Observable<any> = this.assessment_template_type.asObservable()

  constructor(private http: HttpClient, private router: Router) {
    super()
  }

  public assignAssessment(data) {
    data['base_path'] = window.location.origin + this.router.createUrlTree([`/survey`]).toString();
    return this.http.post(this.getApiUrl(`run-assessments/`), data, this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

  public verifyAssessmentTakeToken(data) {
    return this.http.post(this.getApiUrl(`take-assessments/verify-assessment-token/`), data, this.getHttpOptions('json', false)).pipe(
      map(Response => Response),
      timeout(10000)
    );
  }

  public verifyAssessmentTakeCode(data) {
    return this.http.post(this.getApiUrl(`take-assessments/verify-assessment-code/`), data, this.getHttpOptions('json', false)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

  public getPatientAssessment(data) {
    return this.http.post(this.getApiUrl(`run-assessments/get-patient-assessments/`), data, this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

  public getPatientAssessmentQuestions(url, data) {
    return this.http.post(this.getApiUrl(url), data, this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

  AssessmentViewChange(view){
    this.view.next(view); 
  }

  public getRunAssessmentCreateData(params) {
    return this.http.get(this.getApiUrl(`run-assessments/get-run-assessments-data/`, params), this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

  public getRunAssessmentData(clinicId: any) {
    return this.http.get(this.getApiUrl(`run-assessments/get-run-assessments-data/`, { 'clinicId': clinicId }), this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

  public getRunAssessmentDataForGroup(clinicId: any) {
    return this.http.get(this.getApiUrl(`run-assessments/get-run-assessments-data/`, { 'clinicId': clinicId, 'is_group': true }), this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

  public getPatientData(data) {
    return this.http.post(this.getApiUrl(`run-assessments/get-patient-data/`), data, this.getHttpOptions('json', true)).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  }

  public updatePatientAssessmentAnswer(data) {
    return this.http.post(this.getApiUrl('take-assessments/update-patient-assessment-answer/'), data, this.getHttpOptions('json', true)).pipe(
      timeout(10000),
      map(Response => Response)
    );
  }

  public updatePatientAssessmentRunStatus(data) {
    let link = window.location.origin + this.router.createUrlTree([`assessments/detail-view/${data['patientAssessmentId']}`], { queryParams: { 'runIds': data['runIds'].map(function (elem) { return elem['id'] }) } }).toString();
    data['link'] = link
    return this.http.post(this.getApiUrl(`take-assessments/update-patient-assessment-run-status/`), data, this.getHttpOptions('json', true)).pipe(
      map(response => response),
      timeout(10000)
    );
  }

  public submitPatientAssessment(data) {
    let link = window.location.origin + this.router.createUrlTree([`assessments/detail-view/${data['patientAssessmentId']}`], { queryParams: { 'runIds': data['runIds'].map(function (elem) { return elem['id'] }) } }).toString();
    data['link'] = link
    return this.http.post(this.getApiUrl(`take-assessments/submit-patient-assessment/`), data, this.getHttpOptions('json', true)).pipe(
      map(response => response),
      timeout(10000)
    );
  }

  public patientAssessmentNotification(data: any) {
    return this.http.post(this.getApiUrl(`run-assessments/patient-assessment-notification/`), data, this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

  public getAssessmentTabData(data) {
    return this.http.post(this.getApiUrl(`run-assessments/get-assessment-tabs/`), data, this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

  public getAssessmentDetail(data) {
    return this.http.post(this.getApiUrl(`run-assessments/assessment-detail-view/`), data, this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

  public getAssessmentSection(data) {
    return this.http.post(this.getApiUrl(`run-assessments/get-assessment-section/`), data, this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

  public getAssignedPatientDetails() {
    return this.http.get(this.getApiUrl(`run-assessments/`), this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

  public updateAssessmentNotes(data) {
    return this.http.post(this.getApiUrl(`patient-assessment/update-assessment-notes/`), data, this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

  public getAssessmentNotes(data) {
    return this.http.post(this.getApiUrl(`patient-assessment/get-assessment-notes/`), data, this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

  public printAssessment(data) {
    return this.http.post(this.getApiUrl(`run-assessments/print-assessment`), data, this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

  public setselectedTemplateType(message: number) {
    this.assessment_template_type.next(message)
  }

  public getLaunchedPatientAssessment(data) {
    return this.http.post(this.getApiUrl(`run-assessments/get-launched-patient-assessment/`), data, this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

  public getLaunchedPatientMrnAssessment(data) {
    return this.http.post(this.getApiUrl(`run-assessments/get-launched-patient-mrn-assessment/`), data, this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }


  public changePatientAssessed(runId, getQueueData: boolean, multiIds?: any) {
    let params = null;
    if (getQueueData) {
      params = { 'runIds': multiIds, 'getQueueData': getQueueData };
    }
    else if (multiIds && multiIds.length > 0) {
      params = { 'runIds': multiIds };
    }
    return this.http.get(this.getApiUrl(`run-assessments/${runId}/change-patient-assessed`, params), this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

  printAllAssessments(data) {
    let httpOptions = this.getHttpOptions('json', true);
    httpOptions['responseType'] = 'blob';
    httpOptions['observe'] = 'response'
    return this.http.post(this.getApiUrl(`api_print_overall_assessment/`), data, httpOptions).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
    // this._http.post('', print_details, ).subscribe(res => {
    //   const url = window.URL.createObjectURL(res.body);
    //   window.open(url);
    // });
  }

  getGroupForClinicData(data) {
    if (data) {
      return this.http.post(this.getApiUrl(`patient_group/static-patient-group-list/`), data, this.getHttpOptions('json', true)).pipe(
        map(Response => Response),
        catchError(this.handleError)
      );
    }
  }

  getPatientGroupDetails(data) {
    return this.http.post(this.getApiUrl(`run-assessments/get-patient-group-data/`), data, this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

  updateDynamicGroupAssessment(data) {
    return this.http.post(this.getApiUrl(`patient_group/dynamic-patient-group-update/`), data, this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

  launchPatientFroup(data) {
    return this.http.post(this.getApiUrl(`run-assessments/launch-patient-group-assessment/`), data, this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

  verfiyPatientGroupEmail(data) {
    return this.http.post(this.getApiUrl(`run-assessments/verify-email-mobile/`), data, this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }
}
