import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[inputIsNumber]',
})
export class IsNumberDirective {
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const allowedKeys = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'Tab',
      'Control',
      'Meta',
      'Enter',
      'v',
      'c',
    ];

    if (
      ((event.ctrlKey || event.metaKey) || allowedKeys.includes(event.key)) ||
      (event.key >= '0' && event.key <= '9')
    ) {
      return;
    }

    event.preventDefault();
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedValue = clipboardData.getData('text');

    if (isNaN(Number(pastedValue))) {
      event.preventDefault();
    }
  }
}
