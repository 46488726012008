import { mxgraphFactory } from 'mxgraph-factory';

const { mxCodec } = mxgraphFactory({
  mxLoadResources: false,
  mxLoadStylesheets: true,
});

export class CustomMxCodec extends mxCodec {
  getRuleNode(childNodes) {
    let ruleNode = null;

    childNodes.forEach((child) => {
      const isRule = child.getAttribute('condition');
      if (child.nodeName === 'Object' && isRule) {
        ruleNode = child;
      }
    });
    return ruleNode;
  }

  processNode(rulesNode, withoutValue) {
    const rules = [];

    if (rulesNode) {
      rulesNode.childNodes.forEach((child) => {
        if (child.nodeName === 'Object') {
          const rule: any = {};

          Array.from(child.attributes).forEach((attr: any) => {
            rule[attr.name] = attr.value;
          });
          const nestedRulesArray = Array.from(child.childNodes).find(
            (node: any) =>
              node.nodeName === 'Array' && node.getAttribute('as') === 'rules'
          );

          const notAllowedOperators = ['in', 'not in'];

          if (nestedRulesArray) {
            rule.rules = this.processNode(nestedRulesArray, false);
          } else {
            if (notAllowedOperators.includes(rule.operator)) {
              const values = Array.from(child.childNodes)
                .filter(
                  (node: any) =>
                    node.nodeName === 'Array' &&
                    node.getAttribute('as') === 'value'
                )
                .reduce((acc: string[], arrayNode: any) => {
                  const nestedValues = Array.from(arrayNode.childNodes)
                    .filter((node: any) => node.nodeName === 'add')
                    .map((addNode: any) => addNode.getAttribute('value'));

                  return acc.concat(nestedValues);
                }, []);
              rule['value'] = values;
            } else if (!rule.value) {
              rule['value'] = '?';
            }
          }
          if (withoutValue) {
            const isRule = rule.condition;
            if (isRule) {
              rules.push(rule);
            }
          } else {
            rules.push(rule);
          }
        }
      });
    }

    return rules;
  }

  processInitialBlockNode(initialBlockNode) {
    const rules = [];

    const nestedRulesArray = Array.from(initialBlockNode.childNodes).find(
      (node: any) =>
        node.nodeName === 'Array' && node.getAttribute('as') === 'rules'
    );

    if (nestedRulesArray) {
      const rule = this.processNode(nestedRulesArray, false)[0];
      if (rule) {
        rules.push(rule);
      }
    }

    return rules;
  }

  decodeCell(node) {
    const cell = super.decodeCell(node);
    const childNodes = node.childNodes;

    childNodes.forEach((child) => {
      const isInitialBlock = child.getAttribute('mainObject');
      const isConditional = child.getAttribute('isConditional');

      if (isInitialBlock && cell.value && cell.value.rules) {
        const processRulesArray = this.processInitialBlockNode(child);
        cell.value.rules = processRulesArray;
      }

      if (isConditional === '1') {
        if (cell.value.condition.value && cell.value.condition.value.rules) {
          const ruleNode = this.getRuleNode(child.childNodes);
          const processRulesArray = this.processNode(ruleNode, false);

          cell.value.condition.value.rules = processRulesArray[0].rules;
        } else if (cell.value.condition && cell.value.condition.rules) {
          const processRulesArray = this.processNode(child, true);

          cell.value.condition.rules = processRulesArray[0].rules;
        }
      }
    });

    return cell;
  }
}
