import { Directive, HostListener, ElementRef } from '@angular/core';
import * as moment from 'moment';

@Directive({
  selector: '[dateMask]',
})
export class DateMaskDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: KeyboardEvent) {
    const input = this.el.nativeElement;
    let rawValue = input.value.replace(/\D/g, '');

    if (rawValue.length > 8) {
      rawValue = rawValue.slice(0, 8);
    }

    const formattedValue = this.formatDate(rawValue);
    input.value = formattedValue;

    if (this.isDateValid(formattedValue)) {
      input.setCustomValidity('');
    } else {
      input.setCustomValidity('Invalid date');
    }
  }

  private formatDate(value: string): string {
    let result = value;

    if (value.length > 2) {
      result = `${value.slice(0, 2)}/${value.slice(2)}`;
    }
    if (value.length > 4) {
      result = `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4)}`;
    }

    return result;
  }

  private isDateValid(value: string): boolean {
    return moment(value, 'MM/DD/YYYY', true).isValid();
  }
}
