import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-reload",
  templateUrl: "./reload.component.html",
  styleUrls: ["./reload.component.scss"],
})
export class ReloadComponent {
  yes: any;
  /**
   * Constructor
   *
   * @param {MatDialogRef<DialogComponent>} dialogRef
   *
   */
  constructor(
    public dialogRef: MatDialogRef<ReloadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  close() {
    this.dialogRef.close();
  }

  closeModal(param): void {
    this.dialogRef.close(param);
  }
}
