import {
    Component,
    OnInit,
    ViewChild,
    Input,
    Output,
    EventEmitter,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AgGridAngular } from "ag-grid-angular";
import {
    GridOptions,
    GridApi,
    ColumnApi,
    IGetRowsParams,
} from "ag-grid-community";
import { Subscription } from "rxjs/internal/Subscription";

// components
import { CellRendererComponent } from "./cell-renderer/cell-renderer.component";
import { PatientService } from "app/support/services/patient.service";
import { CreatePatientgroupComponent } from "app/features/main/patients/create-patientgroup/create-patientgroup.component";
import { ConfimerComponent } from "../components/dialog-box/confimer/confimer.component";
import { SnackbarCongiration } from "../components/snack-bar/SnackbarConfiguration";

// interfaces
import {
    GridConfig,
    Columns,
    Renderers,
    RowEvent,
    HeaderEvent,
    Column,
    ChangeStatus,
    AssignClinic,
    ActiveStatus,
} from "./grid-datatable";
import { SearchList } from "../extended-search/slim-search/slim-search";

// services
import { UsersService } from "app/support/services/users.service";
import { InterfaceService } from "app/support/services/auth/interface.service";
import { GetApiurl } from "app/support/app-config/config";
import { CommonService } from "app/support/services/common.service";
import { FormControl } from "@angular/forms";
import * as moment from "moment";
import { Subject } from "rxjs";
import { MatDialog, MatDialogConfig } from "@angular/material";

import { MxGraphService } from "app/support/services/mx-graph.service";
import { SmartTestService } from "app/support/services/smart-test.service";

@Component({
    selector: "grid-datatable",
    templateUrl: "./grid-datatable.component.html",
    styleUrls: ["./grid-datatable.component.scss"],
})
export class GridDatatableComponent extends InterfaceService implements OnInit {
    @ViewChild("SlimSearchTag", { static: false }) SlimSearchTag: any;

    // columns
    columnTog = [];
    columnDefs: Array<any> = [];
    cellRenderer: Object = {};
    headerRenderer: Object = {};

    // rows
    rowData: Array<any> = [];
    tempRowData: Array<any> = [];
    zeroText: String = "No rows to show";
    height: any = null;
    rowClassRules: any = {};
    isRowSelectable: any;

    // sidepan
    sidepan: boolean = false;
    sidepanMenu: Array<string> = [];
    enableSidepan: boolean = false;

    // searchbox
    search: boolean = false;
    searchUrl: Object = {};
    searchList: SearchList[] = [];
    columnUpdated: any = 0;
    visibleColCount: any = 0;
    searchFilter: any = null;
    queueInterval: any = null;
    refreshLog: boolean = false;

    // table configs
    config: GridConfig;
    gridApi: GridApi;
    gridOptions: GridOptions;
    gridColumnApi: ColumnApi;
    frameworkComponents: { cellRenderer: any; agColumnHeader?: any };
    context: {
        gridRenderer: any;
        cellRenderer?: Object;
        headerRenderer?: Object;
    };
    subscriptions: Object = {};
    overlayHide: boolean = false;
    activeOptions: Array<any> = [];
    screenName: String = "";
    visibleColumns: Array<any> = [];
    hiddenColumns: Array<any> = [];
    gridSize: number = 850;
    filterQuery: Array<any> = [];
    inactivePopup: boolean = false;
    dynamicPatientList: Array<any> = [];
    enableDynamicGroup: boolean = false;
    enableDynamicGroupButton: boolean = false;
    setDynamicList: Array<any> = [];
    updateQuery: Array<any> = [];
    dynamicGroupupdateWarning: boolean = false;
    tempEnable: boolean = false;
    dynamic_group_patient_list: Array<any> = [];
    isFilterAction: boolean = false;

    public clinicDetails: any;

    //param- audit
    date_params: any;
    selected: boolean = false;
    dataLoad: boolean = false;
    tableLoad: boolean = false;
    searchQuery: any;
    tzoffset: any;
    params: any = {};
    selectedone: boolean = false;
    // current_year: any = new Date().getFullYear();
    date = new Date();
    current_date: any = new Date(this.date.valueOf());
    start = this.date.setDate(this.date.getDate() - 7);
    End_date = new FormControl(moment());
    Start_date = new FormControl(moment(this.start.valueOf()));
    startDate: any = new Date(
        this.Start_date.value.startOf("day") -
            new Date().getTimezoneOffset() * 60000
    ).toISOString();
    endDate: any = new Date(
        this.End_date.value.endOf("day") -
            new Date().getTimezoneOffset() * 60000
    ).toISOString();
    public grpdata = new Subject<any>();
    requestMethod: String = "GET";

    public ModelName: any;

    subscription6: Subscription;
    listScr = ["users", "patients", "clinics"];

    // template reference
    @ViewChild("agGrid", { static: false }) agGrid: AgGridAngular;

    // events
    @Output("rowEvent") rowEvent: EventEmitter<any> = new EventEmitter();
    @Output("rowDataChanged") rowDataChanged: EventEmitter<any> =
        new EventEmitter();
    @Output("rowSelectionEvent") rowSelectionEvent: EventEmitter<any> =
        new EventEmitter();
    @Output("headerEvent") headerEvent: EventEmitter<any> = new EventEmitter();
    @Output("firstRowData") firstRowData: EventEmitter<any> =
        new EventEmitter();
    @Output("changeStatus") changeStatus: EventEmitter<any> =
        new EventEmitter();
    @Output("activeStatus") activeStatus: EventEmitter<any> =
        new EventEmitter();
    @Output("assignClinic") assignClinic: EventEmitter<any> =
        new EventEmitter();

    @Input("onCreateButtonClick") onCreateButtonClick?: any;
    @Input("createButtonTitle") createButtonTitle?: string;
    @Input("tableTitle") tableTitle?: string;
    @Input("enableImportExport") enableImportExport?: boolean;
    @Input("onExportButtonClick") onExportButtonClick?: any;
    @Input("onImportButtonClick") onImportButtonClick?: any;
    @Input("isSuperUser") isSuperUser?: boolean;

    @Input("screen")
    set screen(screen: String) {
        this.screenName = screen;
    }

    @Input("requestType")
    set requestType(requestType: String) {
        this.requestMethod = requestType;
    }

    @Input("Model")
    set Model(Model: String) {
        this.ModelName = Model;
    }

    //Subscriptions
    subscription1: Subscription;
    subscription2: Subscription;
    subscription3: Subscription;
    subscription4: Subscription;
    subscription5: Subscription;
    changeviewSubscription: Subscription;
    querySubscription: Subscription;

    // get configuration for table and search
    @Input("config")
    set tableConfig(config: GridConfig) {
        this.search = config.search;
        this.searchUrl = config.searchUrl;
        this.searchList = config.searchList.concat([]);
        this.sidepan = config.sidepan;
        this.sidepanMenu = config.sidepanMenu;
        this.config = config;
        // if (config.remoteUrl != 'patients'){
        // this.retrieveData();
        //}
    }

    // get cell and header renderers
    @Input("renderers")
    set renderers(renderer: Renderers) {
        this.context["cellRenderer"] = renderer.cellRenderer;
        this.context["headerRenderer"] = renderer.headerRenderer;
        this.context["table"] = renderer.table;
    }

    // get column definitions and togglers
    @Input("columns")
    set columns(columnData: Columns) {
        this.columnDefs = columnData.columnDefs.concat([]);
        this.columnTog = columnData.columnTog.concat([]).sort((a,b) => a.columnName.localeCompare(b.columnName));
    }

    @Input("column")
    set column(columndata: Column) {
        this.columnDefs = columndata.columnDefs.concat([]);
    }

    @Input("searchPatientList")
    set searchPatientList(list) {
        if (list) {
            this.rowData = list.concat([]);
        }
    }

    @Input("input_data")
    set input_data(input_data: any) {
        if (input_data) {
            this.rowData = input_data.concat([]);
            this.tempRowData = input_data.concat([]);
        }
    }

    @Input("url")
    set url(params: any) {
        this.searchUrl = params;
        this.selected = true;
    }

    constructor(
        private http: HttpClient,
        private mx_graphService: MxGraphService,
        private userService: UsersService,
        private interfaceService: InterfaceService,
        private cs: CommonService,
        private patientService: PatientService,
        private dialog: MatDialog,
        private snackbar: SnackbarCongiration,
        private smartTestService: SmartTestService
    ) {
        super();
        this.context = { gridRenderer: this }; // access table properties from renderers
        this.frameworkComponents = {
            // set renderer components for the table level
            cellRenderer: CellRendererComponent,
            // agColumnHeader: HeaderRenderComponent
        };
        this.height = { height: "456px" };
        this.isRowSelectable = (params: any) => {
            if (params["node"]) return !params.node.data.obsolete;
            else return true;
        };
        this.rowClassRules = {
            "obsolete-row": (params: any) => {
                if (params.node.data["obsolete"])
                    return params.node.data.obsolete;
                return false;
            },
        };
    }

    ngOnInit() {
        this.loadUserState();
        this.subscription1 = this.patientService.newRowData$.subscribe(
            (data) => {
                this.retrieveData();
                this.loadUserState();
            }
        );

        this.subscription4 = this.patientService.clinicChange$.subscribe(
            (data) => {
                this.clinicDetails = data;
            }
        );

        this.subscription2 = this.patientService.FilterEvent$.subscribe(
            (data) => {
                if (data) {
                    let filter_data = this.cs.getPatientFilterResullts();
                    if (this.clinicDetails["clinicType"] === "all") {
                        this.enableDynamicGroup = false;
                    } else {
                        if (
                            this.clinicDetails.isPatientGroup &&
                            this.isFilterAction
                        ) {
                            // this.enableDynamicGroup = true
                            // base on roles enable dynamic group button
                            if (!this.clinicDetails["isAdmin"]) {
                                this.enableDynamicGroup = false;
                            } else {
                                this.enableDynamicGroup = true;
                            }
                        } else {
                            this.enableDynamicGroup = false;
                        }
                    }
                    this.setDatasource(filter_data);
                } else {
                    this.filterQuery = [];
                    this.rowData = [];
                    this.enableDynamicGroup = false;
                }
            }
        );

        this.subscription5 = this.patientService.checkQuery$.subscribe(
            (data) => {
                if (data["queryGiven"]) {
                    if (data["queryGiven"].length > 0) {
                        this.enableDynamicGroupButton = true;
                    } else {
                        this.enableDynamicGroupButton = false;
                    }
                } else {
                    this.enableDynamicGroupButton = false;
                }
            }
        );

        let queryData = this.patientService.getFilterQuery();
        if (queryData) {
            this.filterQuery = queryData;
        }

        this.changeviewSubscription = this.patientService.screenview$.subscribe(
            (data) => {
                if (data["action"]) {
                    if (data["action"] === "Add") {
                        this.isFilterAction = false;
                    } else {
                        this.isFilterAction = true;
                    }
                } else {
                    this.isFilterAction = true;
                }
            }
        );
        this.subscription6 = this.smartTestService.newRowData$.subscribe(
            (data) => {
                if (this.SlimSearchTag) {
                    const query = this.SlimSearchTag.getSearchQuery();
                    this.subscriptions["dataFetch"] = this.http
                        .post(
                            this.getApiUrl(
                                `${this.config.searchUrl.searchResultUrl}/`
                            ),
                            JSON.stringify({ searchQuery: query }),
                            this.getHttpOptions("json", true)
                        )
                        .subscribe(
                            (data: any) => {
                                if (data["data"]) {
                                    this.rowData = data["data"].concat([]);
                                } else {
                                    this.rowData = [];
                                }
                                if (data["mainObjects"]) {
                                    this.mx_graphService.getObject(data);
                                }
                                if (!this.rowData.length)
                                    this.zeroText =
                                        "Add " +
                                        this.context["table"] +
                                        " to get listed";
                                this.tempRowData = data["data"].concat([]);
                                this.dataLoad = true;
                                this.setTableConfig();
                                delete this.subscriptions["dataFetch"];
                                if (this.rowData.length > 0)
                                    this.firstRowData.emit(this.rowData[0]);
                                this.refreshLog = false;
                                if (this.rowData.length > 0) {
                                    this.rowDataChanged.emit({
                                        data: this.rowData,
                                    });
                                }
                            },
                            (error: any) => {
                                this.rowData = [];
                                this.overlayHide = true;
                                this.refreshLog = false;
                                delete this.subscriptions["dataFetch"];
                            }
                        );
                } else {
                    this.retrieveData();
                }
                this.loadUserState();
            }
        );
        this.smartTestService.loadConfig.subscribe((data) => {
            if (data == "true") {
                this.gridApi.showLoadingOverlay();
            } else {
                this.gridApi.hideOverlay();
            }
        });
    }

    clinicChange(data) {
        this.clinicDetails = data;
        if (data) {
            if (data["clinicType"] != "all") {
                this.setDatasource(data);
                this.enableDynamicGroup = false;
            } else {
                this.setDatasource({ url: "patients/get-list" });
                if (this.clinicDetails.isPatientGroup) {
                    this.enableDynamicGroup = true;
                } else {
                    this.enableDynamicGroup = false;
                }
            }
        }
    }

    loadUserState() {
        this.cs.getUserState(this.screenName).subscribe((data: any) => {
            if (data && data.columns && data.columns.length) {
                this.columnTog = data.columns;
                if (data.visible_columns.length) {
                    this.visibleColumns = data.visible_columns;
                    this.visibleColCount = data.visible_columns.length;
                }
                if (data.hidden_columns.length) {
                    this.hiddenColumns = data.hidden_columns;
                }
            }
        });
    }

    // row click event emit
    emitRowClick(event: any) {
        this.rowEvent.emit(<RowEvent>{ action: "click", data: event.data });
    }

    // row selection event emit
    emitRowSelection(event: any) {
        this.rowSelectionEvent.emit(<RowEvent>{
            action: "select",
            data: event,
        });
    }

    // row event emit
    emitRowEvent(event: RowEvent) {
        this.rowEvent.emit(event);
    }

    emitChangeStatus(event: ChangeStatus) {
        this.changeStatus.emit(event);
    }

    emitActiveStatus(event: ActiveStatus) {
        this.activeStatus.emit(event);
    }

    emitAssignClinic(event: AssignClinic) {
        this.assignClinic.emit(event);
    }

    // header event emit
    emitHeaderEvent(event: HeaderEvent) {
        this.headerEvent.emit(event);
        if (event["action"] == "fullscreen") this.search = event["fullScreen"];
    }

    // handle Previous data
    patientStateMaintain() {
        this.subscription3 = this.patientService.clinicChange$.subscribe(
            (data) => {
                this.clinicDetails = data;
                if (this.subscription3 != undefined) {
                    if (data) {
                        if (data["clinicType"] != "all") {
                            data["patient_filter"] = true;
                            data["clinics"] = [
                                {
                                    clinic_id: data["clinicType"],
                                },
                            ];
                            data["filter_fields"] = {
                                is_deleted: false,
                                patient__clinic__is_deleted: false,
                                patient__is_deleted: false,
                            };
                            data["model"] = "Patients";
                            data["query"] = [];
                            this.setDatasource(data);
                            this.enableDynamicGroup = false;
                        } else {
                            this.setDatasource({ url: "patients/get-list" });
                            if (this.clinicDetails.isPatientGroup) {
                                this.enableDynamicGroup = true;
                            } else {
                                this.enableDynamicGroup = false;
                            }
                        }
                    } else {
                        this.setDatasource({ url: "patients/get-list" });
                    }
                    this.rowData = [];
                    // this.retrieveData();
                    this.loadUserState();
                    this.storeUserState();
                }
            }
        );
    }

    exportTable() {
        const ids = this.gridApi.getSelectedNodes().map((row) => row.data.id);
        this.onExportButtonClick({ ids });
    }

    // loads grid api
    onGridReady(params: GridOptions) {
        // this.retrieveData();
        this.gridOptions = params;
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.gridApi.showLoadingOverlay();
        this.tableLoad = true;
        if (this.config.remoteUrl == "patients") {
            this.gridApi.setSortModel([
                { colId: this.config.sortModel, sort: "asc" },
            ]);
            this.gridOptions.rowModelType = "infinite";
            this.handleColumnsVisiblity();
            this.patientStateMaintain();
        } else if (
            this.config.remoteUrl == "users" ||
            this.config.remoteUrl == "clinics"
        ) {
            if (this.subscription3) {
                this.subscription3.unsubscribe();
            }
            this.gridApi.setSortModel([
                { colId: this.config.sortModel, sort: "asc" },
            ]);
            this.gridOptions.rowModelType = "infinite";
            this.handleColumnsVisiblity();
            this.setDatasource({ url: this.config.remoteUrl + "/get-list" });
            this.rowData = [];
            this.loadUserState();
            this.storeUserState();
        } else {
            if (this.subscription3) {
                this.subscription3.unsubscribe();
            }
            this.retrieveData();
        }
        this.setTableConfig();
    }

    searchCall(event) {
        if (
            this.config.remoteUrl == "users" ||
            this.config.remoteUrl == "clinics"
        ) {
            this.setDatasource(event);
        }
    }

    setDatasource(data: any) {
        let datasource = {
            getRows: async (params: IGetRowsParams) => {
                if (!params.startRow) this.gridApi.showLoadingOverlay();
                let sort: any = Object.assign({}, params.sortModel[0]);
                if (sort["colId"] == "clinic")
                    sort["colId"] = "clinic__clinic_name";
                // else if (sort['colId'] == 'insurers') sort['colId'] = 'insurers__insurers_name';
                let col_name = sort["colId"];
                if (sort["sort"] == "desc") sort["colId"] = "-" + sort["colId"];
                let body = {
                    start_row: params.startRow,
                    end_row: params.endRow,
                };
                if (["users", "clinics"].includes(this.config["remoteUrl"])) {
                    (body["sort_type"] = sort["sort"]),
                        (body["column_name"] = col_name);
                } else {
                    body["sort"] = sort["colId"];
                    if (!data.hasOwnProperty("patient_filter")) {
                        data["patient_filter"] = true;
                        data["filter_fields"] = {
                            is_deleted: false,
                            patient__is_deleted: false,
                        };
                        data["model"] = "Patients";
                        data["query"] = [];
                    }
                }

                if (data.hasOwnProperty("patient_filter")) {
                    let filter_details = { filter_details: data };
                    Object.assign(body, filter_details);
                    data["url"] = "get_patient_filter/get-filter-result";
                } else if (data.hasOwnProperty("query")) {
                    body["searchQuery"] = data["query"];
                    if (data["group_id"]) {
                        body["group_id"] = data["group_id"];
                    }
                }
                this.http
                    .post(
                        `${GetApiurl(data["url"])}/`,
                        body,
                        this.getHttpOptions("json", true)
                    )
                    .subscribe((response) => {
                        if (response["total"]) {
                            params.successCallback(
                                response["data"],
                                response["total"] < 100
                                    ? response["total"]
                                    : params.endRow <= response["total"]
                                    ? -1
                                    : response["total"]
                            );
                            if (!params.startRow) this.gridApi.hideOverlay();
                        } else {
                            params.successCallback([], 0);
                            this.gridApi.showNoRowsOverlay();
                        }
                        if (response["total"] > 0) {
                            if (
                                response.hasOwnProperty(
                                    "dynamic_group_patient_list"
                                )
                            ) {
                                if (response["dynamic_group_patient_list"]) {
                                    this.dynamic_group_patient_list =
                                        response["dynamic_group_patient_list"];
                                }
                            }
                            if (this.dynamic_group_patient_list.length > 0) {
                                this.enableDynamicGroupButton = true;
                            }
                        } else {
                            this.enableDynamicGroupButton = false;
                        }
                    });
                // params.failCallback()
            },
        };
        if (this.gridApi) {
            this.gridApi.setDatasource(datasource);
            this.gridApi.hideOverlay();
        }
    }

    onDataRendered(event: any) {
        this.handleColumnsVisiblity();
    }

    // sidepan hide/show
    toggleSidepan() {
        this.enableSidepan = !this.enableSidepan;
        // this.gridApi.sizeColumnsToFit();
    }

    // column hide/show
    toggleColumns(column: Object) {
        this.gridColumnApi.setColumnsVisible(
            [column["field"]],
            column["visible"]
        );
        this.gridApi.sizeColumnsToFit();
        this.fitGridSize();
        this.activeOptions = [column];
        this.updateVisibleColCount();
        this.storeUserState();
    }

    // To maintin userstate in all List screens
    storeUserState() {
        this.cs
            .updateUserState({
                screen: this.screenName,
                columns: JSON.stringify(this.columnTog),
            })
            .subscribe(
                (data) => {
                    // Show / hide loader if required
                },
                (error) => {
                    // Show / hide loader if required
                }
            );
    }

    setTableConfig() {
        if (this.dataLoad && this.tableLoad) {
            if (this.config.sortModel) {
                this.gridApi.setSortModel([
                    { colId: this.config.sortModel, sort: "asc" },
                ]);
            }
            // if (this.overlayHide)
            this.gridApi.hideOverlay();
            // this.overlayHide = true;
            this.handleColumnsVisiblity();
            // this.fitGridSize();
        }
    }

    // retrieve data from backend
    retrieveData() {
        if (this.subscriptions["dataFetch"]) {
            this.subscriptions["dataFetch"].unsubscribe();
        }
        this.subscriptions["dataFetch"] = this.cs
            .getRecords(this.config)
            .subscribe(
                (data: any) => {
                    if (data["data"]) {
                        this.rowData = data["data"].concat([]);
                    } else {
                        this.rowData = [];
                    }
                    if (data["mainObjects"]) {
                        this.mx_graphService.getObject(data);
                    }

                    if (!this.rowData.length)
                        this.zeroText =
                            "Add " + this.context["table"] + " to get listed";
                    this.tempRowData = data["data"].concat([]);
                    this.dataLoad = true;
                    this.setTableConfig();

                    delete this.subscriptions["dataFetch"];

                    if (this.rowData.length > 0)
                        this.firstRowData.emit(this.rowData[0]);
                    this.refreshLog = false;
                    if (this.rowData.length > 0) {
                        this.rowDataChanged.emit({ data: this.rowData });
                    }
                },
                (error: any) => {
                    this.rowData = [];
                    this.overlayHide = true;
                    this.refreshLog = false;
                    delete this.subscriptions["dataFetch"];
                }
            );
    }

    // search
    searchEvent(event: any) {
        if (!this.listScr.includes(this.config.remoteUrl)) {
            this.rowData = event["data"].concat([]);
            if (event["query"] != undefined) {
                this.searchQuery = event["query"];
            }
        } else {
            if (
                this.config.remoteUrl == "users" ||
                this.config.remoteUrl == "clinics"
            ) {
                if (event["query"] != undefined) {
                    this.searchQuery = event["query"];
                }
            } else {
                this.setDatasource(event);
            }
        }
        if (this.config.sortModel) {
            this.gridApi.setSortModel([
                { colId: this.config.sortModel, sort: "asc" },
            ]);
        }
    }
    // on clear search
    clearEvent() {
        if (!this.listScr.includes(this.config.remoteUrl)) {
            setTimeout(() => {
                this.searchQuery = undefined;
                this.rowData = this.tempRowData.concat([]);
            });
            this.retrieveData();
        } else {
            let url = this.config["remoteUrl"] + "/get-list";
            this.setDatasource({ url: url });
        }
    }

    ngOnDestroy() {
        // Unsubscribe on service scope destroy
        Object.entries(this.subscriptions).forEach(([name, subscription]) =>
            subscription.unsubscribe()
        );
        this.subscription2.unsubscribe();
        this.subscription4.unsubscribe();
        this.subscription5.unsubscribe();
        this.changeviewSubscription.unsubscribe();
        if (this.subscription3) {
            this.subscription3.unsubscribe();
        }
        if (this.subscription1) {
            this.subscription1.unsubscribe();
        }
        if (this.subscription6) {
            this.subscription6.unsubscribe();
        }
    }

    onGridSizeChanged(event: any) {
        this.gridSize = event["clientWidth"];
        this.fitGridSize();
        this.handleColumnsVisiblity();
    }

    handleColumnsVisiblity() {
        if (
            (this.visibleColumns.length || this.hiddenColumns) &&
            this.columnUpdated < 2
        ) {
            this.columnUpdated = this.columnUpdated + 1;
            this.gridColumnApi.setColumnsVisible(this.visibleColumns, true);
            this.gridColumnApi.setColumnsVisible(this.hiddenColumns, false);
            this.activeOptions = this.columnTog;
        }
    }

    updateVisibleColCount() {
        let visibleColCount = 0;
        for (var i = 0; i < this.columnTog.length; i++) {
            if (this.columnTog[i].visible) {
                visibleColCount++;
            }
        }
        this.visibleColCount = visibleColCount;
    }

    fitGridSize() {
        let cols = 0;
        for (let tog of this.columnTog) {
            if (tog["visible"]) ++cols;
        }
        if (cols == 1) this.gridApi.sizeColumnsToFit();
        else if (cols <= 2 && this.gridSize >= 340)
            this.gridApi.sizeColumnsToFit();
        else if (cols <= 3 && this.gridSize >= 520)
            this.gridApi.sizeColumnsToFit();
        else if (cols <= 4 && this.gridSize >= 650)
            this.gridApi.sizeColumnsToFit();
        else if (cols <= 5 && this.gridSize >= 820)
            this.gridApi.sizeColumnsToFit();
        else if (cols <= 6 && this.gridSize >= 1000)
            this.gridApi.sizeColumnsToFit();
    }

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    getTransferLog() {
        this.refreshLog = true;
        if (this.config.remoteUrl == "audit-log/auditlog-list") {
            if (this.searchQuery != undefined) {
                this.subscriptions["dataFetch"] = this.http
                    .post(
                        this.getApiUrl(
                            `${this.config["searchUrl"]["searchResultUrl"]}/`,
                            [],
                            true
                        ),
                        JSON.stringify({ searchQuery: this.searchQuery }),
                        this.getHttpOptions("json", true)
                    )
                    .subscribe(
                        (response: any) => {
                            this.rowData = response["data"];
                            this.refreshLog = false;
                        },
                        (error: any) => {
                            this.refreshLog = false;
                        }
                    );
            } else {
                this.retrieveData();
            }
        }
    }

    // on clear search
    lastRequestCancel() {
        if (this.subscriptions["dataFetch"]) {
            this.subscriptions["dataFetch"].unsubscribe();
        }
        this.refreshLog = false;
    }

    datefilter() {
        this.refreshLog = true;
        let data = {
            start_date: new Date(
                this.Start_date.value.startOf("day").toISOString()
            ),
            end_date: new Date(this.End_date.value.endOf("day")).toISOString(),
        };
        this.cs.getdatabydatefiltered(data).subscribe((res) => {
            this.rowData = res["data"];
            this.refreshLog = false;
        });
    }

    setDynamicPatientList() {
        let dynamicList = this.rowData;
        let emptyArray = [];
        this.dynamicPatientList = [];
        if (dynamicList.length === 0) {
            this.zeroText = "No Filtered Results To Show ";
        } else {
            this.inactivePopup = false;
            dynamicList.map((item, index) => {
                // console.log("Item", item)
                if (!item.is_active) {
                    this.inactivePopup = true;
                } else {
                    emptyArray.push(item.id);
                }
            });
            this.dynamicPatientList = emptyArray;
        }
    }

    // create dynamic group logics
    createDynamicGroup() {
        if (this.inactivePopup) {
            this.openInactivePopup();
        } else {
            let dialogConfig = new MatDialogConfig();
            dialogConfig.width = "450px";
            dialogConfig.disableClose = true;
            dialogConfig.restoreFocus = false;
            dialogConfig.data = {
                type: 2,
                patient_grp_list: this.dynamic_group_patient_list,
                no_of_patient: this.dynamicPatientList.length,
                clinic_id: this.clinicDetails.clinicType,
                group_type: "Dynamic",
            };
            let dialogRef = this.dialog.open(
                CreatePatientgroupComponent,
                dialogConfig
            );
            dialogRef.afterClosed().subscribe((val) => {
                if (val == "yes") {
                    this.patientService.clearOrFilterPatients(false);
                    let stateMaintain = {
                        screenName: "Patient_group",
                        disableGroup: false,
                    };
                    this.patientService.PatientViewChange(stateMaintain);
                }
            });
        }
    }

    openInactivePopup() {
        let dialogConfig = new MatDialogConfig();
        dialogConfig.position = { top: "70px" };
        dialogConfig.width = "600px";
        dialogConfig.disableClose = true;
        dialogConfig.restoreFocus = false;
        dialogConfig.data = {
            message:
                "Active patients only allow in dynamic group, would you like to continue?",
            item: "Proceed",
        };

        let dialogRef = this.dialog.open(ConfimerComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((value) => {
            if (value == "yes") {
                this.inactivePopup = false;
                this.createDynamicGroup();
            }
        });
        return dialogRef;
    }

    // update dynamic group logics
    updateDynamicGroup() {
        this.patientService.patientQueryData$.subscribe((data) => {
            this.updateQuery = data;
        });
        let isSearch = this.patientService.getSearchClicked();
        if (!isSearch) {
            this.snackbar.triggerSnackBar({
                message: "Please search before update",
                type: "error",
            });
            return;
        }

        let updatePayload = {
            group_name: this.filterQuery["group_name"],
            group_id: this.filterQuery["group_id"],
            clinic_id: this.filterQuery["clinic_id"],
            query: this.updateQuery["query"],
            list_of_patient: this.dynamic_group_patient_list,
            warning: this.dynamicGroupupdateWarning,
        };
        this.patientService.updatePatientGroupQuery(updatePayload).subscribe(
            (response) => {
                if (response["message"] == "same") {
                    this.snackbar.triggerSnackBar({
                        message: "No changes in filter",
                        type: "error",
                    });
                } else if (response["message"] == "exists") {
                    this.alreadyExistModal();
                } else {
                    this.snackbar.triggerSnackBar({
                        message: response["message"],
                        type: "success",
                    });
                    let stateMaintain = {
                        screenName: "Patient_group",
                        disableGroup: false,
                    };
                    this.patientService.PatientViewChange(stateMaintain);
                    this.patientService.clearOrFilterPatients(false);
                }
            },
            (error) => {
                this.snackbar.triggerSnackBar({
                    message: "Patient group name already exist",
                    type: "error",
                });
            }
        );
    }

    alreadyExistModal() {
        let dialogConfig = new MatDialogConfig();
        dialogConfig.position = { top: "70px" };
        dialogConfig.width = "600px";
        dialogConfig.disableClose = true;
        dialogConfig.restoreFocus = false;
        dialogConfig.data = {
            message:
                "This filter already exists in dynamic group, would you like to continue?",
            item: "Proceed",
        };
        let dialogRef = this.dialog.open(ConfimerComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((value) => {
            if (value == "yes") {
                this.dynamicGroupupdateWarning = true;
                this.updateDynamicGroup();
            }
            this.dynamicGroupupdateWarning = false;
        });
        return dialogRef;
    }
}
