import { Component, EventEmitter, Inject, OnInit, Output, ViewChildren } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatSelectTrigger, MatDialogConfig } from "@angular/material";
import { DeleteDialogComponent } from "app/shared/components/dialog-box/delete-dialog/delete-dialog.component";
import { ValidationService } from "app/shared/services/validation.service";
import { MxGraphService } from "app/support/services/mx-graph.service";

@Component({
    selector: 'app-multicondition-panel',
    templateUrl: './multicondition-panel.component.html',
    styleUrls: ['./multicondition-panel.component.scss'],
})
export class MulticonditionPanelComponent implements OnInit {
    @Output() outputModel: EventEmitter<any> = new EventEmitter<any>();
    @Output() edit: EventEmitter<any> = new EventEmitter<any>();
    @ViewChildren('matSelect_k') matSelect_k;
    form: FormGroup;
    activeMetaDatas: any;
    activeInputs: any = {};
    activeInputsOperator: Array<any> = [];
    deleteModalContent: any = {
        title: '',
        message: ''
    };
    deleteConfirmTextInput: string = '';
    openDeleteConfirmation: boolean = false;
    isMarketPlaceView: boolean = false;
    activateCondtions: boolean = false;
    isEdit:boolean = false;
    btnActive:boolean = true;
    grp_rule: boolean = false;
    temp_data: any;
    activeField: any = {};
    inputModal: any;
    selectedBlock: string = '';
    valuesArr: Array<any> = []
    operatorValuesList: any;
    grpNameList: any;
    dayList = [
        {id:0, value:'Days'},
        {id:1, value:'Weeks'},
        {id:2, value:'Months'},
        {id:3, value:'Years'}
    ];
    scoreList = [
        {id:0, value:'Overall'},
        {id:1, value:'Latest'},
    ];
    enableSaveBtn = true;

    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private mx_graphService: MxGraphService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<MulticonditionPanelComponent>,
    ) {
        this.activeMetaDatas = this.data['triggerData'];
        this.grpNameList = this.data['grp_name_list'];
        this.inputModal = this.data['selectedBlockModal']['config']['field'];
        this.isEdit = this.data['isEdit'];
        this.form = this.fb.group({
            operator: [''],
            code: [''],
            code2: [''],
            unit: [''],
            condition: ['And'], 
            rules: this.fb.array([]),
            all_operators: false
        });
        if (this.data['selectedBlockModal'].isConditional == true && this.data['cell']['value']['name'] == 'conditionalBlock' && !this.isEdit) {
            this.selectedBlock = 'conditionalBlock';
            this.activeField = this.data['activeField'];
            if(this.activeField.text == 'Total Score' || this.activeField.input == 'unit'){
                this.form.get('unit').setValidators(Validators.required);
            }
            this.form.get('operator').setValidators(Validators.required);
            this.form.get('code').setValidators(Validators.required);
        }
        if(this.data['selectedBlockModal'].isConditional == true && this.data['cell']['value']['name'] == 'multiconditionalBlock' && !this.isEdit){
            this.selectedBlock = 'multiconditionalBlock';
            this.enableSaveBtn = false;
            this.setTrigger();
        }
        if(this.isEdit) {
            let parent = this.data.mxGraph.getModel().getCell(this.data.cell['parentId']);
            if(this.data['selectedBlockModal'].isConditional == true && parent['block_name'] == 'conditionalBlock' ){
                this.selectedBlock = 'conditionalBlock';
                this.activeField = this.data['activeField'];
                if(this.activeField.text == 'Total Score' || this.activeField.input == 'unit'){
                    this.form.get('unit').setValidators(Validators.required);
                }
                this.form.get('operator').setValidators(Validators.required);
                this.form.get('code').setValidators(Validators.required);
                this.form.get('operator').setValue(this.data['selectedBlockModal']['condition']['operator']);
                this.form.get('code').setValue(this.data['selectedBlockModal']['condition']['value']);
                this.form.get('code2').setValue(this.data['selectedBlockModal']['condition']['value2']);
                this.form.get('unit').setValue(this.data['selectedBlockModal']['condition']['unit']);
                this.temp_data = this.data['selectedBlockModal']['condition']['value'];
                if(this.temp_data == 'English' && !['contains', 'does not contains'].includes(this.data['selectedBlockModal']['condition']['operator'])) this.form.get('code').setValue('EN');
                if(this.temp_data == 'Spanish' && !['contains', 'does not contains'].includes(this.data['selectedBlockModal']['condition']['operator'])) this.form.get('code').setValue('SP');
                this.outputModel.emit(this.temp_data);
                this.edit.emit(this.isEdit);
            }
            if(this.data['selectedBlockModal'].isConditional == true && parent['block_name'] == 'multiconditionalBlock'){
                this.selectedBlock = 'multiconditionalBlock';
                this.edit.emit(this.isEdit);
                this.initializeActiveInputs(this.data['selectedTriggerFields']);
                let modelData = this.data['cell']['value']
                if ('model' in modelData) {
                    this.form.get('condition').setValue(this.data['cell']['value']['model']['condition'])
                    this.btnEvent(this.data['cell']['value']['model']['condition']);
                    if(this.data['cell']['value']['model']['rules'] !== undefined) {
                        this.data['cell']['value']['model']['rules'].forEach((e, i) => {
                            if (e['condition']) {
                                this.addGroup();
                                this.form.controls.rules.value[i]['condition'] = e['condition'];
                                this.btnEventGroup(e['condition'], i);
                                e['rules'].forEach((el, j) => {
                                    if (el['condition']) {
                                        this.addGroupSet(i);
                                        this.form.controls.rules.value[i]['rules'][j]['condition'] = el['condition'];
                                        this.btnEventNest(el['condition'], i, j);
                                        el['rules'].forEach((ele, k) => {
                                            this.addNestedRule(i, j, ele);
                                        })
                                    } else {
                                        this.addRuleSet(i, el);
                                    }
                                })
                            } else {
                                this.addRule(e);
                            }
                        })
                    }
                }  
            }    
        } 
        this.form.valueChanges.subscribe({
            next: (res) => {
                this.checkCondition();
            }
        })
    }

    ngOnInit(){
    }

    initializeActiveInputs(selectedCellValue:any) {
        this.r;
        this.activeMetaDatas.fields.forEach( (value, index) => {
            this.activeInputs[value.col_name] = {
                input: value.input,
                type: value.type,
                text: value.text,
                unit: value.unit,
                operators: value.operators
            };
            if(value.hasOwnProperty('options') && value.options.length > 0) {
                this.activeInputs[value.col_name]['options'] = value.options;
            }
        });
    }

    btnEvent(opr){
        this.btnActive = !this.btnActive;
        this.form.get('condition').setValue(opr);
        setTimeout(() => {
            let btns = document.querySelectorAll('.init-');
            let element;
            btns.forEach(el => {
                el.classList.remove('btn-active');
                if(el.textContent == opr) element = el;
            });
            element.classList.add('btn-active');
        }, 100);
    }

    btnEventGroup(opr, i) {
        const rule = this.form.controls.rules as FormArray;
        rule.controls[i].get('condition').setValue(opr);

        setTimeout(() => {
            let btns = document.querySelectorAll('.set-' + (i + 1));
            let element;
            btns.forEach(el => {
                el.classList.remove('btn-active');
                if(el.textContent == opr) element = el;
            });
            element.classList.add('btn-active');
        }, 100);
    }

    btnEventNest(opr, i, j) {
        const rule = this.form.controls.rules as FormArray;
        const rules = rule.controls[i].get('rules') as FormArray;
        rules.controls[j].get('condition').setValue(opr);

        setTimeout(() => {
            let btns = document.querySelectorAll(`.innerSubGroup${i + 1}-${j + 1}`);
            let element;
            btns.forEach(el => {
                el.classList.remove('btn-active');
                if(el.textContent == opr) element = el;
            });
            element.classList.add('btn-active');
        }, 100);
    }

    setTrigger(){
        this.r;
        this.activeMetaDatas.fields.forEach((value, index) => {
            this.activeInputs[value.col_name] = {
                input: value.input,
                type: value.type,
                text: value.text,
                unit: value.unit,
                operators: value.operators,
            };
            if (value.hasOwnProperty('options') && value.options.length > 0) {
                this.activeInputs[value.col_name]['options'] = value.options;
            }
        });
    }

    addRule(ruleValue) {
        this.f;
        let rule = {
            col_name: '',
            input: '',
            operator: '',
            text: '',
            type: '',
            value: '',
            unit: '',
            value2: '',
            all_operators: ''
        };
        const formRules = this.form.controls.rules as FormArray;
        if (ruleValue) {
            rule['col_name'] = ruleValue['col_name'];
            rule['input'] = ruleValue['input'];
            rule['operator'] = ruleValue['operator'];
            rule['text'] = ruleValue['text'];
            rule['type'] = ruleValue['type'];
            rule['value'] = ruleValue['value'];
            rule['unit'] = ruleValue['unit'];
            rule['value2'] = ruleValue['value2'];
            rule['all_operators'] = ruleValue['all_operators'];
            if(ruleValue['input'] == 'date'){
                this.temp_data = rule['value'];
                this.outputModel.emit(this.temp_data);
            }
        }
        formRules.push(this.fb.group({
            col_name: [rule['col_name'], [Validators.required]],
            operator: [rule.operator, [Validators.required]],
            value: [rule.value, [Validators.required]],
            type: [rule.type],
            input: [rule.input],
            text: [rule.text],
            unit: [rule.unit],
            value2: [rule.value2],
            all_operators: [rule.all_operators]
        }));       
    }

    addGroup(){
        this.f;
        this.grp_rule = true;
        const formGroup = this.form.controls.rules as FormArray;
        formGroup.push(this.fb.group({
            condition: ['And'],
            rules: this.fb.array([])
        }))
    }

    addRuleSet(ind,ruleValue){
        const rules = this.form.controls.rules as FormArray;
        let rule = {
            col_name: '',
            input: '',
            operator: '',
            text: '',
            type: '',
            value: '',
            unit: '',
            value2: '',
            all_operators: false
        };
        const sub_rule = rules.controls[ind].get('rules') as FormArray;
        if (ruleValue) {
            rule['col_name'] = ruleValue['col_name'];
            rule['input'] = ruleValue['input'];
            rule['operator'] = ruleValue['operator'];
            rule['text'] = ruleValue['text'];
            rule['type'] = ruleValue['type'];
            rule['value'] = ruleValue['value'];
            rule['unit'] = ruleValue['unit'];
            rule['value2'] = ruleValue['value2'];
            rule['all_operators'] = ruleValue['all_operators'];
            if(ruleValue['input'] == 'date'){
                this.temp_data = rule['value'];
                this.outputModel.emit(this.temp_data);
            }
            if(ruleValue['value'] == 'English' && !['contains', 'does not contains'].includes(ruleValue['operator'])) rule['value'] = 'EN';
            if(ruleValue['value'] == 'Spanish' && !['contains', 'does not contains'].includes(ruleValue['operator'])) rule['value'] = 'SP';
        }
        sub_rule.push(this.fb.group({
            col_name: [rule['col_name'], [Validators.required]],
            operator: [rule.operator, [Validators.required]],
            value: [rule.value, [Validators.required]],
            type: [rule.type],
            input: [rule.input],
            text: [rule.text],
            unit: [rule.unit],
            value2: [rule.value2],
            all_operators: [rule.all_operators]
        }))
       
    }

    addNestedRule(i,j,ruleValue){
        let rule = {
            col_name: '',
            input: '',
            operator: '',
            text: '',
            type: '',
            value: '',
            unit: '',
            value2: '',
            all_operators: false
        };
        const rules = this.form.controls.rules as FormArray;
        const secondArray = rules.controls[i].get('rules') as FormArray;
        const nest = secondArray.controls[j].get('rules') as FormArray;
        if (ruleValue) {
            rule['col_name'] = ruleValue['col_name'];
            rule['input'] = ruleValue['input'];
            rule['operator'] = ruleValue['operator'];
            rule['text'] = ruleValue['text'];
            rule['type'] = ruleValue['type'];
            rule['value'] = ruleValue['value'];
            rule['unit'] = ruleValue['unit'];
            rule['value2'] = ruleValue['value2'];
            rule['all_operators'] = ruleValue['all_operators'];
            if(ruleValue['input'] == 'date'){
                this.temp_data = rule['value'];
                this.outputModel.emit(this.temp_data);
            }
            if(ruleValue['value'] == 'English' && !['contains', 'does not contains'].includes(ruleValue['operator'])) rule['value'] = 'EN';
            if(ruleValue['value'] == 'Spanish' && !['contains', 'does not contains'].includes(ruleValue['operator'])) rule['value'] = 'SP';  
        }
        nest.push(this.fb.group({
            col_name: [rule['col_name'], [Validators.required]],
            operator: [rule.operator, [Validators.required]],
            value: [rule.value, [Validators.required]],
            type: [rule.type],
            input: [rule.input],
            text: [rule.text],
            unit: [rule.unit],
            value2: [rule.value2],
            all_operators: [rule.all_operators]
        }))  
    }

    addGroupSet(i){
        const rules = this.form.controls.rules as FormArray;
        const secondArray = rules.controls[i].get('rules') as FormArray;
        secondArray.push(this.fb.group({
            condition: ['And'],
            rules: this.fb.array([])
        }))
    }

    setOperator(){
        this.form.get('code').setValue('');
        this.form.get('code2').setValue('');
        this.form.get('unit').setValue('');
        if((this.form.get('operator').value === 'equal' || this.form.get('operator').value === 'not equal') && 
        (this.activeField.text == 'Clinician Email (EMR)' || this.activeField.text == 'Patient Email' || this.activeField.text == 'Patient Email (EMR)')){
            this.form.get('code').setValidators([Validators.required, ValidationService.emailValidator]);
        }else{
            this.form.get('code').setValidators([Validators.required]);
        }
        if(this.form.get('operator').value === 'exist' || this.form.get('operator').value === 'not exist' || 
        this.form.get('operator').value === 'empty' || this.form.get('operator').value === 'not empty' ||
        this.form.get('operator').value === 'Yes' || this.form.get('operator').value === 'No'){
            this.form.get('code').setValue('?');
        }else if(this.form.get('operator').value === 'between'){
            this.form.get('code2').setValidators(Validators.required);
        }else{
            this.form.get('code2').clearValidators();
        }
        this.form.get('code').updateValueAndValidity();
        this.form.get('code2').updateValueAndValidity();
        this.form.get('unit').updateValueAndValidity();
    }

    selectedCondition_1(value: any, index: number) {
        const controlArray = <FormArray>this.form.controls['rules'];
        controlArray.controls[index].get('operator').setValue(this.activeInputs[value]['operators'][0]);
        controlArray.controls[index].get('type').setValue(this.activeInputs[value]['type']);
        controlArray.controls[index].get('input').setValue(this.activeInputs[value]['input']);
        controlArray.controls[index].get('text').setValue(this.activeInputs[value]['text']);
        controlArray.controls[index].get('unit').setValue('');
        controlArray.controls[index].get('value').setValue('');
        controlArray.controls[index].get('value2').setValue('');
        if(this.activeInputs[value]['operators'][0] === 'exist' || this.activeInputs[value]['operators'][0] === 'not exist' ||
         this.activeInputs[value]['operators'][0] === 'empty' || this.activeInputs[value]['operators'][0] === 'not empty' ||
         this.activeInputs[value]['operators'][0] === 'Yes' || this.activeInputs[value]['operators'][0] === 'No' ){
            controlArray.controls[index].get('value').setValue('?');
        }else{
            controlArray.controls[index].get('value').setValue('');
        }
        if(this.activeInputs[value]['text'] == 'Total Score' || this.activeInputs[value]['input'] == 'unit'){
            controlArray.controls[index].get('unit').setValidators(Validators.required);
        }else{
            controlArray.controls[index].get('unit').clearValidators();
        }
        if(this.activeInputs[value]['text'] == 'Clinician Email (EMR)' ||
            this.activeInputs[value]['text'] == 'Patient Email' || this.activeInputs[value]['text'] == 'Patient Email (EMR)'){
            controlArray.controls[index].get('value').setValidators([Validators.required,ValidationService.emailValidator]);
        }else{
            controlArray.controls[index].get('value').setValidators([Validators.required]);
        }
        controlArray.controls[index].get('value').updateValueAndValidity();
        controlArray.controls[index].get('value2').updateValueAndValidity();
        controlArray.controls[index].get('unit').updateValueAndValidity();
        return this.activeInputs[value]
    }
    
    selectedCondition(value: any, i,j,k) {
        const ctrl = <FormArray>this.form.controls['rules'];
        const controlArray = ctrl.controls[i].get('rules') as FormArray;
        
        if(k != undefined){
            const nestArray = controlArray.controls[j].get('rules') as FormArray;
            nestArray.controls[k].get('operator').setValue(this.activeInputs[value]['operators'][0]);
            nestArray.controls[k].get('type').setValue(this.activeInputs[value]['type']);
            nestArray.controls[k].get('input').setValue(this.activeInputs[value]['input']);
            nestArray.controls[k].get('text').setValue(this.activeInputs[value]['text']);
            nestArray.controls[k].get('unit').setValue('');
            nestArray.controls[k].get('value').setValue('');
            nestArray.controls[k].get('value2').setValue('');
            if(this.activeInputs[value]['operators'][0] === 'exist' || this.activeInputs[value]['operators'][0] === 'not exist' ||
            this.activeInputs[value]['operators'][0] === 'empty' || this.activeInputs[value]['operators'][0] === 'not empty' ||
            this.activeInputs[value]['operators'][0] === 'Yes' || this.activeInputs[value]['operators'][0] === 'No'){
                nestArray.controls[k].get('value').setValue('?');
            }else{
                nestArray.controls[k].get('value').setValue('');
            }
            if(this.activeInputs[value]['text'] == 'Total Score' || this.activeInputs[value]['input'] == 'unit'){
                nestArray.controls[k].get('unit').setValidators(Validators.required);
            }else{
                nestArray.controls[k].get('unit').clearValidators();
            }
            if(this.activeInputs[value]['text'] == 'Clinician Email (EMR)' ||
            this.activeInputs[value]['text'] == 'Patient Email' || this.activeInputs[value]['text'] == 'Patient Email (EMR)'){
                nestArray.controls[k].get('value').setValidators([Validators.required,ValidationService.emailValidator]);
            }else{
                nestArray.controls[k].get('value').setValidators([Validators.required]);
            }
            nestArray.controls[k].get('unit').updateValueAndValidity();
            nestArray.controls[k].get('value').updateValueAndValidity();
            nestArray.controls[k].get('value2').updateValueAndValidity();
        }else{
            controlArray.controls[j].get('operator').setValue(this.activeInputs[value]['operators'][0]);
            controlArray.controls[j].get('type').setValue(this.activeInputs[value]['type']);
            controlArray.controls[j].get('input').setValue(this.activeInputs[value]['input']);
            controlArray.controls[j].get('text').setValue(this.activeInputs[value]['text']);
            controlArray.controls[j].get('unit').setValue('');
            controlArray.controls[j].get('value').setValue('');
            controlArray.controls[j].get('value2').setValue('');
            if(this.activeInputs[value]['operators'][0] === 'exist' || this.activeInputs[value]['operators'][0] === 'not exist' || 
            this.activeInputs[value]['operators'][0] === 'empty' || this.activeInputs[value]['operators'][0] === 'not empty' ||
            this.activeInputs[value]['operators'][0] === 'Yes' || this.activeInputs[value]['operators'][0] === 'No'){
                controlArray.controls[j].get('value').setValue('?');
            }else{
                controlArray.controls[j].get('value').setValue('');
            }
            if(this.activeInputs[value]['text'] == 'Total Score' || this.activeInputs[value]['input'] == 'unit'){
                controlArray.controls[j].get('unit').setValidators(Validators.required);
            }else{
                controlArray.controls[j].get('unit').clearValidators();
            }
            if(this.activeInputs[value]['text'] == 'Clinician Email (EMR)' ||
            this.activeInputs[value]['text'] == 'Patient Email' || this.activeInputs[value]['text'] == 'Patient Email (EMR)'){
                controlArray.controls[j].get('value').setValidators([Validators.required,ValidationService.emailValidator]);
            }else{
                controlArray.controls[j].get('value').setValidators([Validators.required]);
            }
            controlArray.controls[j].get('unit').updateValueAndValidity();
            controlArray.controls[j].get('value').updateValueAndValidity();
            controlArray.controls[j].get('value2').updateValueAndValidity();
        }
        
        return this.activeInputs[value]
    }
    

    checkToShowAddRule() {
        if (this.isMarketPlaceView) {
            return false;
        }
        return true;
    }

    onSelectOperator(value: any, index: number,j,k) {
        this.activateCondtions = true;
        const controlArray = <FormArray>this.form.controls['rules'];
        if(k != undefined){
            const indVal = controlArray.controls[index].get('rules') as FormArray;
            const jVal = indVal.controls[j].get('rules') as FormArray;
            const kVal = jVal.controls[k].get('operator').value;
            jVal.controls[k].get('value').setValue('');
            jVal.controls[k].get('value2').setValue('');
            jVal.controls[k].get('unit').setValue('');
            if((kVal === 'equal' || kVal === 'not equal') && (jVal.controls[k].get('text').value == 'Clinician Email (EMR)' || jVal.controls[k].get('text').value == 'Patient Email' 
            || jVal.controls[k].get('text').value == 'Patient Email (EMR)')){
                jVal.controls[k].get('value').setValidators([Validators.required, ValidationService.emailValidator]);
            }else{
                jVal.controls[k].get('value').setValidators([Validators.required])
            }
            if(kVal === 'exist' || kVal === 'not exist' || kVal === 'empty' || kVal === 'not empty' || kVal === 'Yes' || kVal === 'No' ){
                jVal.controls[k].get('value').setValue(['?']);
            }else if(kVal === 'between'){
                jVal.controls[k].get('value2').setValidators(Validators.required);
            }else{
                jVal.controls[k].get('value2').clearValidators();
            }
            if(jVal.controls[k].get('text').value == 'Total Score' || jVal.controls[k].get('input').value == 'unit'){
                jVal.controls[k].get('unit').setValidators(Validators.required);
            }else{
                jVal.controls[k].get('unit').clearValidators();
            }
            jVal.controls[k].get('value').updateValueAndValidity();
            jVal.controls[k].get('value2').updateValueAndValidity();
            jVal.controls[k].get('unit').updateValueAndValidity();
            jVal.controls[k].get('all_operators').setValue(false);
        }else if( j != undefined){
            const indVal = controlArray.controls[index].get('rules') as FormArray;
            const jVal = indVal.controls[j].get('operator').value;
            indVal.controls[j].get('value').setValue('');
            indVal.controls[j].get('value2').setValue('');
            indVal.controls[j].get('unit').setValue('');
            if((jVal === 'equal' || jVal === 'not equal') && (indVal.controls[j].get('text').value == 'Clinician Email (EMR)' || indVal.controls[j].get('text').value == 'Patient Email' 
            || indVal.controls[j].get('text').value == 'Patient Email (EMR)')){
                indVal.controls[j].get('value').setValidators([Validators.required, ValidationService.emailValidator]);
            }else{
                indVal.controls[j].get('value').setValidators([Validators.required])
            }
            if(jVal === 'exist' || jVal === 'not exist' || jVal === 'empty' || jVal === 'not empty' || jVal === 'Yes' || jVal === 'No'){
                indVal.controls[j].get('value').setValue(['?']);
            }else if(jVal === 'between'){
                indVal.controls[j].get('value2').setValidators(Validators.required);
            }else{
                indVal.controls[j].get('value2').clearValidators();
            }
            if(indVal.controls[j].get('text').value == 'Total Score' || indVal.controls[j].get('input').value == 'unit'){
                indVal.controls[j].get('unit').setValidators(Validators.required);
            }else{
                indVal.controls[j].get('unit').clearValidators();
            }
            indVal.controls[j].get('value').updateValueAndValidity();
            indVal.controls[j].get('value2').updateValueAndValidity();
            indVal.controls[j].get('unit').updateValueAndValidity();
            indVal.controls[j].get('all_operators').setValue(false); 
        }else{
            const indVal = controlArray.controls[index].get('operator').value;
            controlArray.controls[index].get('value').setValue('');
            controlArray.controls[index].get('value2').setValue('');
            controlArray.controls[index].get('unit').setValue('');
            if(indVal === 'exist' || indVal === 'not exist' || indVal === 'empty' || indVal === 'not empty' || indVal === 'Yes' || indVal === 'No'){
                controlArray.controls[index].get('value').setValue(['?']);
            }else if(indVal === 'between'){
                controlArray.controls[index].get('value2').setValidators(Validators.required);
            }else{
                controlArray.controls[index].get('value2').clearValidators();
            }
            if(controlArray.controls[index].get('text').value == 'Total Score' || controlArray.controls[index].get('input').value == 'unit'){
                controlArray.controls[index].get('unit').setValidators(Validators.required);
            }else{
                controlArray.controls[index].get('unit').clearValidators();
            }
            if((indVal === 'equal' || indVal === 'not equal') && (controlArray.controls[index].get('text').value == 'Clinician Email (EMR)' || controlArray.controls[index].get('text').value == 'Patient Email' 
            || controlArray.controls[index].get('text').value == 'Patient Email (EMR)')){
                controlArray.controls[index].get('value').setValidators([Validators.required, ValidationService.emailValidator]);
            }else{
                controlArray.controls[index].get('value').setValidators([Validators.required])
            }
            controlArray.controls[index].get('value').updateValueAndValidity();
            controlArray.controls[index].get('value2').updateValueAndValidity();
            controlArray.controls[index].get('unit').updateValueAndValidity();
            controlArray.controls[index].get('all_operators').setValue(false);            
        }
    }

    onBegainDeleteAction(rule: FormGroup, index: number) {
        this.deleteModalContent.title = 'Delete condition';
        this.deleteModalContent.deletableIndex = index;
        this.openDeleteConfirmation = true;
        let dialogConfig = new MatDialogConfig();
        dialogConfig.width = '40%';
        dialogConfig.data = { 'yes': null, 'no': null };
        dialogConfig.position = { top: '70px' };
        dialogConfig.disableClose = true,
            dialogConfig.data = {
                title: 'Delete Condition?',
                sub_title: 'Are you sure to delete this Condition?',
                no_button: 'No, keep it',
                yes_button: 'Yes, Condition'
            }
        let dialogRef = this.dialog.open(DeleteDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            value => {
                if (value == 'yes') {
                    this.removeTriggerCondition();
                }
            }
        );
    }

    removeTriggerCondition() {
        const { deletableIndex } = this.deleteModalContent;
        this.removeRuleByIndex(deletableIndex);
        this.openDeleteConfirmation = false;
        this.deleteModalContent.deletableIndex = -1;
        this.deleteConfirmTextInput = '';
    };

    removeRuleByIndex(index: number) {
        const control = <FormArray>this.form.controls['rules'];
        control.removeAt(index);
    }

    removeRule1(i,j,k){
        const ctrl = <FormArray>this.form.controls['rules'];
        const controlArray = ctrl.controls[i].get('rules') as FormArray;
        this.deleteModalContent.title = 'Delete condition';
        this.openDeleteConfirmation = true;
        let dialogConfig = new MatDialogConfig();
        dialogConfig.width = '40%';
        dialogConfig.data = { 'yes': null, 'no': null };
        dialogConfig.position = { top: '70px' };
        dialogConfig.disableClose = true,
            dialogConfig.data = {
                title: 'Delete Condition?',
                sub_title: 'Are you sure to delete this Condition?',
                no_button: 'No, keep it',
                yes_button: 'Yes, Condition'
            }
        let dialogRef = this.dialog.open(DeleteDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            value => {
                if (value == 'yes') {
                    if(k != undefined){
                        const nestArray = controlArray.controls[j].get('rules') as FormArray;
                        nestArray.removeAt(k);
                    }else{
                        controlArray.removeAt(j);
                    }
                    
                }
            }
        );  
    }

    get f() {
        return this.form.controls;
    }

    get r() {
        return this.form.controls.rules as FormArray;
    }

    save() {
        this.mx_graphService.setGraphLoadingTrue()
        let formValues = this.form.value;
        let returnDatas: any = {
            formData: formValues,
            fields: this.activeInputs,
            triggerData:this.activeMetaDatas
        };
        if(this.isEdit){
            let parent = this.data.mxGraph.getModel().getCell(this.data.cell['parentId']);
            if (this.data['selectedBlockModal'].isConditional == true && parent['block_name'] == 'conditionalBlock' ) {
              this.data['selectedBlockModal']['condition']['operator'] = this.form.value['operator'];
              this.data['selectedBlockModal']['condition']['value'] = this.form.value['code'];
              this.data['selectedBlockModal']['condition']['value2'] = this.form.value['code2'];
              this.data['selectedBlockModal']['condition']['unit'] = this.form.value['unit'];
            }
            if(this.data['selectedBlockModal'].isConditional == true && parent['block_name'] == 'multiconditionalBlock' ) {
                this.data['selectedBlockModal']['condition'] = formValues;
                this.data['selectedBlockModal']['model'] = formValues;
            }
          }else{
            if (this.data['selectedBlockModal'].isConditional == true && this.data['cell']['value']['name'] == 'conditionalBlock') {
              this.data['selectedBlockModal']['condition']['operator'] = this.form.value['operator'];
              this.data['selectedBlockModal']['condition']['value'] = this.form.value['code'];
              this.data['selectedBlockModal']['condition']['value2'] = this.form.value['code2'];
              this.data['selectedBlockModal']['condition']['unit'] = this.form.value['unit'];
            }
            if(this.data['selectedBlockModal'].isConditional == true && this.data['cell']['value']['name'] == 'multiconditionalBlock') {
                this.data['selectedBlockModal']['model'] = formValues;
            }
          }
        this.dialogRef.close(this.data);
    }

    cancel() {
        this.mx_graphService.setGraphLoadingTrue()
        this.dialogRef.close('close');
    }

    removeCell() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40%';
    dialogConfig.data = { 'yes': null, 'no': null };
    dialogConfig.position = { top: '70px' };
    dialogConfig.disableClose = true,
      dialogConfig.data = {
        title: 'Delete Flow?',
        sub_title: 'Are you sure? You want to delete this flow?',
        no_button: 'No, keep it',
        yes_button: 'Yes, Delete'
      }
    let deleteRef = this.dialog.open(DeleteDialogComponent, dialogConfig);
    deleteRef.afterClosed().subscribe(
      value => {
        this.mx_graphService.setGraphLoadingTrue()
        if (value == 'yes') {
          this.dialogRef.close('remove');
        }
      }
    );
  }

    selectConditions(value: any, index: number) {
        const controlArray = <FormArray>this.form.controls['rules'];
        controlArray.controls[index].get('value').setValue(value['value']);
        controlArray.controls[index].get('value').setErrors(null);
    }

    setCondition(value:any,i,j,k){
        let lang = '';
        if(value['value'] == "EN") lang = 'English';
        if(value['value'] == "SP") lang = 'Spanish';
        const ctrl = <FormArray>this.form.controls['rules'];
        const controlArray = ctrl.controls[i].get('rules') as FormArray;
        if(k != undefined){
            const nestedArray = controlArray.controls[j].get('rules') as FormArray;
            if(lang != '') nestedArray.controls[k].value['value'] = lang;
            nestedArray.controls[k].get('value').setErrors(null);
        }else{
            if(lang != '') controlArray.controls[j].value['value'] = lang;
            controlArray.controls[j].get('value').setErrors(null);
        }
        
    }
    
    selectCondtionAllOperators(event) {
        try {
            if (event.checked) {
      
              this.form.get('all_operators').setValue(true);
              this.operatorValuesList = this.activeField.options;
              this.operatorValuesList.forEach(e => {
                  if (e){
                  this.valuesArr.push(e.id);
                  }
              })
              this.form.get('code').setValue(this.valuesArr.concat([]))
              this.matSelect_k._results[0].options._results.forEach((x,y) => {
                x.select();
              });
            } else {
                this.matSelect_k._results[0].options._results.forEach((x,y) => {
                    x.deselect();
                  });
              this.form.get('all_operators').setValue(false);
              this.form.get('code').setValue([]);
            }
          }
          finally {
            this.valuesArr = [];
          }
    }

    checkConditionOperators(i) {
        let count;
        count = this.form.controls['code'].value;
        if (count.length == this.activeField.options.length) {
            this.form.get('all_operators').setValue(true);
        } else {
            this.form.get('all_operators').setValue(false);
        }
    }

    checkCondition() {
        if (this.data['selectedBlockModal'].isConditional == true && this.selectedBlock == 'multiconditionalBlock') {
            if (this.form.controls.rules) {
                if(this.form.controls.rules.value.length == 0){
                    this.enableSaveBtn = false;
                    return;
                } 
                if (this.form.controls.rules.value && this.form.controls.rules.value.length) {
                    const all_check = this.form.controls.rules.value.map((e, i) => {
                        const sub_rule_check = e.rules.map((se, j) => {
                            if (se.rules) {
                                if (se.rules[j] && se.rules[j]['condition']) {
                                    const sub_check = se.rules.map((el) => {
                                        const check_sub_length = el.rules.length > 0;
                                        return check_sub_length ? this.form.valid : check_sub_length;
                                    });
                                    return sub_check.every(u => !!u);
                                }
                                const check_sub_length = se.rules.length > 0;
                                return check_sub_length ? this.form.valid : check_sub_length;
                            }
                            return this.form.valid;
                        });
                        return sub_rule_check.length > 0 ? sub_rule_check.every(u => !!u) && this.form.valid : false;
                    });
                    this.enableSaveBtn = all_check.every(v => !!v);
                    return;
                }
            }
        }else{
            this.enableSaveBtn = this.form.valid;
            return
        }
    }

    selectConditionCode(evt,i){
        if(evt.value == 'EN') this.form.value['code'] = 'English';
        if(evt.value == 'SP') this.form.value['code'] = 'Spanish';
      }

    selectAllOperators(event,i,j,k) {
        const controlArray = <FormArray>this.form.controls['rules'];
        try {
            if (event.checked) {
                if( j == undefined && k == undefined) {
                    controlArray.controls[i].get('all_operators').setValue(true);
                    this.operatorValuesList = this.activeInputs[this.form.controls.rules.value[i].col_name].options;
                    this.operatorValuesList.forEach(e => {
                        if (e){
                        this.valuesArr.push(e.id);
                        }
                    })
                    controlArray.controls[i].get('value').setValue(this.valuesArr.concat([]));
                } else if ( j != undefined && k == undefined){
                    const nested = controlArray.controls[i].get('rules') as FormArray;
                    nested.controls[j].get('all_operators').setValue(true)
                    this.operatorValuesList = this.activeInputs[this.form.controls.rules.value[i].rules[j].col_name].options;
                    this.operatorValuesList.forEach(e => {
                        if (e){
                        this.valuesArr.push(e.id);
                        }
                    })
                    nested.controls[j].get('value').setValue(this.valuesArr.concat([]));
                } else {
                    const nested = controlArray.controls[i].get('rules') as FormArray;
                    const subNestedArray = nested.controls[j].get('rules') as FormArray;
                    subNestedArray.controls[k].get('all_operators').setValue(true)
                    this.operatorValuesList = this.activeInputs[this.form.controls.rules.value[i].rules[j].rules[k].col_name].options;
                    this.operatorValuesList.forEach(e => {
                        if (e){
                        this.valuesArr.push(e.id);
                        }
                    })
                    subNestedArray.controls[k].get('value').setValue(this.valuesArr.concat([]));
                }
            } else {
                if( j == undefined && k == undefined) {
                    controlArray.controls[i].get('all_operators').setValue(false);
                    controlArray.controls[i].get('value').setValue([]);
                } else if ( j != undefined && k == undefined){
                    const nested = controlArray.controls[i].get('rules') as FormArray;
                    nested.controls[j].get('all_operators').setValue(false)
                    nested.controls[j].get('value').setValue([]);
                } else {
                    const nested = controlArray.controls[i].get('rules') as FormArray;
                    const subNestedArray = nested.controls[j].get('rules') as FormArray;
                    subNestedArray.controls[k].get('all_operators').setValue(false)
                    subNestedArray.controls[k].get('value').setValue([]);
                }
            }
        }
        finally{
            this.valuesArr = [];
        }
    }

    checkOperators(i,j,k){
        const controlArray = <FormArray>this.form.controls['rules'];
        let count;
        if(j == undefined && k == undefined) {
            count = this.form.controls.rules.value[i].value;
            if(count.length == this.activeInputs[this.form.controls.rules.value[i].col_name].options.length){
                controlArray.controls[i].get('all_operators').setValue(true);
            }else{
                controlArray.controls[i].get('all_operators').setValue(false);
            }
        } else if (k == undefined && j != undefined) {
            count = this.form.controls.rules.value[i].rules[j].value;
            const nestedArray = controlArray.controls[i].get('rules') as FormArray;
            if (count.length == this.activeInputs[this.form.controls.rules.value[i].rules[j].col_name].options.length){
                nestedArray.controls[j].get('all_operators').setValue(true);
            } else {
                nestedArray.controls[j].get('all_operators').setValue(false);
            }

        } else {
            count = this.form.controls.rules.value[i].rules[j].rules[k].value;
            const nestedArray = controlArray.controls[i].get('rules') as FormArray;
            const subNestedArray = nestedArray.controls[j].get('rules') as FormArray;
            if (count.length == this.activeInputs[this.form.controls.rules.value[i].rules[j].rules[k].col_name].options.length){
                subNestedArray.controls[k].get('all_operators').setValue(true);
            } else {
                subNestedArray.controls[k].get('all_operators').setValue(false);
            }
        }
    }
}