import { Component, EventEmitter, Inject, OnInit, Output, ViewChild, ElementRef, ViewChildren } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DeleteDialogComponent } from "app/shared/components/dialog-box/delete-dialog/delete-dialog.component";
import { ValidationService } from "app/shared/services/validation.service";
import { MxGraphService } from "app/support/services/mx-graph.service";

@Component({
    selector: 'app-launch-assessment-panel',
    templateUrl: './launch-assessment-panel.component.html',
    styleUrls: ['./launch-assessment-panel.component.scss']
  })
  export class LaunchAssessmentPanelComponent implements OnInit {
    @Output() outputModel: EventEmitter<any> = new EventEmitter<any>();
    @Output() edit: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('clinicSearch', { static: false, }) clinicSearch: ElementRef<HTMLInputElement>;
    @ViewChildren('matSelect_j') matSelect_j;
    taskForm: FormGroup = new FormGroup({});
    assmnt: boolean = false;
    condition_val: any;
    selectedAssessment: Array<any> = [];
    selected_input: any;
    isEdit: boolean = false;
    activeField: any = {};
    assessmentList: any;
    show_operator: boolean = false;
    temp_data: any;
    activeMetaDatas: any;
    activeInputs: any = {};
    deleteModalContent: any = {
        title: '',
        message: ''
    };
    selectedBlock: string = '';
    deleteConfirmTextInput: string = '';
    openDeleteConfirmation: boolean = false;
    isMarketPlaceView: boolean = false;
    activateCondtions: boolean = false;
    grp_rule: boolean = false;
    valuesArr: Array<any> = []
    operatorValuesList: any;
    grpNameList: any;
    dayList = [
        {id:0, value:'Days'},
        {id:1, value:'Weeks'},
        {id:2, value:'Months'},
        {id:3, value:'Years'}
    ];
    scoreList = [
        {id:0, value:'Overall'},
        {id:1, value:'Latest'},
    ];
    filteredAssessmentList: Array<any> = [];
    clinicControl: FormControl = new FormControl('');
    selectedAssesment: Array<any> = [];
    expiration_settings: boolean = false;
    intervalHrsList = [
        {'hr': 0, 'value': '00'},
        {'hr': 1, 'value': '01'},
        {'hr': 2, 'value': '02'},
        {'hr': 3, 'value': '03'},
        {'hr': 4, 'value': '04'},
        {'hr': 5, 'value': '05'},
        {'hr': 6, 'value': '06'},
        {'hr': 7, 'value': '07'},
        {'hr': 8, 'value': '08'},
        {'hr': 9, 'value': '09'},
        {'hr': 10, 'value': '10'},
        {'hr': 11, 'value': '11'},
        {'hr': 12, 'value': '12'},
        {'hr': 13, 'value': '13'},
        {'hr': 14, 'value': '14'},
        {'hr': 15, 'value': '15'},
        {'hr': 16, 'value': '16'},
        {'hr': 17, 'value': '17'},
        {'hr': 18, 'value': '18'},
        {'hr': 19, 'value': '19'},
        {'hr': 20, 'value': '20'},
        {'hr': 21, 'value': '21'},
        {'hr': 22, 'value': '22'},
        {'hr': 23, 'value': '23'},
    ]
    intervalMinsList = [
        {'min': 0, 'value': '00'},
        {'min': 5, 'value': '05'},
        {'min': 10, 'value': '10'},
        {'min': 15, 'value': '15'},
        {'min': 20, 'value': '20'},
        {'min': 25, 'value': '25'},
        {'min': 30, 'value': '30'},
        {'min': 35, 'value': '35'},
        {'min': 40, 'value': '40'},
        {'min': 45, 'value': '45'},
        {'min': 50, 'value': '50'},
        {'min': 55, 'value': '55'},
    ]
    expTimeList = [
        { id: 1, value: 'Before' },
        { id: 2, value: 'After' }
    ];
    selectedExpiryOption:any;
    enableSaveBtn = false;

    
    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private mx_graphService: MxGraphService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<LaunchAssessmentPanelComponent>,
    ){
        this.isEdit = this.data['isEdit'];
        this.grpNameList = this.data['grp_name_list'];
        this.activeMetaDatas = this.data['triggerData'];
        this.taskForm = this.fb.group({
            operator: [''],
            code: [''],
            code2: [''],
            unit: [''],
            assessments: [[], Validators.required],
            all_assessment: [false],
            all_operators: false,
            assessment_expire_link_settings:[false],
            expire_days:[1, Validators.required],
            expire_hrs:[0, Validators.required],
            expire_mins:[0, Validators.required],
        })
        let asmtList = this.data['assessment_list']['options']
        const key = 'value';
        const arrayUniqueByKey = [...new Map(asmtList.map(item => [item[key], item])).values()];
        this.assessmentList = arrayUniqueByKey
        this.filteredAssessmentList = arrayUniqueByKey
        if (this.data['selectedBlockModal'].isConditional == true && this.data['cell']['value']['name'] == 'conditionalBlock' && !this.isEdit) {
            this.selectedBlock = 'conditionalBlock';
            this.activeField = this.data['activeField'];
            this.taskForm.get('operator').setValidators(Validators.required);
            this.taskForm.get('code').setValidators(Validators.required);
            if(this.activeField.text == 'Total Score' || this.activeField.input == 'unit'){
                this.taskForm.get('unit').setValidators(Validators.required);
            }
        }
        if(this.data['selectedBlockModal'].isConditional == true && this.data['cell']['value']['name'] == 'multiconditionalBlock' && !this.isEdit){
            this.selectedBlock = 'multiconditionalBlock';
        }
        this.taskForm.valueChanges.subscribe({
            next: (res) => {
                this.checkCondition();
            }
        })
    }
    ngOnInit() {
        if (this.isEdit) {
            let parent = this.data.mxGraph.getModel().getCell(this.data.cell['parentId']);
            let asmtList = this.data['assessment_list']['options'].concat([])
            const key = 'value';
            const arrayUniqueByKey = [...new Map(asmtList.map(item => [item[key], item])).values()];
            this.assessmentList = arrayUniqueByKey;
            this.filteredAssessmentList =  arrayUniqueByKey
            this.taskForm.controls['assessment_expire_link_settings'].setValue(this.data['cell']['value']['assessment_data']['assessment_expire_link_settings']);
            this.expiration_settings = this.data['cell']['value']['assessment_data']['assessment_expire_link_settings'];
            this.taskForm.get('expire_days').setValue(this.data['cell']['value']['assessment_data']['expire_days']);
            this.taskForm.get('expire_hrs').setValue(this.data['cell']['value']['assessment_data']['expire_hrs']);
            this.taskForm.get('expire_mins').setValue(this.data['cell']['value']['assessment_data']['expire_mins']);
            let selected_assessment = [];
            let modelValue = this.data['cell']['value']['model'].concat([])
            const keyToGet = 'value';
            const arrayUnique = [...new Map(modelValue.map(item => [item[keyToGet], item])).values()];
            this.assessmentList.filter((el: any) => {
                for (let i of arrayUnique)
                    if (el['value'] == i['value']) selected_assessment.push(el);
            })
            const keyToId = 'assessment_id';
            let selected_assessment_unique = [...new Map(selected_assessment.map(item => [item[keyToId], item])).values()]
            if(this.assessmentList.length == selected_assessment_unique.length){
                this.taskForm.controls['all_assessment'].setValue(true);
            }
            this.taskForm.controls['assessments'].setValue(selected_assessment_unique);
            this.selectedAssesment = selected_assessment_unique
            if(this.data['selectedBlockModal'].isConditional == true && parent['block_name'] == 'conditionalBlock' ){
                this.selectedBlock = 'conditionalBlock';
                this.activeField = this.data['activeField'];
                if(this.activeField.text == 'Total Score' || this.activeField.input == 'unit'){
                    this.taskForm.get('unit').setValidators(Validators.required);
                }
                this.taskForm.get('operator').setValidators(Validators.required);
                this.taskForm.get('code').setValidators(Validators.required);
                this.taskForm.get('operator').setValue(this.data['selectedBlockModal']['condition']['operator']);
                this.taskForm.get('code').setValue(this.data['selectedBlockModal']['condition']['value']);
                this.taskForm.get('code2').setValue(this.data['selectedBlockModal']['condition']['value2']);
                this.taskForm.get('unit').setValue(this.data['selectedBlockModal']['condition']['unit']);
                this.temp_data = this.data['selectedBlockModal']['condition']['value'];
                if(this.temp_data == 'English' && !['contains', 'does not contains'].includes(this.data['selectedBlockModal']['condition']['operator'])) this.taskForm.get('code').setValue('EN');
                if(this.temp_data == 'Spanish' && !['contains', 'does not contains'].includes(this.data['selectedBlockModal']['condition']['operator'])) this.taskForm.get('code').setValue('SP');
                this.outputModel.emit(this.temp_data);
                this.edit.emit(this.isEdit);
            }
            if(this.data['selectedBlockModal'].isConditional == true && parent['block_name'] == 'multiconditionalBlock'){
                this.selectedBlock = 'multiconditionalBlock';
                this.edit.emit(this.isEdit);
            }
        }
    }

    selectConditionCode(evt,i){
        if(evt.value == 'EN') this.taskForm.value['code'] = 'English';
        if(evt.value == 'SP') this.taskForm.value['code'] = 'Spanish';
    }

    setOperator(){
        this.taskForm.get('code').setValue('');
        this.taskForm.get('code2').setValue('');
        this.taskForm.get('unit').setValue('');
        if((this.taskForm.get('operator').value === 'equal' || this.taskForm.get('operator').value === 'not equal') && 
        (this.activeField.text == 'Clinician Email (EMR)' || this.activeField.text == 'Patient Email' || this.activeField.text == 'Patient Email (EMR)')){
            this.taskForm.get('code').setValidators([Validators.required, ValidationService.emailValidator]);
        }else{
            this.taskForm.get('code').setValidators([Validators.required]);
        }
        if(this.taskForm.get('operator').value === 'exist' || this.taskForm.get('operator').value === 'not exist' ||
        this.taskForm.get('operator').value === 'empty' || this.taskForm.get('operator').value === 'not empty' ||
        this.taskForm.get('operator').value === 'Yes' || this.taskForm.get('operator').value === 'No'){
            this.taskForm.get('code').setValue(['?']);
        }else if(this.taskForm.get('operator').value === 'between'){
            this.taskForm.get('code2').setValidators(Validators.required);
        }else{
            this.taskForm.get('code2').clearValidators();
        }
        this.taskForm.get('code').updateValueAndValidity();
        this.taskForm.get('code2').updateValueAndValidity();
        this.taskForm.get('unit').updateValueAndValidity();
        this.taskForm.get('all_operators').setValue(false);
    }

    selectAllAssessment(event) {
        if (event.checked) {
            this.taskForm.get('all_assessment').setValue(true);
            this.taskForm.get('assessments').setValue(this.assessmentList.concat([]));
        } else {
            this.taskForm.get('all_assessment').setValue(false);
            this.taskForm.get('assessments').setValue([]);
        }
    }

    checkAssessments(){
        let count = this.taskForm.controls['assessments'].value;
        if(count.length == this.assessmentList.length){
            this.taskForm.get('all_assessment').setValue(true);
        }else{
            this.taskForm.get('all_assessment').setValue(false);
        }
    }

    removeCell() {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.width = '40%';
      dialogConfig.data = { 'yes': null, 'no': null, 'option': null };
      dialogConfig.position = { top: '70px' };
      dialogConfig.disableClose = true;
      if(this.data['cell']['edges'].length > 1 && this.data['selectedBlockModal'].isConditional != true){
          dialogConfig.data = {
              title: 'Delete Flow?',
              sub_title: 'Are you sure? You want to delete this block/flow?',
              no_button: 'No, keep it',
              yes_button: 'Delete flow',
              option_button:'Delete block',
              option:true
          }
      }else{
          dialogConfig.data = {
              title: 'Delete Flow?',
              sub_title: 'Are you sure? You want to delete this flow?',
              no_button: 'No, keep it',
              yes_button: 'Yes, Delete'
          }
      }
      let deleteRef = this.dialog.open(DeleteDialogComponent, dialogConfig);
      deleteRef.afterClosed().subscribe(
          value => {
              this.mx_graphService.setGraphLoadingTrue()
              if (value == 'yes') {
                  this.dialogRef.close('remove');
              }
              if(value == 'option'){
                  this,this.dialogRef.close('particular');
              }
          }
      );
    }

    checkCondition() {
        if (this.data['selectedBlockModal'].isConditional == true && this.selectedBlock == 'multiconditionalBlock') {
            if (this.taskForm.controls.rules) {
                if(this.taskForm.controls.rules.value.length == 0){
                    this.enableSaveBtn = false;
                    return;
                } 
                if (this.taskForm.controls.rules.value && this.taskForm.controls.rules.value.length) {
                    const all_check = this.taskForm.controls.rules.value.map((e, i) => {
                        const sub_rule_check = e.rules.map((se, j) => {
                            if (se.rules) {
                                if (se.rules[j] && se.rules[j]['condition']) {
                                    const sub_check = se.rules.map((el) => {
                                        const check_sub_length = el.rules.length > 0;
                                        return check_sub_length ? this.taskForm.valid : check_sub_length;
                                    });
                                    return sub_check.every(u => !!u);
                                }
                                const check_sub_length = se.rules.length > 0;
                                return check_sub_length ? this.taskForm.valid : check_sub_length;
                            }
                            return this.taskForm.valid;
                        });
                        return sub_rule_check.length > 0 ? sub_rule_check.every(u => !!u) && this.taskForm.valid : false;
                    });
                    this.enableSaveBtn = all_check.every(v => !!v);
                    return;
                }
            }
        }else{
            this.enableSaveBtn = this.taskForm.valid;
            return
        }
    }

    addValue() {
        this.mx_graphService.setGraphLoadingTrue()
        let selectedAssessments = this.taskForm.value['assessments']
        const keyToGet = 'value';
        const arrayUnique = [...new Map(selectedAssessments.map(item => [item[keyToGet], item])).values()];
        this.data['selectedBlockModal']['model'] = arrayUnique;
        this.data['selectedBlockModal']['assessment_data'] = {
            'assessment_expire_link_settings':this.taskForm.controls['assessment_expire_link_settings'].value,
            'expire_days': this.taskForm.controls['expire_days'].value,
            'expire_hrs': this.taskForm.controls['expire_hrs'].value,
            'expire_mins': this.taskForm.controls['expire_mins'].value,
        }
        if(this.isEdit){
            let parent = this.data.mxGraph.getModel().getCell(this.data.cell['parentId']);
            if (this.data['selectedBlockModal'].isConditional == true && parent['block_name'] == 'conditionalBlock' ) {
              this.data['selectedBlockModal']['condition']['operator'] = this.taskForm.value['operator'];
              this.data['selectedBlockModal']['condition']['value'] = this.taskForm.value['code'];
              this.data['selectedBlockModal']['condition']['value2'] = this.taskForm.value['code2'];
              this.data['selectedBlockModal']['condition']['unit'] = this.taskForm.value['unit'];
            }
            if(this.data['selectedBlockModal'].isConditional == true && parent['block_name'] == 'multiconditionalBlock' ) {
                if(this.data['selectedBlockModal']['condition']['rules'] == undefined){
                    this.data['selectedBlockModal']['condition'] = this.taskForm.value;
                }else{
                    this.data['selectedBlockModal']['condition']['value'] = this.taskForm.value;
                }
            }
          }else{
            if (this.data['selectedBlockModal'].isConditional == true && this.data['cell']['value']['name'] == 'conditionalBlock') {
              this.data['selectedBlockModal']['condition']['operator'] = this.taskForm.value['operator'];
              this.data['selectedBlockModal']['condition']['value'] = this.taskForm.value['code'];
              this.data['selectedBlockModal']['condition']['value2'] = this.taskForm.value['code2'];
              this.data['selectedBlockModal']['condition']['unit'] = this.taskForm.value['unit'];
            }
            if(this.data['selectedBlockModal'].isConditional == true && this.data['cell']['value']['name'] == 'multiconditionalBlock') {
              this.data['selectedBlockModal']['condition'] = this.taskForm.value;
            }
          }
        this.dialogRef.close(this.data);

    }

    closeModal() {
        this.mx_graphService.setGraphLoadingTrue()
        this.dialogRef.close('close');
    }

    selectCondtionAllOperators(event) {
        try {
            if (event.checked) {
      
              this.taskForm.get('all_operators').setValue(true);
              this.operatorValuesList = this.activeField.options;
              this.operatorValuesList.forEach(e => {
                  if (e){
                  this.valuesArr.push(e.id);
                  }
              })
              this.taskForm.get('code').setValue(this.valuesArr.concat([]))
              this.matSelect_j._results[0].options._results.forEach((x,y) => {
                x.select();
              });
            } else {
                this.matSelect_j._results[0].options._results.forEach((x,y) => {
                    x.deselect();
                    });
              this.taskForm.get('all_operators').setValue(false);
              this.taskForm.get('code').setValue([]);
            }
          }
          finally {
            this.valuesArr = [];
          }
    }

    checkConditionOperators(i) {
        let count;
        count = this.taskForm.controls['code'].value;
        if (count.length == this.activeField.options.length) {
            this.taskForm.get('all_operators').setValue(true);
        } else {
            this.taskForm.get('all_operators').setValue(false);
        }
    }

    filterAssessment(event: any) {
      const filterValue = event.target.value.toLowerCase();
      this.filteredAssessmentList = this.assessmentList.filter(
          option => option['value'].toLowerCase().indexOf(filterValue) > -1
      ).concat([]);
    }

    clinicSelectReset(isOpened: boolean) {
        this.clinicControl.reset();
        this.filteredAssessmentList = this.assessmentList.concat([]);
        if (isOpened) this.clinicSearch.nativeElement.focus();
        else {
            let selChange = [];
            this.assessmentList.filter((element: any) => {
                for (let selected of this.selectedAssesment)
                    if (element['value'] == selected['value']) selChange.push(element);
            });
            this.taskForm.get('assessments').setValue(selChange);
        }
    }

    assessmentChange(event) {
        if (event.source.selected) {
            event.source.value['checked'] = true;
            this.selectedAssesment.push(event.source.value);
        } else {
            event.source.value['checked'] = false;
            this.selectedAssesment.splice(this.selectedAssesment.findIndex(el => el['value'] == event.source.value['value']), 1);
        }
        let newList = [];
        this.selectedAssesment.forEach(el => {
            if (newList.findIndex(nEl => nEl['value'] == el['value']) == -1) newList.push(el);
        });
        this.selectedAssesment = newList.concat([]);
    }

    expireSettings(event){
        if (event.checked == true) {
            this.taskForm.get('assessment_expire_link_settings').setValue(true);
            this.expiration_settings = true;
            this.taskForm.get('expire_days').setValidators(Validators.required);
        } else {
            this.taskForm.get('assessment_expire_link_settings').setValue(false);
            this.expiration_settings = false;
            this.taskForm.get('expire_days').clearValidators();
            this.taskForm.get('expire_hrs').clearValidators();
            this.taskForm.get('expire_mins').clearValidators();
            this.taskForm.get('expire_hrs').updateValueAndValidity();
            this.taskForm.get('expire_mins').updateValueAndValidity();
        }
        this.taskForm.get('expire_days').updateValueAndValidity();
    }

    expireChange(){
        if (parseInt(this.taskForm.get('expire_days').value) === 0 && this.taskForm.get('expire_hrs').value === 0 && this.taskForm.get('expire_mins').value === 0) {
			this.taskForm.get('expire_mins').setValue(15);
		}
    }

}