import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { AuthGuardService, AuthRedirectorService, PermissionService, DeviceAcceptanceGuard } from 'app/support/services/auth/auth-guard.service';
import { CanDeactivateGuard } from 'app/support/services/auth/can-deactivate.guard';
import { DashboardComponent, CustomDateRange } from './main/dashboard/dashboard.component';
import { Error404Component } from './errors/404/error-404.component';
import { Error500Component } from './errors/500/error-500.component';
import { PermissionDeniedComponent } from './no-auth/permission-denied/permission-denied.component';
import { ProfileComponent, ProfileCropModal } from './main/profile/profile.component';
import { DeviceAcceptanceComponent } from './main/device-acceptance/device-acceptance.component';
import { LiabialityAcceptanceComponent } from './main/liabiality-acceptance/liabiality-acceptance.component';
import { AuditsComponent } from './main/audits/audits.component';
import { PatientAssessmentComponent } from './main/patient-assessment/patient-assessment.component';
import { NotificationSettingsComponent } from './main/notification-settings/notification-settings.component';
import { DeviceSettingsComponent } from './main/device-settings/device-settings.component';
import { AssessmentDetailComponent } from './main/assessment-detail/assessment-detail.component';
import { TwoFactorComponent } from './main/two-factor/two-factor.component';
import { DashbardSettingsComponent } from './main/dashboard/dashbard-settings/dashbard-settings.component';
import { DataImportComponent } from './main/data-import/data-import.component';
import { MigrationComponent } from './main/migration/migration.component';
import { LanguageComponent } from './main/language/language.component';
import { ClinicMailVerifyComponent } from './no-auth/clinic-mail-verify/clinic-mail-verify.component';
import { LogFileModal, TransferLogsComponent } from './main/transfer-logs/transfer-logs.component';
import { SmartTestModule } from './main/smart-test/smart-test.module';
import { TransferDyanicSmartestComponent } from './main/transfer-dyanic-smartest/transfer-dyanic-smartest.component';

const routes: Routes = [
    {
        path: '',
        canActivate: [AuthRedirectorService],
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuardService],
        data: {
            roles: ['view#MhtDashboard', 'view#ClinicDashboard']
        }
    },
    {
        path: 'import-data',
        component: DataImportComponent,
        canActivate: [AuthGuardService],
        data: {
            roles: ['add#ImportTool']
        }
    },
    {
        path: 'users',
        loadChildren: () => import('./main/users/users.module').then(m => m.UsersModule),
    },
    {
        path: 'clinics',
        loadChildren: () => import('./main/clinics/clinics.module').then(m => m.ClinicsModule),
    },
    {
        path: 'smart-test',
        loadChildren: () => import('./main/smart-test/smart-test.module').then(m => m.SmartTestModule)
    },
    {
        path: 'custom-groups',
        loadChildren: () => import('./main/list-management/list-management.module').then(m => m.ListManagementModule)
    },
    {
        path: 'api-users-generate',
        loadChildren: () => import('./main/api-user/api-user.module').then(m => m.ApiUserModule)
    },
    {
        path: 'agency',
        loadChildren: () => import('./main/agency/agency.module').then(m => m.AgencyModule),
    },
    {
        path: 'insurers',
        loadChildren: () => import('./main/insurers/insurers.module').then(m => m.InsurersModule),
    },
    {
        path: 'patients',
        loadChildren: () => import('./main/patients/patients.module').then(m => m.PatientsModule),
    },
    {
        path: 'liability',
        loadChildren: () => import('./main/liability/liability.module').then(m => m.LiabilityModule),
    },
    {
        path: 'email-template',
        loadChildren: () => import('./main/email-template/email-template.module').then(m => m.EmailTemplateModule),
    },
    {
        path: 'assign-assessments',
        loadChildren: () => import('./main/patient-assessment/patient-assessment.module').then(m => m.PatientsAssessmentModule),
    },
    {
        path: 'take-assessment',
        loadChildren: () => import('./main/patient-assessment-link/patient-assessment-link.module').then(m => m.PatientsAssessmentLinkModule),
    },
    {
        path: 'reports1',
        loadChildren: () => import('./main/reports3/reports.module').then(m => m.ReportsModule),
    },
    {
        path: 'reports',
        loadChildren: () => import('./main/reports1/reports.module').then(m => m.ReportsModule),
    },
    {
        path: 'assessments',
        loadChildren: () => import('./main/assessments/assessments.module').then(m => m.AssessmentsModule),
    },
    {
        path: 'activate-assessments',
        loadChildren: () => import('./main/clinic-assessment-activate/clinic-assessment-activate.module').then(m => m.ClinicAssessmentActivateModule),
    },
    {
        path: 'register-device',
        loadChildren: () => import('./main/device-register/device-register.module').then(m => m.DeviceRegisterModule),
    },
    {
        path: 'audits',
        component: AuditsComponent,
        canActivate: [AuthGuardService],
        data: {
            roles: ['view#Audits'],
            super_user: true
        }
    },
    {
        path: 'transfer-logs',
        component: TransferLogsComponent,
        canActivate: [AuthGuardService],
        data: {
            roles: ['view#Audits'],
            super_user: true
        }
    },
    {
        path: 'system-settings',
        component: DeviceSettingsComponent,
        canActivate: [AuthGuardService],
        canDeactivate: [CanDeactivateGuard],
        data: {
            roles: ['change#MHTSystemSettings'],
            super_user: true
        }
    },
    {
        path: 'migration',
        component: MigrationComponent
    },
    {
       path:'language',
       component:LanguageComponent 
    },
    {
        path: 'my-settings',
        component: TwoFactorComponent,
        canActivate: [AuthGuardService],
        canDeactivate: [CanDeactivateGuard]
    },

    {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuardService],
        canDeactivate: [CanDeactivateGuard]
    },
    { path: 'notification-settings', component: NotificationSettingsComponent },
    { path: 'not-found', component: Error404Component },
    { path: 'server-error', component: Error500Component },
    { path: 'permission-denied', component: PermissionDeniedComponent },
    { path: 'clinic-email-verify', component: ClinicMailVerifyComponent },
    { path: 'device-accept', component: DeviceAcceptanceComponent, canActivate: [DeviceAcceptanceGuard] },
    { path: 'device-accept/view', component: DeviceAcceptanceComponent, canActivate: [DeviceAcceptanceGuard] },
    { path: 'liability-accept', component: LiabialityAcceptanceComponent },
    {
        path: 'device-settings', component: DeviceSettingsComponent, data: {
            roles: ['enable#DeviceSettings'],
            system_admin: true
        },
        canActivate: [AuthGuardService],
        canDeactivate: [CanDeactivateGuard],
    },
    { 
        path: 'dashboard-settings', 
        component: DashbardSettingsComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
            roles: ['view#MhtDashboard', 'view#ClinicDashboard']
        }
    },
    { path: '**', redirectTo: '/not-found' }
];

@NgModule({
    declarations: [
        DashboardComponent,
        Error404Component,
        Error500Component,
        ProfileComponent,
        PermissionDeniedComponent,
        DeviceAcceptanceComponent,
        LiabialityAcceptanceComponent,
        AuditsComponent,
        PatientAssessmentComponent,
        NotificationSettingsComponent,
        ProfileCropModal,
        DeviceSettingsComponent,
        AssessmentDetailComponent,
        TwoFactorComponent,
        DashbardSettingsComponent,
        CustomDateRange,
        DataImportComponent,
        MigrationComponent,
        LanguageComponent,
        ClinicMailVerifyComponent,
        TransferLogsComponent,
        LogFileModal,
        TransferDyanicSmartestComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        SharedModule,
        SmartTestModule
    ],
    exports: [
        RouterModule
    ],
    entryComponents: [
        ProfileCropModal,
        CustomDateRange,
        LogFileModal,
        TransferDyanicSmartestComponent
    ]
})
export class FeaturesModule { }
